import { useState, useEffect } from "react";
import ContentDiv from "../../../components/ContentDiv";
import StudentSidebar from "../../../components/StudentSidebar";
import { Bar } from "react-chartjs-2";
import { useParams,Link } from 'react-router-dom';
import React from 'react';
import logo from '../../../assests/images/logos/Cambridge-logo.webp';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";


const StudentReportDetails = () => {

    const [chartData, setChartData] = useState({
        datasets: [],
    });
    const [chartOptions, setChartOptions] = useState({});


    const { subject } = useParams();



    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend
    );



    useEffect(() => {

        const data = [

            {
                id: 1,
                chapter: "Chapter 1",
                topic: "topic 1",
                attempt: [
                    {
                        attempt: 1,
                        marks: 12
                    },
                    {
                        attempt: 2,
                        marks: 15
                    }
                ],
                avg:13
            },
            {
    
                id: 2,
                chapter: "Chapter 2",
                topic: "topic 2",
                attempt: [
                    {
                        attempt: 1,
                        marks: 12
                    }
                ],
                avg:12
            },
    
        ]

        
        
        setChartData({
            labels: data.map(chapter =>   chapter.chapter),
            datasets: [
                {
                    label: "Whom'st let the dogs out",
                    data: data.map(marks => marks.avg),
                    borderColor: "rgb(53, 162, 235)",
                    backgroundColor: "#ff5951",
                },
            ],
        });
        setChartOptions({
            responsive: true,
            plugins: {
                legend: {
                    position: "top",
                },
                title: {
                    display: true,
                    text: "Whom'st let the dogs out",
                },
            },
        });
    }, []);


    const data = [

        {
            //topicId
            id: 1,
            chapter: "Chapter 1",
            topic: "topic 1",
            attempt: [
                {
                    attempt: 1,
                    marks: 12
                },
                {
                    attempt: 2,
                    marks: 15
                }
            ],
            avg:13
        },
        {

            id: 2,
            chapter: "Chapter 2",
            topic: "topic 2",
            attempt: [
                {
                    attempt: 1,
                    marks: 12
                }
            ],
            avg:12
        },

    ]

    return (
        <>
            <div className="flex h-screen justify-center bg-background w-full">
                <aside className="w-14 fixed left-0 top-0  bg-slate-700">
                    <StudentSidebar />
                </aside>
                <main className="flex-1 ml-10">
                    <div className="p-10">

                        <ContentDiv classes={'justify-center -mt-4'}>
                            <img className='' src={logo} alt="CambridgeOnline" height={250} width={250} />
                        </ContentDiv>
                    </div>
                    <div className=" bg-background -mt-10 p-6">
                        <ContentDiv>
                            {/* Table */}
                      
                            <section className="flex flex-col  container ml-4  mx-4">
                            
                            
                                <h1 className="text-center text-xl text-primary mb-4   md:text-left  text-xl lg:text-left  text-2xl xl:text-left   text-2xl">Reports: {subject}</h1>
                                {data.map((item) => {
                                            return (
                                                
                                <div className="w-full mb-8 overflow-hidden rounded-lg shadow-lg ">

                                    <h1 className="text-center text-xl text-primary mb-4 py-4 px-4 border-red-500 border-2 md:text-left  text-xl lg:text-left  text-2xl xl:text-left   text-2xl">Chapter: {item.chapter}</h1>
                                    
                                    <div className="w-full overflow-x-auto">
                                  
                                  {
                                    item.attempt.map((attemp,index)=>{
                                        return(
                                                <table className="w-full border-spacing">
                                                    <thead>
                                                        <tr key={attemp._id} className="text-md font-semibold tracking-wide text-left text-white bg-[#7378a2] uppercase border-b border-gray-600">
                                                            <th className="px-4 py-3">Attempt {attemp.attempt}</th>
                                                            <th className="px-4 py-3">Total</th>
                                                            <th className="px-4 py-3">Obtain</th>
                                                            <th className="px-4 py-3">Answer Keys</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="bg-white">
                                                        <tr className="text-gray-700" >
                                                            <td className="px-4 py-3 text-ms font-semibold border">{item.topic}</td>
                                                            <td className="px-4 py-3 text-ms font-semibold border">15</td>
                                                            <td className={`px-4 py-3 text-ms font-semibold border  `}>  {attemp.marks}</td>
                                                            <td  className={`px-4 py-3 text-ms font-semibold border  `}>
                                                             <Link to={`/student/reports/answerKey/${item.id}`}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="text-primary w-6 h-6">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m5.231 13.481L15 17.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v16.5c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9zm3.75 11.625a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" />
                                                            </svg>
                                                            </Link>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                        )
                    })
                  }
                                    </div>
                                </div>
                                )
                            }
                        )}
                                <Bar options={chartOptions} data={chartData} />
                            </section>


                        </ContentDiv>
                    </div>
                </main>
            </div>
        </>
    )
}
export default StudentReportDetails


