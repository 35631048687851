import { useState } from "react";
import ContentDiv from "../../components/ContentDiv";
import React from 'react';
import { useParams } from "react-router-dom";
import TeacherSidebar from "../../components/TeacherSidebar";
import { Question } from "../../api/question";
import axios from "axios"
import { BASE_URL } from "../../utiles/constant";
import LoadingSpinner from '../../components/LoaderComponent';

const TeacherAddQuestion = () => {

    // Just some styles
    const styles = {
        container: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: 50,
        },
        preview: {
            marginTop: 50,
            display: "flex",
            flexDirection: "column",
        },
        image: { width: 512, height: 256 },
        delete: {
            cursor: "pointer",
            padding: 15,
            background: "red",
            color: "white",
            border: "none",
        },
    };


    const { courseId, course, chapterId, chapter, id, topic } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const imageInputRef = React.useRef()
    const [msg, setMessage] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [option1, setOption1] = useState("")
    const [option2, setOption2] = useState("")
    const [option3, setOption3] = useState("")
    const [option4, setOption4] = useState("")
    const [correctAnswer, setCorrectAnswer] = useState("")
    const [error, setError] = useState('');

    const [file, setFile] = useState();
    const user = localStorage.getItem('user')
    const token = JSON.parse(user);
    const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
 
    const handleFile = (e) => {
        setSelectedFile(e.target.files[0])
        setFile(URL.createObjectURL(e.target.files[0]));
    }

    const removeSelectedImage = () => {
        setFile();
        imageInputRef.current.value = '';
    };

    const handleImageLoad = (event) => {
        const { naturalWidth, naturalHeight } = event.target;
        setImageSize({ width: naturalWidth, height: naturalHeight });
    };
    const onSubmit = async () => {

            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    "Authorization": `Bearer ${token.token}`
                }
            };

            const formData = new FormData();
            formData.append("option1", option1);
            formData.append("option2", option2);
            formData.append("option3", option3);
            formData.append("option4", option4);
            formData.append("correctAnswer", correctAnswer);
            formData.append("imgUrl", selectedFile);
            formData.append("chapterId", chapterId);
            formData.append("topicId", id);
            formData.append("courseId", courseId);

            setIsLoading(true);

            await axios
                .post(`${BASE_URL}${Question.question}`, formData, config)
                .then((res) => {
                    setOption1("");
                    setOption2("");
                    setOption3("");
                    setOption4("");
                    setCorrectAnswer("");
                    imageInputRef.current.value = "";
                    setSelectedFile(null)
                    //  alert("File Upload success");

                    setIsLoading(false);
                    setFile()

                })
                .catch((err) => {
                    setMessage(true)
                    setIsLoading(false);
                    setTimeout(() => {
                        setMessage(false)

                    }, 5000)
                    alert("File Upload Error");
                });
    }

    return (
        <>
            <div className="flex h-screen justify-center bg-background w-full">
                <aside className="w-14 fixed left-0 top-0  bg-slate-700">
                    <TeacherSidebar />
                </aside>
                <main className="flex-1 ml-10">
                    <div className="p-10">
                        <h1 className=" text-center text-primary  text-2xl">The Cambridge Online Digital School</h1>
                    </div>
                    <div className=" bg-background -mt-10 p-6">
                        <ContentDiv>
                            <div className="bg-white rounded shadow-lg p-8 mb-6 ml-6 md:ml-14 w-5/6 lg:ml-12 w-5/6  ">
                                <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 ">
                                    <div className="flex flex-col">
                                        <div className="text-gray-600">
                                            <h3 className="text-xl">Course: <span className="text-primary text-lg">{course}</span> </h3>
                                        </div>
                                        <div className="text-gray-600 mt-4">
                                            <h3 className="text-xl">Chapter: <span className="text-primary text-lg">{chapter}</span> </h3>
                                        </div>
                                        <div className="text-gray-600 mt-4">
                                            <h3 className="text-xl">Topic: <span className="text-primary text-lg">{topic}</span> </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ContentDiv>
                    </div>
                    <div className=" bg-background -mt-10 p-6" >
                        <ContentDiv>
                            <div className="bg-white rounded shadow-lg p-8 mb-6 ml-6 md:ml-14 w-5/6 lg:ml-12 w-5/6   ">
                                <div className="grid gap-4 gap-y-2 text-sm grid-cols-1">
                                    <div className="lg:col-span-2">
                                        <div className=" gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5">
                                            <div className="mb-4 md:col-span-5">
                                                <label htmlFor="full_name" className="mb-4">Insert Question:</label>
                                                <div className="-ml-6 flex flex-col gap-2 mt-4 mb-4 md:ml-0 lg:ml-0" >
                                                    <div className=" grid grid-cols-1 px-4 gap-8 sm:grid-cols-2 md:grid-cols-1 gap-4 px-2 lg:grid-cols-1 xl:grid-cols-1">
                                                        <div style={styles.container}>
                                                            <input type="file" name="imgUrl"
                                                                ref={imageInputRef}
                                                                onChange={handleFile} className="block w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" aria-describedby="file_input_help" id="file_input" />
                                                            <p className="mt-1 text-sm text-gray-500 dark:text-gray-300" id="file_input_help">SVG, PNG, JPG or GIF (MAX. 800x400px).</p>

                                                            {file && (
                                                                <div style={styles.preview}>
                                                                    {error && <p className="text-primary">{error}</p>}
                                                                    <img
                                                                        src={file}
                                                                        style={styles.image}
                                                                        alt="Thumb"
                                                                        onLoad={handleImageLoad}
                                                                    />
                                                                    <button onClick={removeSelectedImage} style={styles.delete}>
                                                                        Remove This Image
                                                                    </button>

                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-2">
                                                        <div>
                                                            <label htmlFor="option1">Option 1 </label>  {option1 === '' ? msg && <span className='text-primary'> is  required</span> : ''}
                                                            <input type="text" name="option1" id="option1" required={true} className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" value={option1} onChange={(e) => setOption1(e.target.value)} />
                                                        </div>
                                                        <div>
                                                            <label htmlFor="option2">Option 2 </label>  {option2 === '' ? msg && <span className='text-primary'> is  required</span> : ''}
                                                            <input type="text" name="option2" id="option2" required={true} className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" value={option2} onChange={(e) => setOption2(e.target.value)} />
                                                        </div>
                                                        <div>
                                                            <label htmlFor="option3">Option 3 </label>  {option3 === '' ? msg && <span className='text-primary'> is  required</span> : ''}
                                                            <input type="text" name="option3" id="option3" required={true} className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" value={option3} onChange={(e) => setOption3(e.target.value)} />
                                                        </div>
                                                        <div>
                                                            <label htmlFor="option4">Option 4 </label>  {option4 === '' ? msg && <span className='text-primary'> is  required</span> : ''}
                                                            <input type="text" name="option4" id="option4" required={true} className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" value={option4} onChange={(e) => setOption4(e.target.value)} />
                                                        </div>
                                                    </div>
                                                    <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-2">
                                                        <div>
                                                            <label htmlFor="correctAnswer">Correct Answer </label>  {correctAnswer === '' ? msg && <span className='text-primary'> is  required</span> : ''}
                                                            <input type="text" name="correctAnswer" id="correctAnswer" required={true} className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" value={correctAnswer} onChange={(e) => setCorrectAnswer(e.target.value)} />
                                                        </div>
                                                        <div className="md:mt-6">
                                                            <button onClick={onSubmit} className=" bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Submit</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {isLoading && <LoadingSpinner />}
                            </div>
                        </ContentDiv>
                    </div>
                </main>
            </div>
        </>
    )
}

export default TeacherAddQuestion;