import axios from "axios";
import {BASE_URL} from "../utiles/constant";
export  const Book ={
  textBook : "/api/Book", 
  teacherSubject:"/api/TeacherSubject",
  studentTextBook:"/api/StudentTextBook",
  readBook:"/api/DownloadPDF",
}
const user =  localStorage.getItem('user')
const token  = JSON.parse(user);

export const getTextBook = Book.textBook;

export  const deleteTextBook = (del) => {
  return axios.delete(`${BASE_URL}${Book.textBook}/${del.id}`,
  { headers: {"Authorization" : `Bearer ${token.token}`} }
  
  ).then(res=>{
   //   console.log(res,'TEST')
  }).catch(err=>{
    //  console.log(err)
  })
};
// export  const editTeacher = (data) => {
//     return axios.put(`${BASE_URL}${Teacher.teacher}/${data.id}`,data,
//     { headers: {"Authorization" : `Bearer ${token.token}`} }
    
//     ).then(res=>{
//       //  console.log(res,'TEST')
//     }).catch(err=>{
//      //   console.log(err)
//     })
//   };
  
//   export  const deleteTeacher = (del) => {
//     return axios.delete(`${BASE_URL}${Teacher.teacher}/${del.id}`,
//     { headers: {"Authorization" : `Bearer ${token.token}`} }
    
//     ).then(res=>{
//      //   console.log(res,'TEST')
//     }).catch(err=>{
//       //  console.log(err)
//     })
//   };

// export  const createTeacher = (data) => {
//     return axios.post(`${BASE_URL}${Teacher.teacher}`,data,
//     { headers: {"Authorization" : `Bearer ${token.token}`} }
//     )
//   };


  


