import axios from "axios";
import {BASE_URL} from "../utiles/constant";
export  const Question ={
  question : "/api/CreateQuestion"

}
const user =  localStorage.getItem('user')
const token  = JSON.parse(user);

export  const QuestionByTopic = (topicId) => {
  return axios.get(`${BASE_URL}${Question.question}/${topicId}`,
  { headers: {"Authorization" : `Bearer ${token.token}`} })
};


export  const editQuestion = (data) => {
    return axios.put(`${BASE_URL}${Question.question}/${data.id}`,data,
    { headers: {"Authorization" : `Bearer ${token.token}`} })
  };

  export  const deleteQuestion = (del) => {
    return axios.delete(`${BASE_URL}${Question.question}/${del.questionId}`,
    { headers: {"Authorization" : `Bearer ${token.token}`} })
    .then(res=>{
      //console.log(res,'TEST')
  }).catch(err=>{
      console.log(err)
  })
  };

// export  const createQuestion = (data) => {
//     return axios.post(`${BASE_URL}${Question.question}`,data,
//     { headers: {"Authorization" : `Bearer ${token.token}`} }
//     )
// };


