import React from 'react';
export default function index(){
return(
    <>
 
<div className="flex flex-col">
  <div className="mt-4  grid grid-cols-1 px-8  gap-8 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4  ">
    <div className="flex items-start rounded-xl bg-white p-4 shadow-lg">
      <div className="flex h-16 w-16 items-center justify-center rounded-full border border-primary bg-white-50">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-primary" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
          <path strokeLinecap="round" strokeLinejoin="round" d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z" />
        </svg>
      </div>

      <div className="ml-4">
        <span className="flex justify-between "> <p className="font-semibold ">574</p> <p className="text-xs text-primary text-right">View more</p></span>
        <p className="mt-2 text-sm text-gray-500">Cambridge Subjects</p>
      </div>
    </div>

    <div className="flex items-start rounded-xl bg-white p-4 shadow-lg">
      <div className="flex h-16 w-16 items-center justify-center rounded-full border border-primary bg-white-50">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-primary" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
          <path strokeLinecap="round" strokeLinejoin="round" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
        </svg>
      </div>

      <div className="ml-4">
       <span className="flex justify-between">
       <p className="font-semibold">1823</p>
       <p className="text-xs text-right text-primary">View more</p>
       </span> 
        <p className="mt-2 text-sm text-gray-500">Cambridge Teachers</p>
     
      </div>
    </div>
    <div className="flex items-start rounded-xl bg-white p-4 shadow-lg">
      <div className="flex h-16 w-16 items-center justify-center rounded-full border border-primary bg-white-50">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-primary" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
          <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
        </svg>
      </div>

      <div className="ml-4">
       <span className="flex justify-between">
       <p className="font-semibold">1823</p>
       <p className="text-xs text-right text-primary">View more</p>
       </span> 
        <p className="mt-2 text-sm text-gray-500">Cambridge Students</p>
     
      </div>
     
    </div>
    <div className="flex items-start rounded-xl bg-white p-4 shadow-lg">
      <div className="flex h-16 w-16 items-center justify-center rounded-full border border-primary bg-white-50">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-primary" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
          <path strokeLinecap="round" strokeLinejoin="round" d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z" />
        </svg>
      </div>
      <div className="ml-4">
       <span className="flex justify-between">
       <p className="font-semibold">1823</p>
       <p className="text-xs text-right text-primary">View more</p>
       </span> 
        <p className="mt-2 text-sm text-gray-500">Cambridge Support</p>
     
      </div>
    </div>
  </div>
</div>
    </>
)
}