import { useState } from "react";
import ContentDiv from "../../../components/ContentDiv";
import StudentSidebar from "../../../components/StudentSidebar";
import logo from '../../../assests/images/logos/Cambridge-logo.webp';
import React from 'react';
const StudentAskQuestion = () => {

    const [msg, setMsg] = useState(false)
    const [sendMsg, setSendMsg] = useState(false)

    const [messageData, setMessageDate] = useState({
        teacherId: "", subject: "", teacher: ""
    })
    const initalState = {
        message: "",
        subject: "",
        teacher: "",
        teacherId: "",
        studentId: ""
    }
    const [inputField, setInputField] = useState(initalState)

    const addHandler = (item) => {
        messageData.teacherId = item.teacherId
        messageData.subject = item.subject
        messageData.teacher = item.teacher

        setMsg(true)
        setMessageDate(item)

    }

    const onInputChange = (e) => {
        setInputField({ ...inputField, [e.target.name]: e.target.value })

    }
    const onSubmit = () => {
        let data = {
            studentId: 1,
            teacherId: messageData.teacherId,
            subject: messageData.subject,
            message: inputField.message,

        }
        setSendMsg(true)
        console.log(data, 'data')
        setInputField(initalState)
        setMsg(false)
        setTimeout(() => {
            setSendMsg(false)
        }, 3000)

    }

    const data = [
        {
            id: 1,
            subject: "English",
            teacher: "Hadil",
            teacherId: 1
        },
        {
            id: 2,
            subject: "Math",
            teacher: "Tod",
            teacherId: 2

        },
        {
            id: 3,
            subject: "Physic",
            teacher: "Christrope",
            teacherId: 3
        },
        {
            id: 4,
            subject: "Chemistry",
            teacher: "Martin",
            teacherId: 4
        }
    ]
    return (
        <>
            <div className="flex h-screen justify-center bg-background w-full">
                <aside className="w-14 fixed left-0 top-0  bg-slate-700">
                    <StudentSidebar />
                </aside>
                <main className="flex-1 ml-10">
                    <div className="p-10">
                        <ContentDiv classes={'justify-center -mt-4'}>
                            <img className='' src={logo} alt="CambridgeOnline" height={250} width={250} />
                        </ContentDiv>
                    </div>
                    <div className=" bg-background -mt-12 p-6">
                        <ContentDiv>
                            <section className="flex flex-col  container ml-4  mx-4">
                                <h1 className="text-center text-xl text-primary mb-4  md:text-left  text-xl lg:text-left  text-2xl xl:text-left   text-2xl">Reports</h1>
                                {sendMsg && <p className="text-green-500 text-lg font-medium">Message Send Successfully</p>}

                                <div className="w-full mb-8 overflow-hidden rounded-lg shadow-lg ">
                                    <div className="w-full overflow-x-auto">
                                        <table className="w-full border-spacing">
                                            <thead>
                                                <tr className="text-md font-semibold tracking-wide text-left text-white bg-primary uppercase border-b border-gray-600">
                                                    <th className="px-4 py-3">Subjects</th>
                                                    <th className="px-4 py-3">Teacher</th>
                                                    <th className="px-4 py-3">Ask Question</th>
                                                </tr>
                                            </thead>
                                            <tbody className="bg-white">
                                                {data.map((item) => {
                                                    return (
                                                        <tr className="text-gray-700" key={item._id}>
                                                            <td className="px-4 py-3 text-ms font-semibold border">{item.subject}</td>
                                                            <td className="px-4 py-3 text-ms font-semibold border">{item.teacher}</td>
                                                            <td className="px-4 py-3 text-sm border">
                                                                <div className="flex flex-row gap-2">
                                                                    {/* <Link to={`/student/reports/${item.id}/${item.subject}`}>  */}

                                                                    <button type="button" onClick={() => addHandler(item)}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-primary">
                                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25M9 16.5v.75m3-3v3M15 12v5.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                                                                        </svg>
                                                                    </button>
                                                                    {/* </Link> */}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                                )}
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                                {msg &&
                                    <div className="bg-white rounded shadow-lg p-8 mb-6     ">
                                        <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
                                            <div className="text-gray-600">
                                                <p className="text-lg">Subjects: <span className="text-primary">{messageData.subject}</span></p>
                                                <p className=" text-lg">Teacher: <span className="text-primary">{messageData.teacher}</span></p>
                                            </div>
                                            <div className="lg:col-span-2">
                                                <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5">
                                                    <div className="md:col-span-5">
                                                        <label htmlFor="message" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">Your message</label>
                                                        <textarea id="message" name="message" rows="4" value={inputField.message} onChange={(e) => onInputChange(e)} className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300  " placeholder="Your message..."></textarea>
                                                    </div>
                                                    <div className="md:col-span-5 mt-2 text-right">
                                                        <div className="inline-flex items-end gap-4">
                                                            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={onSubmit}>Submit</button>
                                                            <button className="bg-primary hover:bg-red-700 text-white font-bold py-2 px-4 rounded" onClick={() => { setMsg(false) }}>Close</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </section>
                        </ContentDiv>
                    </div>
                </main>
            </div>
        </>
    )
}
export default StudentAskQuestion


