
import {NavLink,useNavigate } from 'react-router-dom'
import logo from '../assests/images/logos/Cambridge-logo.webp'
import React from 'react';

const HeadSidebar = () => {
  const navigate = useNavigate()
    const onSubmit=()=>{
        localStorage.clear();
        navigate('/headowner')
    }
  return (
    <>

      <div className="min-h-screen bg-gray-100">
        <div className="sidebar min-h-screen w-[3.35rem] overflow-hidden border-r hover:w-56 hover:bg-white hover:shadow-lg">
          <div className="flex h-screen flex-col justify-between pt-2 pb-6">
            <div>
              <div className="w-max p-2.5">
                <img className='mt-2 -mb-4' src={logo} alt="Cambridge-logo" width={150} height={150} />


              </div>
              <ul className="mt-6 space-y-2 tracking-wide">
                <li className="min-w-max">
                  <NavLink  to='/headowner/dashboard'>
                    <div aria-label="dashboard" className="relative flex items-center space-x-4 bg-gradient-to-r from-sky-600 to-primary px-4 py-3 text-white">
                      <svg className="-ml-1 h-6 w-6" viewBox="0 0 24 24" fill="none">
                        <path d="M6 8a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2V8ZM6 15a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2v-1Z" className="fill-current text-primary dark:fill-slate-600"></path>
                        <path d="M13 8a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2V8Z" className="fill-current text-primary group-hover:text-primary"></path>
                        <path d="M13 15a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2v-1Z" className="fill-current group-hover:text-sky-300"></path>
                      </svg>
                      <span className="-mr-1 font-medium">   Dashboard </span>
                    </div>
                    {/* </Link> */}
                  </NavLink>
                </li>
                <li className="min-w-max">
                  <NavLink   to='/headowner/dashboard/subjects'>
                    <div className="bg group flex items-center space-x-4 rounded-full px-4 py-3 text-gray-600">

                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                        <path className=" text-primary group-hover:text-primary" strokeLinecap="round" strokeLinejoin="round" d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25" />
                      </svg>
                      <span className="group-hover:text-primary"> Subjects</span>
                    </div>
                    {/* </Link> */}
                  </NavLink>
                </li>
                <li className="min-w-max">
                  <NavLink   to='/headowner/dashboard/students'>
                    <div  className="bg group flex items-center space-x-4 rounded-full px-4 py-3 text-gray-600">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                        <path className=" text-primary group-hover:text-primary" strokeLinecap="round" strokeLinejoin="round" d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5zm6-10.125a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0zm1.294 6.336a6.721 6.721 0 01-3.17.789 6.721 6.721 0 01-3.168-.789 3.376 3.376 0 016.338 0z" />
                      </svg>
                      <span className="group-hover:text-primary">Student Enrollment</span>
                    </div>
                  </NavLink>
                </li>
                {/* <li className="min-w-max">
                  <NavLink   to='/subscription'>
                    <div  className="bg group flex items-center space-x-4 rounded-full px-4 py-3 text-gray-600">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
  <path className="text-primary group-hover:text-primary"  strokeLinecap="round" strokeLinejoin="round" d="M3.75 12h16.5m-16.5 3.75h16.5M3.75 19.5h16.5M5.625 4.5h12.75a1.875 1.875 0 010 3.75H5.625a1.875 1.875 0 010-3.75z" />
</svg>
                      <span className="group-hover:text-primary">Subscription</span>
                    </div>
                  </NavLink>
                </li> */}
                <li className="min-w-max">
                  <NavLink   to='/headowner/dashboard/teachers'>
                    <div className="bg group flex items-center space-x-4 rounded-full px-4 py-3 text-gray-600">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                        <path className=" text-primary group-hover:text-primary" strokeLinecap="round" strokeLinejoin="round" d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" />
                      </svg>
                      <span className="group-hover:text-primary">Teachers</span>
                    </div>
                  </NavLink>
                </li>
                
        
                <li className="min-w-max">
                  <NavLink   to='/headowner/dashboard/announcements'>
                    <div className="group flex items-center space-x-4 rounded-md px-4 py-3 text-gray-600">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                        <path className=" text-primary group-hover:text-primary" strokeLinecap="round" strokeLinejoin="round" d="M19.114 5.636a9 9 0 010 12.728M16.463 8.288a5.25 5.25 0 010 7.424M6.75 8.25l4.72-4.72a.75.75 0 011.28.53v15.88a.75.75 0 01-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.01 9.01 0 012.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75z" />
                      </svg>
                      <span className="group-hover:text-primary">Annoucement</span>
                    </div>
                  </NavLink>
                </li>
              
                <li className="min-w-max">
                  <NavLink   to='/headowner/dashboard/finance'>
                    <div className="group flex items-center space-x-4 rounded-md px-4 py-3 text-gray-600">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                        <path className=" text-primary group-hover:text-primary" strokeLinecap="round" strokeLinejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z" />
                      </svg>
                      <span className="group-hover:text-primary">Finance</span>
                    </div>
                  </NavLink>
                </li>
              </ul>
            </div>
            <div className="w-max -mb-3 cursor-pointer">
              <div  className="group flex items-center space-x-4 rounded-md px-4 py-3 text-gray-600">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                  <path className=" text-primary group-hover:text-primary" strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75" />
                </svg>
                <span className="group-hover:text-primary" onClick={onSubmit}>Logout</span>
              </div>
            </div>
          </div>
        </div>
      </div>


    </>
  )
}
export default HeadSidebar