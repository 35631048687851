
 import logo from '../../../assests/images/logos/Cambridge-logo.webp';
 import ContentDiv from "../../../components/ContentDiv";
 import StudentSidebar from '../../../components/StudentSidebar';
 import React from 'react';
 const TeacherResponse = () => {
  

    const data = [
        {
            id:1,
            question:"How can we calculate force",
            answer: "waiting for response"
        },
        {
            id:1,
            question:"What is 2 + 2 ?",
            answer: "4"
        },
        {
            id:2,
            question:"What is the S.I unit of Force",
            answer:"Newton"
        }
    ]


     return (
         <>
             <div className="flex h-screen justify-center bg-background w-full">
                 <aside className="w-14 fixed left-0 top-0  bg-slate-700">
                     <StudentSidebar />
                 </aside>
                 <main className="flex-1 ml-10">
                     <div className="p-10">
                          <ContentDiv classes={'justify-center'}>
                             <img className='' src={logo} alt="CambridgeOnline" height={250} width={250} />
                         </ContentDiv>
                         
                     </div>
                     <div className=" bg-background -mt-10 p-6">
                        <ContentDiv>
                            {/* Table */}
                 <section className="flex flex-col  container ml-4  mx-4">
                                <h1 className="text-center text-xl text-primary mb-4  md:text-left  text-xl lg:text-left  text-2xl xl:text-left   text-2xl">Teacher Answers</h1>

                                <div className="w-full mb-8 overflow-hidden rounded-lg shadow-lg ">
                                    <div className="w-full overflow-x-auto">
                                        <table className="w-full border-spacing">
                                            <thead>
                                                <tr className="text-md font-semibold tracking-wide text-left text-white bg-primary uppercase border-b border-gray-600">
                                                    <th className="px-4 py-3">No</th>
                                                    <th className="px-4 py-3">Question</th>
                                                    <th className="px-4 py-3">Answer</th>
                                                </tr>
                                            </thead>
                                            <tbody className="bg-white">
                                          
                                               {data.map((item,index)=>
                                               {
                                                return(
                                                    <tr className="text-gray-700" key={item._id}>
                                                   <td className="px-4 py-3 text-sm border">{index+1}</td>
                                                    <td className="px-4 py-3 text-ms font-semibold border">{item.question}</td>
                                                    {/* <td className="px-4 py-3 text-xs border">
                                                        <span className="px-2 py-1 font-semibold leading-tight text-green-700 bg-green-100 rounded-sm"> Acceptable </span>
                                                    </td> */}
                                                    <td className="px-4 py-3 text-sm border">{item.answer}</td>
                                                   
                                                </tr>
                                                )
                                               }
                                               
                                               )}



                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </section>
                            
                            
                        </ContentDiv>
                    </div>
                 </main>
             </div>
         </>
     )
 }
 export default TeacherResponse