
import logo from '../../assests/images/logos/Cambridge-logo.webp';
import ContentDiv from "../../components/ContentDiv";
import TeacherSidebar from '../../components/TeacherSidebar';
import React from 'react';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { getTeacherCourse } from '../../api/teacher';
import { Chapter, getChapterTopic } from '../../api/chapter';
import { BASE_URL } from "../../utiles/constant";
import axios from "axios";
import { useEffect } from 'react';
import { getTopicQuestion, Topic } from '../../api/topic';
import { deleteQuestion } from '../../api/question';
import jwtDecode from 'jwt-decode';
import LoadingSpinner from '../../components/LoaderComponent';
import { getLesson } from '../../api/lesson';
const TeacherTopic = () => {

    const [record, setRecord] = useState([]);
    const [chapterList, setChapterList] = useState([]);
    const [topicList, setTopicList] = useState([]);
    const [chapterTopic, setChapterTopic] = useState([])
    const [mcqQuestionList, setMcqQuestionList] = useState([])
    const [lessonList, setLessonList] = useState([]);
    const [mcqQuestionByTopic, setMcqQuestionByTopic] = useState(false);
    const [viewChapter, setViewChapter] = useState(false)
    const [viewQuestion, setViewQuestion] = useState(false)

    const [viewLesson, setViewLesson] = useState(false)

    const [topicShow, setTopicShow] = useState(false)

    const [structuredTopic, setStructuredTopic] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [structured, setStructured] = useState(false);
    const [mcqQuestion, setMcqQuestion] = useState(false);
    const [courseData, setCourseData] = useState({})
    const [chapterId, setChapterId] = useState(0);
    const [lessonId, setLessonId] = useState(0);


    const [showModal, setShowModal] = useState(false);
    const user = localStorage.getItem('user')
    const decodeToken = jwtDecode(user);
    const teacherId = decodeToken.primarysid;
    const token = JSON.parse(user);

    const loadSubject = async () => {
        return await axios.get(`${BASE_URL}${getTeacherCourse}/${teacherId}`,
            { headers: { "Authorization": `Bearer ${token.token}` } }
        ).then(res => {
            setRecord(res.data);
        }).catch(err => {
            console.log(err)
        })
    };

    const loadChapter = async (data) => {
        return await axios.get(`${BASE_URL}${Chapter.courseChapter}/${data.courseId}`,
            { headers: { "Authorization": `Bearer ${token.token}` } }
        ).then(res => {
            setChapterList(res.data);
        }).catch(err => {
            console.log(err)
        })
    };




    const loadLesson = async () => {
        return await axios.get(`${BASE_URL}${getLesson}`,
            { headers: { "Authorization": `Bearer ${token.token}` } }
        ).then(res => {
            setLessonList(res.data);
        }).catch(err => {
            console.log(err)
        })
    };


    const loadTopic = async (data) => {
        return await axios.get(`${BASE_URL}${Topic.topicList}/${data.id}`,
            { headers: { "Authorization": `Bearer ${token.token}` } }
        ).then(res => {

            console.log(res.data, 'aaaa')
            setTopicList(res.data);
        }).catch(err => {
            console.log(err)
        })
    };
    const loadChapterTopic = async (data) => {

        return await axios.get(`${BASE_URL}${getChapterTopic}/${data.courseId}`,
            { headers: { "Authorization": `Bearer ${token.token}` } }
        ).then(res => {
            setChapterTopic(res.data);
        }).catch(err => {
            console.log(err)
        })
    };
    const loadTopicQuestions = async (data) => {

        return await axios.get(`${BASE_URL}${getTopicQuestion}/${data.topicId}`,
            { headers: { "Authorization": `Bearer ${token.token}` } }
        ).then(res => {
            //            console.log(res.data, 'dd')
            setMcqQuestionList(res.data);
        }).catch(err => {
            console.log(err)
        })
    };

    const removeQuestion = async (del) => {
        setIsLoading(true)
        await deleteQuestion(del)
        setIsLoading(false)
        setStructured(false)
        setMcqQuestionByTopic(false)
        setTopicShow(false)

    }

    useEffect(() => {
        loadSubject();
        // loadLesson();
        //  loadChapter()
        //  loadTopic()

    }, [])


    const structuredQuestion = [
        {
            id: 1,
            courseId: 1,
            topicId: 1,
            chapter: "Introduction To Physics",
            topic: "Physics Principle"

        },
        {
            id: 1,
            courseId: 2,
            chapter: "Introduction To Math ",
            topicId: 2,
            topic: "Derivative"

        },
        {
            id: 1,
            courseId: 1,
            chapter: "Introduction To Formula",
            topicId: 3,
            topic: "Chemistry Formula"

        }
    ]



    const qustionListStructure = [
        {
            id: 1,
            question: "hello"
        }, {
            id: 1,
            question: "world"
        }
    ]

    return (
        <>
            <div className="flex h-screen justify-center bg-background w-full">
                <aside className="w-14 fixed left-0 top-0  bg-slate-700">
                    <TeacherSidebar />
                </aside>
                <main className="flex-1 ml-10">
                    <div className="p-10">
                        <ContentDiv classes={'justify-center'}>
                            <img className='' src={logo} alt="CambridgeOnline" height={250} width={250} />
                        </ContentDiv>
                    </div>
                    <div className=" bg-background -mt-10 p-6">
                        <ContentDiv >
                            <section className="flex flex-col container mx-4 
                             -mt-6">
                                <div className='md:grid grid-cols-2 gap-2'>
                                    {Array.isArray(record) ? record.map((item, index) => {
                                        return (
                                            <div className=" mb-4 p-6 max-w-screen-2xl bg-white rounded-lg border border-gray-200 shadow-md bg-white dark:border-shadow-lg" key={index + 1}>
                                                <h5 className="mb-2  font-medium tracking-tight  text-primary">Course</h5>
                                                <h6 className="mb-2 text-xl font-medium tracking-tight  text-primary">{item.course} {item.courseId}</h6>
                                                <div className='grid grid-cols-3 gap-4'>
                                                    <Link to={`/subject/chapters/${item.courseId}/${item.course}`} className="inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-primary rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 bg-primary dark:hover:bg-red-700 dark:focus:ring-red-800">
                                                        Add
                                                        <svg aria-hidden="true" className="ml-2 -mr-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                                    </Link>
                                                    <Link onClick={() => { setViewLesson(false); loadChapter(item); setViewChapter(true); setCourseData(item); setTopicShow(false); setViewQuestion(false);; setStructuredTopic(false); setStructured(false); setMcqQuestion(false) }} className="inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-primary rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 bg-primary dark:hover:bg-red-700 dark:focus:ring-red-800">
                                                        Chapters
                                                        <svg aria-hidden="true" className="ml-2 -mr-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                                    </Link>
                                                    <Link onClick={() => { setViewChapter(false); setViewQuestion(true); setCourseData(item); setMcqQuestion(false) }} className="inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-primary rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 bg-primary dark:hover:bg-red-700 dark:focus:ring-red-800">
                                                        View Question
                                                        <svg aria-hidden="true" className="ml-2 -mr-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                                    </Link>
                                                </div>
                                            </div>
                                        )
                                    }) : []
                                    }
                                </div>
                            </section>
                        </ContentDiv>
                    </div>
                    {viewChapter &&

                        <div className=" bg-background -mt-10 p-6">
                            <ContentDiv>
                                {/* Table */}
                                <section className="flex flex-col  container ml-4  mx-4">
                                    <div className='lg:grid grid-cols-2 gap-4'>
                                        <div className="w-full mb-8  overflow-hidden rounded-lg shadow-lg ">
                                            <h1 className="text-xl  text-primary">Chapters List</h1>
                                            <div className="w-full  overflow-x-auto">
                                                <table className="w-full border-spacing">
                                                    <thead>
                                                        <tr className="text-md font-semibold tracking-wide text-left text-white bg-primary uppercase border-b border-gray-600">
                                                            <th className="px-4 py-3">No</th>
                                                            <th className="px-4 py-3">Chapter</th>
                                                            <th className="px-4 py-3">View</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="bg-white">
                                                        {Array.isArray(chapterList) ? chapterList.map((item, index) => {

                                                            return (
                                                                <tr className="text-gray-700" key={index + 1}>
                                                                    <td className="px-4 py-3 text-ms font-semibold border">{index + 1}</td>
                                                                    <td className="px-4 py-3 text-ms font-semibold border">{item.chapterName}</td>
                                                                    <td className="px-4 py-3 text-sm border">
                                                                        <div className="flex flex-row gap-2">
                                                                            <button type="button" onClick={() => { loadLesson(); setTopicShow(false); setViewLesson(true); setChapterId(item.id); loadTopic(item) }}>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-primary">
                                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zM3.75 12h.007v.008H3.75V12zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm-.375 5.25h.007v.008H3.75v-.008zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
                                                                                </svg>
                                                                            </button>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }) : []}

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        {viewLesson &&
                                            <div className="w-full mb-8  overflow-hidden rounded-lg shadow-lg ">
                                                <h1 className="text-xl  text-primary">Lesson List</h1>
                                                <div className="w-full  overflow-x-auto">
                                                    <table className="w-full border-spacing">
                                                        <thead>
                                                            <tr className="text-md font-semibold tracking-wide text-left text-white bg-primary uppercase border-b border-gray-600">
                                                                <th className="px-4 py-3">No</th>
                                                                <th className="px-4 py-3">Lesson</th>
                                                                <th className="px-4 py-3">View</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="bg-white">
                                                            {Array.isArray(lessonList) ? lessonList.filter(x => x.chapterId === chapterId).map((item, index) => {
                                                                return (
                                                                    <tr className="text-gray-700" key={index + 1}>
                                                                        <td className="px-4 py-3 text-ms font-semibold border">{index + 1}</td>
                                                                        <td className="px-4 py-3 text-ms font-semibold border">{item.lesson}</td>
                                                                        <td className="px-4 py-3 text-sm border">
                                                                            <div className="flex flex-row gap-2">
                                                                                <button type="button" onClick={() => { setTopicShow(true); setLessonId(item.id); loadTopic(item) }}>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-primary">
                                                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zM3.75 12h.007v.008H3.75V12zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm-.375 5.25h.007v.008H3.75v-.008zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
                                                                                    </svg>
                                                                                </button>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }) : []}

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </section>
                            </ContentDiv>
                        </div>
                    }

                    {
                        topicShow &&
                        <div className=" bg-background -mt-10 p-6">
                            <ContentDiv>
                                {/* Table */}
                                <section className="flex flex-col  container ml-4  mx-4">
                                    <div className='lg:grid grid-cols-2 gap-4'>
                                        <div className="w-full mb-8  overflow-hidden rounded-lg shadow-lg ">
                                            <div className="w-full  overflow-x-auto">
                                                <h1 className="text-xl  text-primary">Topic List</h1>
                                                <table className="w-full border-spacing">
                                                    <thead>
                                                        <tr className="text-md font-semibold tracking-wide text-left text-white bg-primary uppercase border-b border-gray-600">
                                                            <th className="px-4 py-3">No</th>
                                                            <th className="px-4 py-3">Topic</th>
                                                            <th className="px-4 py-3">Add</th>
                                                        </tr>
                                                    </thead>
                                                    {Array.isArray(topicList) ? topicList.map((item, index) => {
                                                        return (
                                                            <tbody className="bg-white">
                                                                <tr className="text-gray-700" key={index + 1}>
                                                                    <td className="px-4 py-3 text-ms font-semibold border">{index + 1}</td>
                                                                    <td className="px-4 py-3 text-ms font-semibold border">{item.topic}</td>
                                                                    <td className="px-4 py-3 text-sm border">
                                                                        <div className="flex flex-row gap-2">
                                                                            <Link exact to={`/teahcer/add/questions/${item.courseId}/${item.course}/${item.chapterId}/${item.chapter}/${item.id}/${item.topic}`}>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 text-primary">
                                                                                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm.75-11.25a.75.75 0 00-1.5 0v2.5h-2.5a.75.75 0 000 1.5h2.5v2.5a.75.75 0 001.5 0v-2.5h2.5a.75.75 0 000-1.5h-2.5v-2.5z" clipRule="evenodd" />
                                                                                </svg>
                                                                                <span className='text-primary'>MCQ's</span>
                                                                            </Link>|
                                                                            <Link exact to={`/teacher/assessments/StructuredQuestion/${item.courseId}/${item.course}/${item.chapterId}/${item.chapter}/${item.id}/${item.topic}`}>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 text-primary">
                                                                                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm.75-11.25a.75.75 0 00-1.5 0v2.5h-2.5a.75.75 0 000 1.5h2.5v2.5a.75.75 0 001.5 0v-2.5h2.5a.75.75 0 000-1.5h-2.5v-2.5z" clipRule="evenodd" />
                                                                                </svg>
                                                                                <span className='text-primary'>Structured</span>
                                                                            </Link>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        )
                                                    }
                                                    ) : []}
                                                </table>
                                            </div>
                                        </div>

                                    </div>
                                </section>
                            </ContentDiv>
                        </div>
                    }
                    {
                        viewQuestion &&
                        <div className=" bg-background -mt-12 p-6">
                            <ContentDiv>
                                {/* Table */}
                                <section className="flex flex-col  container ml-4  mx-4">
                                    <div className=" mb-4 p-6 max-w-sm bg-white rounded-lg border border-gray-200 shadow-md bg-white dark:border-shadow-lg">

                                        <h6 className="mb-2 text-xl font-medium tracking-tight  text-primary">{courseData.course}-{courseData.courseId}</h6>
                                        <div className='grid grid-cols-2 gap-4'>

                                            <Link onClick={() => { loadChapterTopic(courseData); setViewChapter(false); setTopicShow(false); setViewQuestion(true); setStructuredTopic(false); setStructured(false); setMcqQuestionByTopic(false); setMcqQuestion(true) }} className="inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-primary rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 bg-primary dark:hover:bg-red-700 dark:focus:ring-red-800">
                                                MCQ's
                                                <svg aria-hidden="true" className="ml-2 -mr-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                            </Link>
                                            <Link onClick={() => { setViewChapter(false); setViewQuestion(true); setStructuredTopic(true); setMcqQuestion(false); setMcqQuestionByTopic(false) }} className="inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-primary rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 bg-primary dark:hover:bg-red-700 dark:focus:ring-red-800">
                                                Structured
                                                <svg aria-hidden="true" className="ml-2 -mr-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                            </Link>
                                        </div>
                                    </div>

                                </section>
                            </ContentDiv>
                        </div>
                    }
                    {
                        structuredTopic &&
                        <div className=" bg-background -mt-10 p-6">
                            <ContentDiv>
                                {/* Table */}
                                <section className="flex flex-col  container ml-4  mx-4">
                                    <h1 className="text-center text-xl text-primary mb-4  md:text-left  text-xl lg:text-left  text-2xl xl:text-left   text-2xl">Total Topic: {structuredQuestion.length} </h1>
                                    <div className="w-full mb-8 overflow-hidden rounded-lg shadow-lg ">
                                        <div className="w-full overflow-x-auto">
                                            <table className="w-full border-spacing">
                                                <thead>
                                                    <tr className="text-md font-semibold tracking-wide text-left text-white bg-primary uppercase border-b border-gray-600">
                                                        <th className="px-4 py-3">S.No</th>
                                                        <th className="px-4 py-3">Chapter</th>
                                                        <th className="px-4 py-3">Topics</th>
                                                        <th className="px-4 py-3">File</th>
                                                        <th className="px-4 py-3">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="bg-white">
                                                    {structuredQuestion.filter(coureseData => coureseData.courseId === courseData.id).map((item, index) => {
                                                        return (
                                                            <tr className="text-gray-700" key={index + 1}>
                                                                <td className="px-4 py-3 text-sm border">{index + 1}</td>
                                                                <td className="px-4 py-3 text-sm border">{item.chapter}</td>
                                                                <td className="px-4 py-3 text-sm border">{item.topic}</td>
                                                                <td className="px-4 py-3 text-sm border">-</td>
                                                                <td className="px-4 py-3 text-sm border">
                                                                    <div className="flex flex-row gap-2">
                                                                        <input type='file' name='fileAnswer' />
                                                                        <svg xmlns="http://www.w3.org/2000/svg" onClick={() => { setStructured(true); }} fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="cursor-pointer w-6 h-6 text-primary">
                                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                                        </svg>
                                                                        <Link to=''>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 text-primary cursor-pointer">
                                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                                                            </svg>
                                                                        </Link>
                                                                        <button type="button"  >
                                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 text-primary cursor-pointer">
                                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                                                            </svg>
                                                                        </button>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }

                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </section>

                            </ContentDiv>
                        </div>
                    }
                    {
                        structured &&
                        <div className=" bg-background -mt-10 p-6">
                            <ContentDiv>
                                {/* Table */}
                                <section className="flex flex-col  container ml-4  mx-4">
                                    <div className="w-full mb-8 overflow-hidden rounded-lg shadow-lg ">
                                        <div className="w-full overflow-x-auto">
                                            <table className="w-full border-spacing">
                                                <thead>
                                                    <tr className="text-md font-semibold tracking-wide text-left text-white bg-primary uppercase border-b border-gray-600">
                                                        <th className="px-4 py-3">S.NO</th>
                                                        <th className="px-4 py-3">Question</th>

                                                    </tr>
                                                </thead>
                                                <tbody className="bg-white">
                                                    {Array.isArray(qustionListStructure) ? qustionListStructure.map((item, index) => {

                                                        return (
                                                            <tr className="text-gray-700" key={index + 1}>
                                                                <td className="px-4 py-3 text-3xl text-center border">{index + 1}</td>

                                                                <td className="px-4 py-3 text-3xl text-center border">{item.question}</td>

                                                            </tr>
                                                        )
                                                    }

                                                    ) : []}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </section>

                            </ContentDiv>
                        </div>

                    }
                    {
                        mcqQuestionByTopic &&
                        <div className=" bg-background -mt-10 p-6">
                            <ContentDiv>
                                {/* Table */}
                                <section className="flex flex-col  container ml-4  mx-4">
                                    <div className="w-full mb-8 overflow-hidden rounded-lg shadow-lg ">
                                        <div className="w-full overflow-x-auto">
                                            <table className="w-full border-spacing">
                                                <thead>
                                                    <tr className="text-md font-semibold tracking-wide text-left text-white bg-primary uppercase border-b border-gray-600">
                                                        <th className="px-4 py-3">S.NO</th>
                                                        <th className="px-4 py-3">Question</th>
                                                        <th className="px-4 py-3">Answer</th>
                                                        <th className="px-4 py-3">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="bg-white">
                                                    {Array.isArray(mcqQuestionList.questions) ? mcqQuestionList.questions.map((item, index) => {

                                                        return (
                                                            <tr className="text-gray-700" key={index + 1}>
                                                                <td className="px-4 py-3  text-center border">{index + 1}</td>
                                                                <td className="py-3 px-4 text-sm border">
                                                                    <img className='object-cover ' alt={'img'} src={`https://cos-backend.cambridgeonlineschool.org/Uploads/Questions/${item.question}`} /></td>
                                                                <td className="px-4 py-3  text-center border">{item.correctAnswer}</td>
                                                                <td className="px-4 py-3 text-sm border">
                                                                    <div className="flex flex-row gap-2">

                                                                        <div onClick={() => { removeQuestion(item) }} >
                                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 text-primary cursor-pointer">
                                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                                                            </svg>
                                                                        </div>

                                                                    </div>

                                                                </td>
                                                            </tr>
                                                        )
                                                    }

                                                    ) : []}
                                                </tbody>
                                            </table>
                                        </div>
                                        {showModal ? (
                                            <>
                                                <div
                                                    className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                                                >
                                                    <div className="relative w-auto my-6 mx-auto max-w-3xl">
                                                        {/*content*/}
                                                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                                            {/*header*/}
                                                            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                                                <h3 className="text-2xl font-semibold">
                                                                    CourseId: <span className='text-primary'>{ }</span>
                                                                </h3>
                                                                <button
                                                                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                                                    onClick={() => setShowModal(false)}

                                                                >
                                                                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                                                        ×
                                                                    </span>
                                                                </button>
                                                            </div>
                                                            {/*body*/}
                                                            <div className="relative p-6 flex-auto">
                                                                <div className="flex justify-center">
                                                                    <div className="mb-3 xl:w-96">




                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/*footer*/}
                                                            <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                                                <button
                                                                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                                                    type="button"
                                                                    onClick={() => setShowModal(false)}
                                                                >
                                                                    Close
                                                                </button>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                                            </>
                                        ) : null}
                                        {isLoading && <LoadingSpinner />}
                                    </div>
                                </section>

                            </ContentDiv>
                        </div>

                    }
                    {
                        mcqQuestion &&
                        <div className=" bg-background -mt-10 p-6">
                            <ContentDiv>
                                <section className="flex flex-col  container ml-4  mx-4">
                                    <div className="w-full mb-8 overflow-hidden rounded-lg shadow-lg ">
                                        <div className="w-full overflow-x-auto">
                                            <table className="w-full border-spacing">
                                                <thead>
                                                    <tr className="text-md font-semibold tracking-wide text-left text-white bg-primary uppercase border-b border-gray-600">
                                                        <th className="px-4 py-3">Chapter</th>
                                                        <th className="px-4 py-3">Topics</th>
                                                        <th className="px-4 py-3">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="bg-white">
                                                    {Array.isArray(chapterTopic) ? chapterTopic.map((item, index) => {
                                                        return (
                                                            <tr className="text-gray-700" key={index + 1}>
                                                                <td className="px-4 py-3 text-sm border">{item.chapterName}</td>
                                                                <td className="px-4 py-3 text-sm border">{item.topic}</td>
                                                                <td className="px-4 py-3 text-sm border">
                                                                    <div className="flex flex-row gap-2">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" onClick={() => { loadTopicQuestions(item); setStructured(false); setMcqQuestionByTopic(true); }} fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="cursor-pointer w-6 h-6 text-primary">
                                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                                        </svg>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                    ) : []}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </section>

                            </ContentDiv>
                        </div>
                    }

                </main>
            </div>
        </>
    )
}
export default TeacherTopic