import {useNavigate} from 'react-router-dom'
import React from 'react';
export default function BackButton(props) {
     const navigate = useNavigate()
    return (
        <>
            <div className="flex flex-row">
                <div className="flex flex-col">
                    <div className="mt-4 grid grid-cols-1 px-8 ml-4 gap-8 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4  ">
                        <div className="flex items-start rounded-xl bg-white  text-white p-4 shadow-lg cursor-pointer" 
                         onClick={() => {navigate(props.url); }}
                        >
                            <div className="">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 text-primary">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}