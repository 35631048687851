import { useState, useEffect } from "react";
import Sidebar from '../../../components/Sidebar'
import ContentDiv from "../../../components/ContentDiv";
import React from 'react';
import { getStudent, createStudent, deleteStudent, editStudent } from '../../../api/student'
import { BASE_URL } from "../../../utiles/constant";
import axios from "axios";
const Student = () => {

    const [table, setTable] = useState(true)
    const [form, setFrom] = useState(false)
    const [record, setRecord] = useState([])
    const [emailExist, setEmailExist] = useState(false);
    const [delModal, setDelModal] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [msg, setMessage] = useState(false);
    const [edit, setEdit] = useState({});
    const [delStudent, setDelStudent] = useState({});
    const [passwordShown, setPasswordShown] = useState(false);
    const user =  localStorage.getItem('user')
    const token  = JSON.parse(user);
    console.log(token,"tt")
    
    const initialState = {
        name: "",
        fname: "",
        email: "",
        password: "",
        phone: "",
        address: "",
        city: "",
        postal: "",
        country: "",
        state: ""
    }

    const [inputField, setInputField] = useState(initialState)


    const addHandler = () => {
        setTable(false)
        setFrom(true)
    }
    const viewHandler = () => {
        setTable(true)
        setFrom(false)
    }
    const onInputChange = (e) => {
        setInputField({ ...inputField, [e.target.name]: e.target.value })
    }

    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };
    const onSubmit = async () => {
        await createStudent(inputField).then(res => {
            setInputField(initialState)
            setFrom(false);
            loadData();
            setTable(true);
            console.log(res,"res")
        }).catch(err => {
            console.log(err.response.data.Message, 'ree')
            setEmailExist(err.response.data.Message);
            setMessage(true)
            setTimeout(() => {
                setMessage(false)
                setEmailExist(false)
            }, 5000)
        });
    }

    const onChangeEdit = (e) => {
        setEdit({ ...edit, [e.target.name]: e.target.value });
    }

    const loadData = async () => {
        return await axios.get(`${BASE_URL}${getStudent}`,
            { headers: { "Authorization": `Bearer ${token.token}` } }
        ).then(res => {
            setRecord(res.data);
        }).catch(err => {
            console.log(err)
        })
    };


    useEffect(() => {
        loadData();
    }, [])

    const onSubmitEdit = async (edit) => {
        await editStudent(edit)
        loadData();
    }

    const onSubmitDel = async (del) => {
        await deleteStudent(del)
        loadData();
    }

    return (
        <>
            <div className="flex h-screen justify-center bg-background w-full">
                <aside className="w-14 fixed left-0 top-0  bg-slate-700">
                    <Sidebar />
                </aside>
                <main className="flex-1 ml-10">
                    <div className="p-10">
                        <h1 className=" text-center text-primary mb-4 text-2xl">The Cambridge Online Digital School</h1>
                        <ContentDiv >
                            <div className="flex flex-row">
                                <div className="flex flex-col">
                                    <div className="mt-4 grid grid-cols-1 px-8 ml-4 gap-8 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4  ">
                                        <div className="flex items-start rounded-xl bg-white p-4 shadow-lg cursor-pointer" onClick={() => addHandler()}>
                                            <div className="flex h-16 w-16 items-center justify-center rounded-full border border-primary bg-white-50">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-primary" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
                                                </svg>
                                            </div>
                                            <div className="ml-4">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="text-primary float-right w-6 h-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                                </svg>
                                                <p className="mt-2 text-sm text-gray-500">Add Students</p>
                                            </div>
                                        </div>
                                        <div className="flex items-start rounded-xl bg-white p-4 shadow-lg cursor-pointer" onClick={() => viewHandler()}>
                                            <div className="flex h-16 w-16 items-center justify-center rounded-full border border-primary bg-white-50">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-primary" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
                                                </svg>
                                            </div>
                                            <div className="ml-4">
                                                <span className="flex justify-between">
                                                </span>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="text-primary float-right w-6 h-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607zM13.5 10.5h-6" />
                                                </svg>
                                                <p className="mt-2 text-sm text-gray-500">View  Students</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ContentDiv>
                    </div>
                    <div className=" bg-background -mt-4 ">
                        <ContentDiv>
                            {/* Table */}
                            {table &&
                                <section className="flex flex-col  container ml-12 px-4   lg:ml-0    ">
                                    <h1 className="text-center text-xl text-primary mb-4  md:text-left  text-xl lg:text-left  text-2xl xl:text-left   text-2xl">Students List</h1>

                                    <div className=" mb-8 overflow-hidden rounded-lg shadow-lg ">
                                        <div className="w-full overflow-x-auto">
                                            <table className="w-full border-spacing">
                                                <thead>

                                                    <tr className="text-md font-semibold tracking-wide text-left text-white bg-primary uppercase border-b border-gray-600">
                                                    <th className="px-4 py-3">S.No</th>
                                                        <th className="px-4 py-3">Name</th>
                                                        <th className="px-4 py-3">Father</th>
                                                        <th className="px-4 py-3">Email</th>
                                                        <th className="px-4 py-3">Code</th>
                                                        <th className="px-4 py-3">Status</th>
                                                        <th className="px-4 py-3">Date</th>
                                                        <th className="px-4 py-3">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="bg-white">
                                                    {Array.isArray(record) ? record.map((item,index) => {
                                                        return (

                                                            <tr className="text-gray-700" key={index+1}>
                                                                <td className="px-4 py-3 text-ms font-semibold border">{index+1}</td>
                                                                <td className="px-4 py-3 text-ms font-semibold border">{item.name}</td>
                                                                <td className="px-4 py-3 text-ms font-semibold border">{item.fname}</td>
                                                                <td className="px-4 py-3 text-ms font-semibold border">{item.email}</td>
                                                                <td className="px-4 py-3 text-ms font-semibold border">{item.code}</td>
                                                                <td className="px-4 py-3 text-xs border">
                                                                    {item.status == true ? <span className="px-2 py-1 font-semibold leading-tight text-green-700 bg-green-100 rounded-sm">Active</span> : <span className="px-2 py-1 font-semibold leading-tight text-red-700 bg-red-100 rounded-sm">Non-Active</span>}
                                                                </td>
                                                                <td className="px-4 py-3 text-sm border">{item.date}</td>

                                                                <td className="px-4 py-3 text-sm border">
                                                                    <div className="flex flex-row gap-2">
                                                                        <div onClick={() => { setShowModal(true); setEdit(item) }}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 text-primary cursor-pointer">
                                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                                                            </svg>
                                                                        </div>
                                                                        <div onClick={() => { setDelModal(true); setDelStudent(item) }}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 text-primary cursor-pointer">
                                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                                                            </svg>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }) : []}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </section>
                            }
                            {form &&
                                <div className="bg-white rounded shadow-lg p-8 mb-6 ml-6 md:ml-14 w-5/6 lg:ml-12 w-5/6   ">
                                    <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
                                        <div className="text-gray-600">
                                            <p className="text-primary text-lg">Student Form</p>
                                            <p>Please fill out all the fields *</p>

                                        </div>

                                        <div className="lg:col-span-2">
                                            <p className="text-center"></p>

                                            <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5">
                                                <div className="md:col-span-5">

                                                    <label htmlFor="name">Student Name </label>  {inputField.name === '' ? msg && <span className='text-primary'> is  required</span> : ''}
                                                    <input type="text" name="name" id="name" required={true} className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" value={inputField.name} onChange={(e) => onInputChange(e)} />
                                                </div>
                                                <div className="md:col-span-5">
                                                    <label htmlFor="fname">Father Name </label>  {inputField.fname === '' ? msg && <span className='text-primary'> is  required</span> : ''}
                                                    <input type="text" name="fname" id="fname" required className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" value={inputField.fname} onChange={(e) => onInputChange(e)} />
                                                </div>

                                                <div className="md:col-span-5">

                                                    <label htmlFor="email">Email </label>    {inputField.email === '' ? msg && <span className='text-primary'> is  required</span> : ''}
                                                    {emailExist && <span className='text-primary'> {emailExist} </span>}
                                                    <input type="email" name="email" id="email" required className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" placeholder="abc@gmail.com" value={inputField.email} onChange={(e) => onInputChange(e)} />
                                                </div>
                                                <div className="md:col-span-5">
                                                    <label htmlFor="password">Password </label> {inputField.password === '' ? msg && <span className='text-primary'> is  required</span> : ''}
                                                    <input type={passwordShown ? "text" : "password"} name="password" id="password" required className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" placeholder="password" value={inputField.password} onChange={(e) => onInputChange(e)} />
                                                    <p className="text-right cursor-pointer" onClick={togglePassword}>Show Password</p>
                                                </div>

                                                <div className="md:col-span-5">
                                                    <label htmlFor="address">Address / Street</label>
                                                    <input type="text" name="address" id="address" required className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" placeholder="Street/Address" value={inputField.address} onChange={(e) => onInputChange(e)} />
                                                </div>
                                                <div className="md:col-span-3">
                                                    <label htmlFor="phone">Phone</label>
                                                    <input type="text" name="phone" id="phone" required className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" placeholder="Phone Number" value={inputField.phone} onChange={(e) => onInputChange(e)} />
                                                </div>
                                                <div className="md:col-span-2">
                                                    <label htmlFor="city">City</label>
                                                    <input type="text" name="city" id="city" required className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" placeholder="city" value={inputField.city} onChange={(e) => onInputChange(e)} />
                                                </div>

                                                <div className="md:col-span-2">
                                                    <label htmlFor="country">Country / Region</label>
                                                    <div className="h-10 bg-gray-50 flex border border-gray-200 rounded items-center mt-1" >
                                                        <input name="country" id="country" required className="px-4 appearance-none outline-none text-gray-800 w-full bg-transparent" value={inputField.country} placeholder="Country/Region" onChange={(e) => onInputChange(e)} />

                                                    </div>
                                                </div>

                                                <div className="md:col-span-2">
                                                    <label htmlFor="state">State / Province</label>
                                                    <div className="h-10 bg-gray-50 flex border border-gray-200 rounded items-center mt-1">
                                                        <input name="state" id="state" value={inputField.state} required className="px-4 appearance-none outline-none text-gray-800 w-full bg-transparent" placeholder="State/Province" onChange={(e) => onInputChange(e)} />

                                                    </div>
                                                </div>

                                                <div className="md:col-span-1">
                                                    <label htmlFor="postal">Zipcode</label>
                                                    <input type="text" name="postal" id="zipcode" required className="transition-all flex items-center h-10 border mt-1 rounded px-4 w-full bg-gray-50" placeholder="postal code" value={inputField.postal} onChange={(e) => onInputChange(e)} />
                                                </div>

                                                <div className="md:col-span-5 mt-2 text-right">
                                                    <div className="inline-flex items-end">
                                                        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={onSubmit}>Submit</button>
                                                    </div>
                                                </div>
                                                {emailExist && <span className='text-primary'> Fill the form properly </span>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {showModal ? (
                                <>
                                    <div
                                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                                    >
                                        <div className="relative w-auto my-6 mx-auto max-w-3xl">
                                            {/*content*/}
                                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                                {/*header*/}
                                                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                                    <h3 className="text-2xl font-semibold">
                                                        Edit Record of :  <span className='text-primary'>{edit.name}</span>
                                                    </h3>
                                                    <button
                                                        className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                                        onClick={() => setShowModal(false)}

                                                    >
                                                        <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                                            ×
                                                        </span>
                                                    </button>
                                                </div>
                                                {/*body*/}
                                                <div className="relative p-6 flex-auto">
                                                    <div className="flex justify-center">
                                                        <div className="mb-3 xl:w-96">
                                                            <label htmlFor="course" className="form-label inline-block mb-2 text-gray-700">Student Name</label>
                                                            <input
                                                                type={"text"}
                                                                className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                                                value={edit.name}
                                                                name="name"
                                                                onChange={(e) => onChangeEdit(e)}
                                                                placeholder="Your message"
                                                            />
                                                            <label htmlFor="course" className="form-label inline-block mb-2 text-gray-700">Father Name</label>
                                                            <input
                                                                type={"text"}
                                                                className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                                                value={edit.fname}
                                                                name="fname"
                                                                onChange={(e) => onChangeEdit(e)}
                                                                placeholder="Your message"
                                                            />
                                                            <label htmlFor="course" className="form-label inline-block mb-2 text-gray-700">Email</label>
                                                            <input
                                                                type={"text"}
                                                                className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                                                value={edit.email}
                                                                name="email"
                                                                onChange={(e) => onChangeEdit(e)}
                                                                placeholder="Your message"
                                                            />
                                                          
                                                            <label htmlFor="course" className="form-label inline-block mb-2 text-gray-700">Phone</label>
                                                            <input
                                                                type={"text"}
                                                                className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                                                value={edit.phone}
                                                                name="phone"
                                                                onChange={(e) => onChangeEdit(e)}
                                                                placeholder="Your message"
                                                            />
                                                            <label htmlFor="course" className="form-label inline-block mb-2 text-gray-700">address</label>
                                                            <input
                                                                type={"text"}
                                                                className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                                                value={edit.address}
                                                                name="address"
                                                                onChange={(e) => onChangeEdit(e)}
                                                                placeholder="Your message"
                                                            />
                                                            <label htmlFor="course" className="form-label inline-block mb-2 text-gray-700">City</label>
                                                            <input
                                                                type={"text"}
                                                                className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                                                value={edit.city}
                                                                name="city"
                                                                onChange={(e) => onChangeEdit(e)}
                                                                placeholder="Your message"
                                                            />
                                                            <label htmlFor="course" className="form-label inline-block mb-2 text-gray-700">Postal</label>
                                                            <input
                                                                type={"text"}
                                                                className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                                                value={edit.postal}
                                                                name="postal"
                                                                onChange={(e) => onChangeEdit(e)}
                                                                placeholder="Your message"
                                                            />
                                                            <label htmlFor="course" className="form-label inline-block mb-2 text-gray-700">Country</label>
                                                            <input
                                                                type={"text"}
                                                                className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                                                value={edit.country}
                                                                name="country"
                                                                onChange={(e) => onChangeEdit(e)}
                                                                placeholder="Your message"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                {/*footer*/}
                                                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                                    <button
                                                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                                        type="button"
                                                        onClick={() => setShowModal(false)}
                                                    >
                                                        Close
                                                    </button>
                                                    <button
                                                        className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                                        type="button"
                                                        onClick={() => { setShowModal(false); onSubmitEdit(edit) }}
                                                    >
                                                        Save Changes
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                                </>
                            ) : null}

                            {delModal ? (
                                <>
                                    <div
                                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                                    >
                                        <div className="relative w-auto my-6 mx-auto max-w-3xl">
                                            {/*content*/}
                                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                                {/*header*/}
                                                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">

                                                    <label htmlFor="code" className="form-label inline-block mb-2 text-gray-700">Are you want to sure delete this Student: {delStudent.name} ?</label>
                                                </div>
                                                {/*body*/}

                                                <div className="relative p-6 flex-auto">
                                                    <div className="flex justify-center">
                                                        <div className="mb-3 xl:w-96">

                                                            <div className="flex grid-col-2 justify-center gap-4">
                                                                <button
                                                                    className="bg-red-500 text-white active:bg-red-600 text-white-500 background-transparent rounded font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                                                    type="button"
                                                                    onClick={() => { setDelModal(false); onSubmitDel(delStudent) }}

                                                                >
                                                                    Yes
                                                                </button>
                                                                <button
                                                                    className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                                                    type="button"
                                                                    onClick={() => { setDelModal(false) }}
                                                                >
                                                                    No
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/*footer*/}
                                                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                                </>
                            ) : null}
                        </ContentDiv>
                    </div>
                </main>
            </div>
        </>
    )
}
export default Student