
import logo from '../../../assests/images/logos/Cambridge-logo.webp';
import ContentDiv from "../../../components/ContentDiv";
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useState } from 'react';
import Sidebar from '../../../components/Sidebar';
const OwnerAddTopicQuestion = () => {

    const  {subjectId,subjectName} = useParams();
    const [viewChapter,setViewChapter]=useState(false)
    const [viewQuestion,setViewQuestion]=useState(false)
    const [topicShow, setTopicShow] = useState(false)
    const [mcqs,setMCQs]=useState(false);
    const [structuredTopic,setStructuredTopic] = useState(false);
    const [structured,setStructured] = useState(false);
    const[mcqQuestion,setMcqQuestion] = useState(false);
    //const [courseData, setCourseData] = useState({})
    const [chapterData, setChapterData] = useState({})
    const [topicData,setTopicData] =useState({});
    
    
 
    // const token = {
    //     teacherId: 1
    // }
  
    const chapter = [
        {
            id: 1,
            courseId: 1,
            chapterName: "Introduction To English"
        },
        {
            id: 2,
            courseId: 1,
            chapterName: "Complex English"
        },
        {
            id: 3,
            courseId: 2,
            chapterName: "Introduction To Math"
        },
        {
            id: 4,
            courseId: 2,
            chapterName: "Complex Math"
        },
        {
            id: 5,
            courseId: 2,
            chapterName: "Multiply Math"
        }

    ]
    const topic = [
        {
            id: 1,
            courseId:1,
            course:"English",
            chapterId: 1,
            chapter:"Introduction To English",
            topic: "English Part 1"
        }, {
            id: 2,
            chapterId: 1,
            chapter:"Introduction To English",
            courseId:1,
            course:"English",
            topic: "English Part 2",
        },
        {
            id: 3,
            chapter:"Complex English",
            chapterId: 2,
            course:"Math",
            courseId:2,
            topic: "Complex English Part 1",

        },
        {
            id: 4,
            chapter:"Complex English",
            chapterId: 2,
            course:"Math",
            courseId:2,
            topic: "Complex English Part 2"
        },
        {
            id: 5,
            chapterId: 3,
            chapter:"Introduction To Math",
            course:"Math",
            courseId:2,
            topic: "Math Part 1"
        }
    ]

    const questionList =[
        {
            id: 1,
            img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRUWMeI5-o79F2ueD-DFCFar2mC2K2qziWQHisH7JfLy6-xhbJReaswqj5S3j87fb9FnvM&usqp=CAU",
            code: "10",
            answer:"a"

        },
        {
            id: 2,
            img: "https://www.fast-standard.org/wp-content/uploads/2016/03/sample_question_1.png",
            code: "20",
            answer:"b"
        },
        {
            id: 3,
            img: "https://www.fast-standard.org/wp-content/uploads/2016/03/sample_question_5.png",
            code: "30",
            answer:"c"
        },
        {
            id: 4,
            img: "https://images.slideplayer.com/26/8777630/slides/slide_2.jpg",
            code: "40",
            answer:"d"
        }
    ]


const structuredQuestion =[
    {
        id:1,
        courseId:1,
        topicId:1,
        chapter:"Introduction To Physics",
        topic:"Physics Principle"

    },
    {
        id:1,
        courseId:2,
        chapter:"Introduction To Math ",
        topicId:2,
        topic:"Derivative"

    },
    {
        id:1,
        courseId:1,
        chapter:"Introduction To Formula",
        topicId:3,
        topic:"Chemistry Formula"

    }
]    
const structuredList =[
    {
        id:1,
        question:"Define Archamida's Principle",
        topicId:1,
        topic:"Physic Principle's"
    },
    {
        id:2,
        question:"Define Archamida's Principle Equation",
        topicId:1,
        topic:"Physic Principle's"
    },
    {
        id:3,
        question:"What are the characteristics of thermometer",
        topicId:2,
        topic: "Mathematics Measuring",
       
    },
     {
        id:4,
        question:"Chemical formula of water?",
        topicId:3,
        topic:"Chemistry Formula"
    }
]

    return (
        <>
           <div className="flex h-screen justify-center bg-background w-full">
                <aside className="w-14 fixed left-0 top-0  bg-slate-700">
                    <Sidebar />
                </aside>
                <main className="flex-1 ml-10">
                    <div className="p-10">
                        <ContentDiv classes={'justify-center'}>
                            <img className='' src={logo} alt={"CambridgeOnline"} height={250} width={250} />
                        </ContentDiv>
                    </div>
                    <div className=" bg-background -mt-10 p-6">
                        <ContentDiv >
                            <section className="flex flex-col container mx-4 p-2 -mt-6">
                                <div className='md:grid grid-cols-3 gap-2'>
                                    {/* {
                                        data.map(item => {
                                            return ( */}
                                                <div className=" mb-4 p-6 max-w-sm bg-white rounded-lg border border-gray-200 shadow-md bg-white dark:border-shadow-lg">
                                                    
                                                        <h5 className="mb-2  font-medium tracking-tight  text-primary">Course</h5>
                                                        <h6 className="mb-2 text-xl font-medium tracking-tight  text-primary">{subjectName}</h6>
                                                   
                                                    <div className='grid grid-cols-2 gap-4'>
                                                        <Link onClick={() => { setStructured(false);setViewChapter(true);setTopicShow(false); setViewQuestion(false);setMCQs(false); setStructuredTopic(false);setMcqQuestion(false) }} className="inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-primary rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-blue-300 bg-primary dark:hover:bg-red-700 dark:focus:ring-blue-800">
                                                            Chapters
                                                            <svg aria-hidden="true" className="ml-2 -mr-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                                        </Link>
                                                        <Link onClick={()=>{setViewChapter(false) ;setViewQuestion(true); setTopicData(false);setMcqQuestion(false);setStructuredTopic(false) }} className="inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-primary rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-blue-300 bg-primary dark:hover:bg-red-700 dark:focus:ring-blue-800">
                                                            View Question
                                                            <svg aria-hidden="true" className="ml-2 -mr-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                                        </Link>
                                                    </div>
                                                </div>
                                            {/* )
                                        })
                                    }  */}
                                </div>
                            </section>
                        </ContentDiv>
                        </div>
                      {viewChapter && <div className=" bg-background -mt-10 p-6">
                            <ContentDiv>
                                {/* Table */}
                                <section className="flex flex-col  container ml-4  mx-4">
                                    <div className='lg:grid grid-cols-2 gap-4'>
                                        <div className="w-full mb-8  overflow-hidden rounded-lg shadow-lg ">
                                        <h1 className="text-xl  text-primary">Chapters List</h1>
                                            <div className="w-full  overflow-x-auto">
                                                <table className="w-full border-spacing">
                                                    <thead>
                                                        <tr className="text-md font-semibold tracking-wide text-left text-white bg-primary uppercase border-b border-gray-600">
                                                            <th className="px-4 py-3">No</th>
                                                            <th className="px-4 py-3">Chapter</th>
                                                            <th className="px-4 py-3">View</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="bg-white">
                                                        {chapter.filter(chap => chap.courseId === parseInt(subjectId)).map((item, index) => {
                                                            
                                                            return (
                                                                <tr className="text-gray-700" key={item+1}>
                                                                    <td className="px-4 py-3 text-ms font-semibold border">{index + 1}</td>
                                                                    <td className="px-4 py-3 text-ms font-semibold border">{item.chapterName}</td>
                                                                    <td className="px-4 py-3 text-sm border">
                                                                        <div className="flex flex-row gap-2">
                                                                            <button type="button" onClick={() => { setTopicShow(true); setChapterData(item); }}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-primary">
                                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zM3.75 12h.007v.008H3.75V12zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm-.375 5.25h.007v.008H3.75v-.008zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
                                                                            </svg>
                                                                            </button>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        {topicShow && <div className="w-full mb-8  overflow-hidden rounded-lg shadow-lg ">
                                            <div className="w-full  overflow-x-auto">
                                            <h1 className="text-xl  text-primary">Topic List</h1>
                                                <table className="w-full border-spacing">
                                                    <thead>
                                                        <tr className="text-md font-semibold tracking-wide text-left text-white bg-primary uppercase border-b border-gray-600">
                                                            <th className="px-4 py-3">No</th>
                                                            <th className="px-4 py-3">Topic</th>
                                                            <th className="px-4 py-3">Add</th>
                                                        </tr>
                                                    </thead>
                                                    {topic.filter(topicItem => topicItem.chapterId === chapterData.id ).map((item, index) => {
                                                        return (
                                                            <tbody className="bg-white">

                                                                <tr className="text-gray-700" key={item._id}>
                                                                    <td className="px-4 py-3 text-ms font-semibold border">{index + 1}</td>
                                                                    <td className="px-4 py-3 text-ms font-semibold border">{item.topic}</td>
                                                                    <td className="px-4 py-3 text-sm border">
                                                                        <div className="flex flex-row gap-2">
                                                                            
                                                                          <Link exact to={`/teahcer/add/questions/${item.courseId}/${item.course}/${item.chapterId}/${item.chapter}/${item.id}/${item.topic}`}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 text-primary">
                                                                                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm.75-11.25a.75.75 0 00-1.5 0v2.5h-2.5a.75.75 0 000 1.5h2.5v2.5a.75.75 0 001.5 0v-2.5h2.5a.75.75 0 000-1.5h-2.5v-2.5z" clipRule="evenodd" />
                                                                            </svg>
                                                                            <span className='text-primary'>MCQ's</span>
                                                                            </Link>|
                                                                            <Link exact to={`/teacher/assessments/StructuredQuestion/${item.courseId}/${item.course}/${item.chapterId}/${item.chapter}/${item.id}/${item.topic}`}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 text-primary">
                                                                                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm.75-11.25a.75.75 0 00-1.5 0v2.5h-2.5a.75.75 0 000 1.5h2.5v2.5a.75.75 0 001.5 0v-2.5h2.5a.75.75 0 000-1.5h-2.5v-2.5z" clipRule="evenodd" />
                                                                            </svg>
                                                                            <span className='text-primary'>Structured</span>
                                                                            </Link>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        )
                                                    }
                                                    )}
                                                </table>
                                            </div>
                                        </div>
                                        }
                                    </div>
                                </section>
                            </ContentDiv>
                        </div>
                        }
                      



{
viewQuestion &&

<div className=" bg-background -mt-12 p-6">
<ContentDiv>
    {/* Table */}
 <section className="flex flex-col  container ml-4  mx-4">
<div className=" mb-4 p-6 max-w-sm bg-white rounded-lg border border-gray-200 shadow-md bg-white dark:border-shadow-lg">
                                                    

<h6 className="mb-2 text-xl font-medium tracking-tight  text-primary">{subjectName}{subjectId }</h6>
<div className='grid grid-cols-2 gap-4'>
    
<Link onClick={() => { setStructured(false);setViewChapter(false);setTopicShow(false); setViewQuestion(true); setMCQs(false);setStructuredTopic(false);setMcqQuestion(true) }} className="inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-primary rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-blue-300 bg-primary dark:hover:bg-red-700 dark:focus:ring-blue-800">
    MCQ's
    <svg aria-hidden="true" className="ml-2 -mr-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
</Link>
<Link onClick={()=>{setViewChapter(false) ;setViewQuestion(true);setStructuredTopic(true);setMCQs(false);setMcqQuestion(false)}} className="inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-primary rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-blue-300 bg-primary dark:hover:bg-red-700 dark:focus:ring-blue-800">
    Structured 
    <svg aria-hidden="true" className="ml-2 -mr-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
</Link>
</div>
</div>

</section>
</ContentDiv>
</div>
}

{ mcqs &&    <div className=" bg-background -mt-10 p-6">
                                <ContentDiv>
                                    {/* Table */}
                                <section className="flex flex-col  container ml-4  mx-4">
                                        <h1 className="text-center text-xl text-primary mb-4  md:text-left  text-xl lg:text-left  text-2xl xl:text-left   text-2xl">Total Questions: {questionList.length} </h1>
        
                                        <div className="w-full mb-8 overflow-hidden rounded-lg shadow-lg ">
                                            <div className="w-full overflow-x-auto">
                                                <table className="w-full border-spacing">
                                                    <thead>
                                                        <tr className="text-md font-semibold tracking-wide text-left text-white bg-primary uppercase border-b border-gray-600">
                                                            <th className="px-4 py-3">Question</th>
                                                            <th className="px-4 py-3">Answer</th>
                                                            <th className="px-4 py-3">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="bg-white">
                                                  
                                                       {questionList.map((item)=>
                                                       {
                                                        return(
                                                            <tr className="text-gray-700" key={item._id}>
                                                         
                                                            <td className="px-4 py-3 text-ms font-semibold border">
                                                                <img height={'auto'} width={500} src={item.img} alt={'question'}/>
                                                                
                                                             </td>
                                                          
                                                            <td className="px-4 py-3 text-sm border">{item.answer}</td>
                                                            <td className="px-4 py-3 text-sm border">
                                                            <div className="flex flex-row gap-2">

                                               
<Link to=''>
 
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 text-primary cursor-pointer">
    <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
</svg>
</Link>
<button type="button"  >
<svg  xmlns="http://www.w3.org/2000/svg"  fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 text-primary cursor-pointer">
    <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
</svg>
</button>
</div>
                                                           </td>
                                                        </tr>
                                                        )
                                                       }
                                                       
                                                       )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </section> 
                                 
                                </ContentDiv>
                            </div>
}

{
structuredTopic &&
<div className=" bg-background -mt-10 p-6">
                                <ContentDiv>
                                    {/* Table */}
                                <section className="flex flex-col  container ml-4  mx-4">
                                        <h1 className="text-center text-xl text-primary mb-4  md:text-left  text-xl lg:text-left  text-2xl xl:text-left   text-2xl">Total Topic: {structuredQuestion.length} </h1>
        
                                        <div className="w-full mb-8 overflow-hidden rounded-lg shadow-lg ">
                                            <div className="w-full overflow-x-auto">
                                                <table className="w-full border-spacing">
                                                    <thead>
                                                        <tr className="text-md font-semibold tracking-wide text-left text-white bg-primary uppercase border-b border-gray-600">
                                                       
                                                        <th className="px-4 py-3">Chapter</th>
                                                        <th className="px-4 py-3">Topics</th>
                                                        <th className="px-4 py-3">File</th>
                                                           
                                                            <th className="px-4 py-3">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="bg-white">
                                                  
                                                       {structuredQuestion.filter(q=>q.courseId=== parseInt(subjectId)).map((item)=>
                                                       {
                                                        return(
                                                            <tr className="text-gray-700" key={item.id}>
                                                         
                                                          
                                                         <td className="px-4 py-3 text-sm border">{item.chapter}</td>
                                                            <td className="px-4 py-3 text-sm border">{item.topic}</td>
                                                            <td className="px-4 py-3 text-sm border">-</td>
                                                            <td className="px-4 py-3 text-sm border">
                                                            <div className="flex flex-row gap-2">
                                                                <input type='file' name='fileAnswer'/>
                                                                <svg xmlns="http://www.w3.org/2000/svg"   onClick={()=>{setStructured(true);setTopicData(item)}} fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="cursor-pointer w-6 h-6 text-primary">
  <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
  <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
</svg>


                                               
<Link to=''>
 
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 text-primary cursor-pointer">
    <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
</svg>
</Link>
<button type="button"  >
<svg  xmlns="http://www.w3.org/2000/svg"  fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 text-primary cursor-pointer">
    <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
</svg>
</button>
</div>
                                                           </td>
                                                        </tr>
                                                        )
                                                       }
                                                       
                                                       )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </section> 
                                 
                                </ContentDiv>
                            </div>
}


{
    structured &&
    <div className=" bg-background -mt-10 p-6">
                                <ContentDiv>
                                    {/* Table */}
                                <section className="flex flex-col  container ml-4  mx-4">
                                        <h1 className="text-center text-xl text-primary mb-4  md:text-left  text-xl lg:text-left  text-2xl xl:text-left   text-2xl">Total Questions: {questionList.length} </h1>
        
                                        <div className="w-full mb-8 overflow-hidden rounded-lg shadow-lg ">
                                            <div className="w-full overflow-x-auto">
                                                <table className="w-full border-spacing">
                                                    <thead>
                                                        <tr className="text-md font-semibold tracking-wide text-left text-white bg-primary uppercase border-b border-gray-600">
                                                       
                                                        <th className="px-4 py-3">Topic</th>
                                                        <th className="px-4 py-3">Question</th>
                                                    
                                                           
                                                            <th className="px-4 py-3">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="bg-white">
                                                  
                                                       {structuredList.filter(x=>x.topicId===topicData.topicId).map((item)=>
                                                       {
                                                        return(
                                                            <tr className="text-gray-700" key={item.id}>
                                                         
                                                          
                                                         <td className="px-4 py-3 text-sm border">{item.topic}</td>
                                                            <td className="px-4 py-3 text-sm border">{item.question}</td>
                                                          
                                                            <td className="px-4 py-3 text-sm border">
                                                            <div className="flex flex-row gap-2">
                                                              
                                                                


                                               
<Link to=''>
 
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 text-primary cursor-pointer">
    <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
</svg>
</Link>
<button type="button"  >
<svg  xmlns="http://www.w3.org/2000/svg"  fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 text-primary cursor-pointer">
    <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
</svg>
</button>
</div>
                                                           </td>
                                                        </tr>
                                                        )
                                                       }
                                                       
                                                       )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </section> 
                                 
                                </ContentDiv>
                            </div>
    
}

{
    mcqQuestion &&
<div className=" bg-background -mt-10 p-6">
                                <ContentDiv>
                                    {/* Table */}
                                <section className="flex flex-col  container ml-4  mx-4">
                                        <h1 className="text-center text-xl text-primary mb-4  md:text-left  text-xl lg:text-left  text-2xl xl:text-left   text-2xl">Total Topic: {structuredQuestion.length} </h1>
        
                                        <div className="w-full mb-8 overflow-hidden rounded-lg shadow-lg ">
                                            <div className="w-full overflow-x-auto">
                                                <table className="w-full border-spacing">
                                                    <thead>
                                                        <tr className="text-md font-semibold tracking-wide text-left text-white bg-primary uppercase border-b border-gray-600">
                                                       
                                                        <th className="px-4 py-3">Chapter</th>
                                                        <th className="px-4 py-3">Topics</th>
                                                      
                                                           
                                                            <th className="px-4 py-3">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="bg-white">
                                                  
                                                       {structuredQuestion.filter(q=>q.courseId===parseInt(subjectId)).map((item)=>
                                                       {
                                                        return(
                                                            <tr className="text-gray-700" key={item.id}>
                                                         
                                                          
                                                         <td className="px-4 py-3 text-sm border">{item.chapter}</td>
                                                            <td className="px-4 py-3 text-sm border">{item.topic}</td>
                                                           
                                                            <td className="px-4 py-3 text-sm border">
                                                            <div className="flex flex-row gap-2">
                                                             
                                                                <svg xmlns="http://www.w3.org/2000/svg"   onClick={()=>{setStructured(true);setTopicData(item)}} fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="cursor-pointer w-6 h-6 text-primary">
  <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
  <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
</svg>


                                               
<Link to=''>
 
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 text-primary cursor-pointer">
    <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
</svg>
</Link>
<button type="button"  >
<svg  xmlns="http://www.w3.org/2000/svg"  fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 text-primary cursor-pointer">
    <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
</svg>
</button>
</div>
                                                           </td>
                                                        </tr>
                                                        )
                                                       }
                                                       
                                                       )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </section> 
                                 
                                </ContentDiv>
                            </div>

}
                    
                </main>
            </div>
        </>
    )
}
export default OwnerAddTopicQuestion