import { useState, } from "react";
import Sidebar from '../../../components/Sidebar'
import ContentDiv from "../../../components/ContentDiv";
import React from 'react';
import { useParams } from "react-router-dom";
// import axios from "axios";

const AddStructureQuestion = () => {
    const {courseId,course,chapterId,chapter,id,topic} =useParams();

    // const date = new Date();
    // let year = date.getFullYear();
    const [optionList, setoptionList] = useState([{ question: "" }]);
    const [assessment, setAssessment] = useState({
       
        // duration: "",
        // assessmentId: `cos/${date.getTime().toString(36)}/${year}`,
        fileUrl: "",
    })

    let {  
        // duration,
        //  assessmentId,
          fileUrl } = assessment

    const onInputAssessment = (e) => {
        setAssessment({ ...assessment, [e.target.name]: e.target.value })
    }

    const onSubmit = () => {
        console.log('submit', assessment)
        console.log('submit', optionList)
        let data = {
           duration: assessment.duration,
            assessmentId: assessment.assessmentId, fileUrl: assessment.fileUrl, optionList
        }
        console.log(data, 'data')

    }


    // handle input change
    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...optionList];
        list[index][name] = value;
        setoptionList(list);
        console.log(list)
    };
    // handle click event of the Remove button
    const handleRemoveClick = index => {
        const list = [...optionList];
        list.splice(index, 1);
        setoptionList(list);
    };
    // handle click event of the Add button
    const handleAddClick = () => {
        setoptionList([...optionList, { question: "" }]);
    };
    return (
        <>
            <div className="flex h-screen justify-center bg-background w-full">
                <aside className="w-14 fixed left-0 top-0  bg-slate-700">
                    <Sidebar />
                </aside>
                <main className="flex-1 ml-10">
                    <div className="p-10">
                        <h1 className=" text-center text-primary  text-2xl">The Cambridge Online Digital School</h1>
                       
                    </div>
                    <div className=" bg-background -mt-10 p-6">
                        <ContentDiv>
                            <div className="bg-white rounded shadow-lg p-8 mb-6 ml-6 md:ml-14 w-5/6 lg:ml-12 w-5/6   ">
                                <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
                                 

                                    <div className="flex flex-col">
                                    <div className="text-gray-600">
                                          <h3 className="text-xl">Course: <span className="text-primary text-lg">{courseId}  {course}</span> </h3>
                                      </div>
                                        <div className="text-gray-600 mt-4">
                                            <h3 className="text-xl">Chapter: <span className="text-primary text-lg">{chapterId}  {chapter}</span> </h3>
                                        </div>
                                        <div className="text-gray-600 mt-4">  
                                            <h3 className="text-xl">Topic: <span className="text-primary text-lg">{id}  {topic}</span> </h3>
                                        </div>
                                    </div>
                                
                                </div>
                                
                            </div>
                            
                        </ContentDiv>
                    </div>
                    <div className=" bg-background -mt-10 p-6" >
                        <ContentDiv>
                            <div className="bg-white rounded shadow-lg p-8 mb-6 ml-6 md:ml-14 w-5/6 lg:ml-12 w-5/6   ">
                                <div className="grid gap-4 gap-y-2 text-sm grid-cols-1">

                                    <div className="lg:col-span-2">
                                        <div className=" gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5">
                                        
                                            <div className="mb-4 md:col-span-5">
                                            <label htmlFor="full_name" className="text-primary">Answer Key</label>
                                                <input type="file"
                                                    name="fileUrl"
                                                    value={fileUrl}
                                                    id="full_name"
                                                    placeholder="Model Answer Url"
                                                    onChange={(e) => onInputAssessment(e)}
                                                    className="focus:outline-none h-10 border mt-1 rounded px-4 w-full bg-gray-50" />
                                            </div>

                                            <div className="mb-4 md:col-span-5">
                                                <label htmlFor="full_name" className="mb-4 text-primary">Structured Question</label>
                                                {optionList.map((x, i) => {
                                                    return (
                                                        <div className="mb-4 md:col-span-5" key={i + 1}>
                                                            <span className="text-lg">{i + 1}.</span>
                                                            <input type="text" name="question" id="full_name" onChange={(e) => handleInputChange(e, i)} value={x.question} className="h-10 border mt-1 rounded px-4 w-full mb-4 bg-gray-50" />

                                                            {optionList.length !== 1 &&
                                                                <button
                                                                    className="bg-primary hover:bg-red-700 text-white font-bold w-6 h-6  px-2 rounded"
                                                                    onClick={() => handleRemoveClick(i)}>x</button>
                                                            }
                                                            {optionList.length - 1 === i &&
                                                                <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold w-6 h-6  px-2 rounded" onClick={handleAddClick}>+</button>


                                                            }

                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ContentDiv>
                        <div className="md:col-span-5 mt-2 text-center">
                            <div className="inline-flex items-end gap-4">
                                <button onClick={onSubmit} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Submit</button>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}
export default AddStructureQuestion