import logo from '../../../assests/images/logos/Cambridge-logo.webp';
import ContentDiv from "../../../components/ContentDiv";
import { useParams, NavLink, Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import React from 'react';
import { Topic } from '../../../api/topic';
import axios from 'axios';
import { BASE_URL, token } from '../../../utiles/constant';
import { DefaultPlayer as Video } from 'react-html5video';
import 'react-html5video/dist/styles.css';
import { SubTopic } from '../../../api/subTopic';
import { getSubject } from '../../../api/subject';

const OnlineResourceTopic = () => {
    const [hideLink, setHideLink] = useState(false)
    const [sub, setSub] = useState(true)
    const [topicList, setTopicList] = useState([]);
    const [subTopicList, setSubTopicList] = useState([]);
    const [topicUrl, setTopicUrl] = useState({});
    const [introVideo, setIntroVideo] = useState({});
    const navigate = useNavigate()
    const user =  localStorage.getItem('user');
    const token  = JSON.parse(user);
    const [topic, setTopic] = useState({
        id: "",
        assessmentId: "",
        topic: "",
        chapter:"",
        url: "",
        modelAnswer: "",

    })
    const { chapterId, id,courseId } = useParams();

    const onSubmitLogout = () => {
        localStorage.clear();
        navigate('/')
    }

    const getIntroductionVideo = () => {

        setSub(true)
         loadIntro()
        setHideLink(false)
        getTopic()

    }

    const loadIntro = async () => {
        return await axios.get(`${BASE_URL}${getSubject}/${courseId}`,
            { headers: { "Authorization": `Bearer ${token.token}` } }
        ).then(res => {
            setIntroVideo(res.data);
        }).catch(err => {
            console.log(err)
        })
    }

    const getTopic = (filteredtopic) => {
        setTopic(filteredtopic)
        loadTopicUrl(filteredtopic.id)
        setTopicList(filteredtopic)
        setHideLink(true)
        setSub(false)

    }

    const getSubTopic = async () => {
        return await axios.get(`${BASE_URL}${SubTopic.subTopic}`,
            { headers: { "Authorization": `Bearer ${token.token}` } }
        ).then(res => {
            setSubTopicList(res.data);

        }).catch(err => {
            console.log(err)
        })
    }
    const loadTopic = async () => {
        return await axios.get(`${BASE_URL}${Topic.topicList}/${id}`,
            { headers: { "Authorization": `Bearer ${token.token}` } }
        ).then(res => {
            setTopicList(res.data);


        }).catch(err => {
            console.log(err)
        })
    };

    const loadTopicUrl = async (id) => {
        return await axios.get(`${BASE_URL}${Topic.topic}/${id}`,
            { headers: { "Authorization": `Bearer ${token.token}` } }
        ).then(res => {
            setTopicUrl(res.data);
        }).catch(err => {
            console.log(err)
        })
    };


    useEffect(() => {
        loadIntro();
        loadTopic();
        getSubTopic();

    }, [])
    return (
        <>
            <div className="flex h-screen justify-center bg-background w-full">
                <aside className="w-14 fixed left-0 top-0  bg-slate-700">
                    <div className="min-h-screen bg-gray-100">
                        <div className="sidebar min-h-screen w-[3.35rem] overflow-hidden border-r hover:w-56 hover:bg-white hover:shadow-lg">
                            <div className="flex h-screen flex-col justify-between pt-2 pb-6">
                                <div>
                                    <div className="w-max p-2.5">
                                        <img className='mt-2 -mb-4' src={logo} alt="Cambridge-logo" width={150} height={150} />
                                    </div>
                                    <ul className="mt-6 space-y-2 tracking-wide">
                                        <li className="min-w-max">
                                            <NavLink to='/student/dashboard'>
                                                <div aria-label="dashboard" className="relative flex items-center space-x-4 bg-gradient-to-r from-sky-600 to-primary px-4 py-3 text-white">
                                                    <svg className="-ml-1 h-6 w-6" viewBox="0 0 24 24" fill="none">
                                                        <path d="M6 8a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2V8ZM6 15a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2v-1Z" className="fill-current text-primary dark:fill-slate-600"></path>
                                                        <path d="M13 8a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2V8Z" className="fill-current text-primary group-hover:text-primary"></path>
                                                        <path d="M13 15a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2v-1Z" className="fill-current group-hover:text-sky-300"></path>
                                                    </svg>
                                                    <span className="-mr-1 font-medium">Dashboard </span>
                                                </div>
                                            </NavLink>
                                        </li>
                                        <li className="min-w-max">
                                            <NavLink to='/student/onlineResource'>
                                                <div className="bg group flex items-center space-x-4 rounded-full px-4 py-3 text-gray-600">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 text-primary">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3" />
                                                    </svg>
                                                    <span className="group-hover:text-primary"> Go Back</span>
                                                </div>
                                            </NavLink>
                                        </li>
                                        <li className="min-w-max" onClick={() => getIntroductionVideo()}>
                                            <div className="bg group flex items-center space-x-4 rounded-full px-4 py-3 text-gray-600">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-primary">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                                                </svg>
                                                <span className="group-hover:text-primary cursor-pointer">Introduction</span>
                                            </div>
                                        </li>
                                        {Array.isArray(topicList) ? topicList.filter(topic => topic.chapterId === parseInt(chapterId)).map((item, index) => {
                                            return (
                                                <li key={index + 1} className="min-w-max" onClick={() => { getTopic(item); loadTopic(); setTopicUrl(item.url) }}>
                                                    <div className="bg group flex items-center space-x-4 rounded-full px-4 py-3 text-gray-600 cursor-pointer">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="text-primary w-6 h-6 ">
                                                            <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clipRule="evenodd" />
                                                        </svg>
                                                        <span className="group-hover:text-primary "> {item.topic}</span>
                                                    </div>
                                                </li>
                                            )
                                        }) : []}

                                    </ul>
                                </div>
                                <div className="w-max -mb-3 cursor-pointer">
                                    <div className="group flex items-center space-x-4 rounded-md px-4 py-3 text-gray-600">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                            <path className=" text-primary group-hover:text-primary" strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75" />
                                        </svg>
                                        <span className="group-hover:text-primary" onClick={onSubmitLogout}>Logout</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </aside>
                <main className="flex-1 ml-10">
                    <div className="p-10">
                        <ContentDiv classes={'justify-center'}>
                            <img className=' mb-8' src={logo} alt="CambridgeOnline" height={250} width={250} />
                        </ContentDiv>
                    </div>
                    <div className=" bg-background -mt-10 p-6">
                        <ContentDiv >
                            <section className="container mx-4 p-2 -mt-6">
                                <div className="flex flex-col ">
                                    {sub && <div className='flex flex-col -mt-8 md:px-12'>
                                    <iframe width="560" height="315" src={`https://www.youtube.com/embed/${introVideo.introVideoId}/?modestbranding=1`} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                 

                                    </div>}
                                    {hideLink &&
                                        <div className='flex flex-col -mt-8 md:px-12 '>
                                            <div className='grid grid-cols-2 gap-4'>
                                                <div className="col-span-1">
                                                    <div>
                                                    <h2 className='text-xl underline text-primary md: mt-4 '>{topic.chapter}</h2>
                                                        <h2 className='text-xl underline text-primary md: mt-4 '>{topic.topic}:</h2>
                                                        {Array.isArray(subTopicList) ? subTopicList.filter(x => x.topicId === parseInt(topic.id)).map((item, index) => {
                                                            return (
                                                                <li key={index + 1}>{item.subTopic}</li>
                                                            )
                                                        }) : []}

                                                    </div>
                                                </div>
                                                <div className="col-span-1">

                                                    <div className="aspect-w-6 aspect-h-3">
                                                        <iframe title='video' src={topicUrl.url} frameBorder="0" autoplay="false" allow="autoplay accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>}
                                </div>
                                <div className='flex gap-4 md:px-12 mt-4'>
                                    {hideLink && <Link to={`/student/onlineResource/attemptQuiz/topic/${courseId}/${chapterId}/${topic.id}/${topic.topic}/${topic.chapter}/${id}`} className="inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-primary rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-blue-300 bg-primary dark:hover:bg-red-700 dark:focus:ring-blue-800">
                                        Attemp Quiz
                                        <svg aria-hidden="true" className="ml-2 -mr-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                    </Link>}
                                    {hideLink &&
                                        <a href='https://embassyportal.schoolsmart.org.uk/Uploads/StudentDocuments/65983384_CC2020-Repetition-Questions-Chapter-1.docx' className="inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-primary rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-blue-300 bg-primary dark:hover:bg-red-700 dark:focus:ring-blue-800">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-2">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m.75 12l3 3m0 0l3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                                            </svg>
                                            Structured Question
                                        </a>}
                                </div>
                            </section>
                        </ContentDiv>
                    </div>
                </main>
            </div>
        </>
    )
}
export default OnlineResourceTopic