import { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom'
import Sidebar from '../../../components/Sidebar'
import ContentDiv from "../../../components/ContentDiv";
import React from 'react';
import { getTopic, createTopic, editTopic, deleteTopic } from "../../../api/topic";
import { BASE_URL } from "../../../utiles/constant";
import axios from "axios";
import TeacherSidebar from "../../../components/TeacherSidebar";
import jwtDecode from "jwt-decode";

const CreateTopic = () => {

    const { subjectId, subjectName, chapterId, chapterName, id, lesson } = useParams();
    const [table, setTable] = useState(true)
    const [topic, setTopic] = useState(false)

    const [delModal, setDelModal] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [topicList, setTopicList] = useState([])
    const [edit, setEdit] = useState({});
    const [delLesson, setDelTopic] = useState({});
    const [role, setRole] = useState();

    const user = localStorage.getItem('user')
    const isRole = jwtDecode(user);
    const token  = JSON.parse(user);



    // const [message, setMessage] =useState(false)

    const initalTopicState = {
        subTopic: '',
    }
    const [inputTopic, setInputTopic] = useState([initalTopicState])

    const initalState = {
        courseId: parseInt(subjectId),
        subject: subjectName,
        chapterId: parseInt(chapterId),
        chapter: chapterName,
        lessonId: parseInt(id),
        lesson: lesson,
        topic: '',
        subTopic: inputTopic,
        url: ''
    }
    //const [inputField, setInputField] = useState([initalState])
    const [inputField, setInputField] = useState(initalState)

    const addHandler = () => {

        setTable(false)
        setTopic(true)
    }
    const viewHandler = () => {

        setTable(true)
        setTopic(false)
    }
    const onChangeEdit = (e) => {
        setEdit({ ...edit, [e.target.name]: e.target.value });
    }

    const onInputTopicChange = (event, index) => {
        const values = [...inputTopic];
        if (event.target.name === "subTopic") {
            values[index].subTopic = event.target.value
        }
        setInputTopic(values)
    }

    const handleAddTopicClick = () => {
        const values = [...inputTopic];
        values.push({ subTopic: '' });

        setInputTopic(values);


    }
    const handleRemoveTopicClick = index => {
        const list = [...inputTopic];
        list.splice(index, 1);
        setInputTopic(list);
    }




    const onInputChange = (event, index) => {
        event.preventDefault();
        const values = { ...inputField }
        if (event.target.name === "lesson") {
            values.lesson = event.target.value
        }
        else if (event.target.name === "lessonId") {
            values.lessonId = event.target.value
        }
        else if (event.target.name === "courseId") {
            values.courseId = event.target.value
        }
        else if (event.target.name === "subject") {
            values.subject = event.target.value
        }
        else if (event.target.name === "chapterId") {
            values.chapterId = event.target.value
        }
        else if (event.target.name === "chapter") {
            values.chapter = event.target.value
        }
        else if (event.target.name === "url") {
            values.url = event.target.value
        }
        else {
            values.topic = event.target.value
        }
        setInputField(values)
    }

    // handle click event of the Remove button
    const handleRemoveClick = index => {
        const list = [...inputField];
        list.splice(index, 1);
        setInputField(list);
    };
    // handle click event of the Add button
    const handleAddClick = () => {
        const values = [...inputField];
        values.push({ chapter: '', chapterId: '', courseId: '', subject: '', lessonId: '', lesson: '', topic: '', subTopic: [], url: '' });
        setInputField(values);
    };

    const onSubmit = async () => {



        setInputField([initalState]);
        inputField.lessonId = parseInt(id);
        inputField.chapterId = parseInt(chapterId);
        inputField.courseId = parseInt(subjectId);
        inputField.subTopic = inputTopic;
        setInputTopic([initalTopicState]);


        console.log(inputField, 'first');
        console.log(inputTopic, 'subTopic')

        let data = {

        }
        await createTopic(inputField)
        loadData()
        setTopic(false)
        setTable(true)
    }

    useEffect(() => {
        setRole(isRole.role);
        loadData();
    }, [])

    const onSubmitEdit = async (edit) => {
        await editTopic(edit)
        loadData();
    }

    const onSubmitDel = async (del) => {
        await deleteTopic(del)
        loadData();
    }



    const loadData = async () => {
        return await axios.get(`${BASE_URL}${getTopic}`,
            { headers: { "Authorization": `Bearer ${token.token}` } }
        ).then(res => {
            setTopicList(res.data);
        }).catch(err => {
            console.log(err)
        })
    };
    return (
        <>
            <div className="flex h-screen justify-center bg-background w-full">
                <aside className="w-14 fixed left-0 top-0  bg-slate-700">
                    {role === 'admin' ? <Sidebar /> : role === 'teacher' ? <TeacherSidebar /> : ''}
                </aside>
                <main className="flex-1 ml-10">
                    <div className="p-10">
                        <h1 className=" text-center text-primary mb-4 text-2xl">The Cambridge Online Digital School</h1>

                        <ContentDiv >
                            <div className="flex flex-row">
                                <div className="flex flex-col">

                                    <div className="mt-4 grid grid-cols-1 px-4 ml-4 gap-8 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4  ">
                                        <div>
                                            <h3 className="text-medium px-4 md:text-lg   font-medium px-4"><span className="text-primary">Subject:</span>  {`${subjectName}`}</h3>
                                            <h3 className="text-mdeium  md: text-lg  font-medium px-4"><span className="text-primary">Chapter:</span> {`${chapterName}`}</h3>
                                            <h3 className="text-mdeium md: text-lg  font-medium px-4"><span className="text-primary">Chapter:</span> {`${lesson}`}</h3>

                                        </div>
                                        <div className="flex items-start rounded-xl bg-white p-4 shadow-lg cursor-pointer" onClick={() => addHandler()}>
                                            <div className="flex h-16 w-16 items-center justify-center rounded-full border border-primary bg-white-50">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-primary" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
                                                </svg>
                                            </div>
                                            <div className="ml-4">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="text-primary float-right w-6 h-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                                </svg>

                                                <p className="mt-2 text-sm text-gray-500">Add Topics</p>
                                            </div>
                                        </div>
                                        <div className="flex items-start rounded-xl bg-white p-4 shadow-lg cursor-pointer" onClick={() => viewHandler()}>
                                            <div className="flex h-16 w-16 items-center justify-center rounded-full border border-primary bg-white-50">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-primary" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
                                                </svg>
                                            </div>
                                            <div className="ml-4">
                                                <span className="flex justify-between">
                                                </span>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="text-primary float-right w-6 h-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607zM13.5 10.5h-6" />
                                                </svg>
                                                <p className="mt-2 text-sm text-gray-500">View  Topics</p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </ContentDiv>
                    </div>
                    <div className=" bg-background -mt-10 p-6">
                        <ContentDiv>
                            {/* Table */}
                            {table && <section className="flex flex-col  container ml-4  mx-4">
                                <div className="w-full mb-8 overflow-hidden rounded-lg shadow-lg ">
                                    <div className="w-full overflow-x-auto">
                                        <table className="w-full border-spacing">
                                            <thead>
                                                <tr className="text-md font-semibold tracking-wide text-left text-white bg-primary uppercase border-b border-gray-600">
                                                    <th className="px-4 py-3">Course</th>
                                                    <th className="px-4 py-3">Syllabus</th>
                                                    <th className="px-4 py-3">Lesson</th>
                                                    <th className="px-4 py-3">Topics</th>
                                                    <th className="px-4 py-3">Video</th>
                                                    <th className="px-4 py-3">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody className="bg-white">
                                                {Array.isArray(topicList) ? topicList.filter(lesson => lesson.lessonId === parseInt(id)).map((item, index) => {
                                                    return (
                                                        <tr className="text-gray-700" key={index + 1}>
                                                            <td className="px-4 py-3 text-ms font-semibold border">{subjectName}</td>
                                                            <td className="px-4 py-3 text-ms font-semibold border">{chapterName}</td>
                                                            <td className="px-4 py-3 text-ms font-semibold border">{lesson}</td>
                                                            <td className="px-4 py-3 text-ms font-semibold border">{item.topic}</td>
                                                            <td className="px-4 py-3 text-ms font-semibold border"><a href={item.url} >videos</a>  </td>

                                                            <td className="px-4 py-3 text-sm border">
                                                                <div className="flex flex-row gap-2">

                                                                    <Link to={`/subject/chapters/lesson/topic/subTopic/${item.topic}/${item.id}`}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-primary cursor-pointer">
                                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
                                                                        </svg>
                                                                    </Link>

                                                                    <div onClick={() => { setShowModal(true); setEdit(item) }}>

                                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 text-primary cursor-pointer">
                                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                                                        </svg>
                                                                    </div>
                                                                    <div onClick={() => { setDelModal(true); setDelTopic(item) }}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 text-primary cursor-pointer">
                                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                }

                                                ) : []}



                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </section>
                            }
                            {topic &&
                                <div className="bg-white rounded shadow-lg p-8 mb-6 ml-6 md:ml-14 w-5/6 lg:ml-12 w-5/6   ">

                                    <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3" >
                                        <div className="text-gray-600">
                                            <p className="text-primary text-lg">Add Topics </p>
                                            <p>Please fill out all the fields *</p>
                                        </div>
                                        <div className="lg:col-span-2">


                                            <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5 lg:grid-cols-2">

                                                <div className="md:col-span-2">
                                                    <label htmlFor="full_name">Subject Name</label>
                                                    <input type="text" readOnly name="subject" value={subjectName} onChange={(e) => onInputChange(e)} id="full_name" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" />
                                                </div>
                                                <div className="md:col-span-1" hidden={true}>
                                                    <label htmlFor="full_name">Subject Id</label>
                                                    <input type="text" name="courseId" value={inputField.courseId} onChange={(e) => onInputChange(e)} id="full_name" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" />
                                                </div>
                                                <div className="md:col-span-1" hidden={true}>
                                                    <label htmlFor="full_name">Chpater Id</label>
                                                    <input type="text" name="chapterId" value={inputField.chapterId} onChange={(e) => onInputChange(e)} id="full_name" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" />
                                                </div>
                                                <div className="md:col-span-1">
                                                    <label htmlFor="full_name">Chapter Name</label>
                                                    <input type="text" name="chapterName" readOnly value={chapterName} onChange={(e) => onInputChange(e)} id="full_name" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" />
                                                </div>
                                                <div className="md:col-span-1" hidden={true}>
                                                    <label htmlFor="code">LessonId</label>
                                                    <input type="text" name="lessonId" readOnly id="lessonId" value={inputField.lessonId} onChange={(e) => onInputChange(e)} className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" />
                                                </div>
                                                <div className="md:col-span-1">
                                                    <label htmlFor="code">Lesson</label>
                                                    <input type="text" name="lessonId" readOnly id="lessonId" value={lesson} onChange={(e) => onInputChange(e)} className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" />
                                                </div>
                                                <div className="md:col-span-2">
                                                    <label htmlFor="code">Topic</label>
                                                    <input type="text" name="topic" id="topic" value={inputField.topic} onChange={(e) => onInputChange(e)} className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" />
                                                </div>
                                                {inputTopic.map((x, index) => {
                                                    return (
                                                        <div>
                                                            <div className="md:col-span-2" key={index + 1}>
                                                                <label htmlFor="code">Sub-Topics</label>
                                                                <input type="text" name="subTopic" id="subTopic" value={x.subTopic} onChange={(e) => onInputTopicChange(e, index)} className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" />
                                                            </div>

                                                            <div className="md:col-span- mt-2 text-right">
                                                                <div className="text-left">
                                                                    {inputTopic.length !== 1 &&
                                                                        <button
                                                                            className="bg-primary hover:bg-red-700 text-white font-bold w-6 h-6  px-2 rounded"
                                                                            onClick={() => handleRemoveTopicClick(index)}

                                                                        >x</button>

                                                                    }
                                                                    {
                                                                        inputTopic.length - 1 === index &&
                                                                        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold w-6 h-6  px-2 rounded"
                                                                            onClick={handleAddTopicClick}
                                                                        >+</button>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}



                                                <div className="md:col-span-2">
                                                    <label htmlFor="code">Video Url</label>
                                                    <input type="text" name="url" id="url" value={inputField.url} onChange={(e) => onInputChange(e)} className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" />
                                                </div>
                                            </div>
                                            {/* <div className="md:col-span- mt-2 text-right">
                                                        <div className="text-left">
                                                            {inputField.length !== 1 &&
                                                                <button
                                                                    className="bg-primary hover:bg-red-700 text-white font-bold w-6 h-6  px-2 rounded"
                                                                    onClick={() => handleRemoveClick(index)}

                                                                >x</button>

                                                            }
                                                            {
                                                                inputField.length - 1 === index &&
                                                                <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold w-6 h-6  px-2 rounded"
                                                                    onClick={handleAddClick}
                                                                >+</button>


                                                            }
                                                        </div>
                                                    </div> */}
                                            {/* {message && <p className="text-primary text-xl px-4">{message}</p>} */}
                                        </div>
                                    </div>

                                    <div className="items-end text-right">
                                        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={onSubmit}>Submit</button>
                                    </div>
                                </div>
                            }
                            {showModal ? (
                                <>
                                    <div
                                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                                    >
                                        <div className="relative w-auto my-6 mx-auto max-w-3xl">
                                            {/*content*/}
                                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                                {/*header*/}
                                                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                                    <h3 className="text-2xl font-semibold">
                                                        Chapter: <span className='text-primary'>{ }</span>
                                                    </h3>
                                                    <button
                                                        className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                                        onClick={() => setShowModal(false)}

                                                    >
                                                        <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                                            ×
                                                        </span>
                                                    </button>
                                                </div>
                                                {/*body*/}
                                                <div className="relative p-6 flex-auto">
                                                    <div className="flex justify-center">
                                                        <div className="mb-3 xl:w-96">

                                                            <label htmlFor="course" className="form-label inline-block mb-2 text-gray-700">Chapter Name</label>

                                                            <input
                                                                type={"text"}
                                                                className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                                                disabled={true}
                                                                readOnly={true}
                                                                value={chapterName} />
                                                            <label htmlFor="course" className="form-label inline-block mb-2 text-gray-700">Lesson</label>

                                                            <input
                                                                type={"text"}
                                                                className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                                                disabled={true}
                                                                readOnly={true}
                                                                value={edit.lesson}
                                                            />
                                                            <label htmlFor="course" className="form-label inline-block mb-2 text-gray-700">Topic</label>

                                                            <input
                                                                type={"text"}
                                                                className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                                                value={edit.topic}
                                                                name="topic"
                                                                onChange={(e) => onChangeEdit(e)}

                                                            />

                                                            <label htmlFor="course" className="form-label inline-block mb-2 text-gray-700">Topic Url</label>

                                                            <input
                                                                type={"text"}
                                                                className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                                                value={edit.url}
                                                                name="url"
                                                                onChange={(e) => onChangeEdit(e)}

                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                {/*footer*/}
                                                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                                    <button
                                                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                                        type="button"
                                                        onClick={() => setShowModal(false)}
                                                    >
                                                        Close
                                                    </button>
                                                    <button
                                                        className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                                        type="button"
                                                        onClick={() => { setShowModal(false); onSubmitEdit(edit) }}
                                                    >
                                                        Save Changes
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                                </>
                            ) : null}

                            {delModal ? (
                                <>
                                    <div
                                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                                    >
                                        <div className="relative w-auto my-6 mx-auto max-w-3xl">
                                            {/*content*/}
                                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                                {/*header*/}
                                                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">

                                                    <label htmlFor="code" className="form-label inline-block mb-2 text-gray-700">Are you want to sure delete this Topic: {delLesson.topic} ?</label>
                                                </div>
                                                {/*body*/}

                                                <div className="relative p-6 flex-auto">
                                                    <div className="flex justify-center">
                                                        <div className="mb-3 xl:w-96">

                                                            <div className="flex grid-col-2 justify-center gap-4">
                                                                <button
                                                                    className="bg-red-500 text-white active:bg-red-600 text-white-500 background-transparent rounded font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                                                    type="button"
                                                                    onClick={() => { setDelModal(false); onSubmitDel(delLesson) }}

                                                                >
                                                                    Yes
                                                                </button>
                                                                <button
                                                                    className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                                                    type="button"
                                                                    onClick={() => { setDelModal(false) }}
                                                                >
                                                                    No
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/*footer*/}
                                                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                                </>
                            ) : null}
                        </ContentDiv>
                    </div>
                </main>
            </div>
        </>
    )
}
export default CreateTopic


