
import FeatureCard from '../../components/FeatureCard'
import Sidebar from '../../components/Sidebar'
import ContentDiv from "../../components/ContentDiv";
import React from 'react';
const Dashboard = () => {
    return (
        <>
            <div className="flex h-screen justify-center bg-background w-full">
                <aside className="w-14 fixed left-0 top-0  bg-slate-700">
                    <Sidebar />
                </aside>
                <main className="flex-1 ml-10">
                    <div className="p-2 h-min-screen lg:h-36 ">
                        <h1 className="text-center text-primary  text-2xl">The Cambridge Online Digital School</h1>
                        <ContentDiv >
                            <div className="flex flex-col gap-12">
                                <FeatureCard />
                            </div>
                        </ContentDiv>
                    </div>
                    <div className=" h-min-screen w-min-screen md:h-[52rem] lg:h-[44rem] p-6">
                        <ContentDiv >
                            <div className="flex flex-col gap-12">

                            </div>
                            <section className="flex flex-col container mx-4 p-2">
                                <h1 className="text-center text-xl text-primary mb-4  md:text-left  text-xl lg:text-left  text-2xl xl:text-left   text-2xl">Recent Students</h1>

                                <div className="w-full mb-8 overflow-hidden rounded-lg shadow-lg ">
                                    <div className="w-full overflow-x-auto">
                                        <table className="w-full border-spacing">
                                            <thead>
                                                <tr className="text-md font-semibold tracking-wide text-left text-white bg-primary uppercase border-b border-gray-600">
                                                    <th className="px-4 py-3">Name</th>
                                                    <th className="px-4 py-3">Age</th>
                                                    <th className="px-4 py-3">Status</th>
                                                    <th className="px-4 py-3">Date</th>
                                                </tr>
                                            </thead>
                                            <tbody className="bg-white">
                                                <tr className="text-gray-700">
                                                    <td className="px-4 py-3 border">
                                                        <div className="flex items-center text-sm">
                                                            <div className="relative w-8 h-8 mr-3 rounded-full md:block">
                                                                {/* <Image className="object-cover w-full h-full rounded-full" src="https://images.pexels.com/photos/5212324/pexels-photo-5212324.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260" width={40} height={40} alt="" loading="lazy" /> */}
                                                                <div className="absolute inset-0 rounded-full shadow-inner" aria-hidden="true"></div>
                                                            </div>
                                                            <div>
                                                                <p className="font-semibold text-black">Sufyan</p>
                                                                <p className="text-xs text-gray-600">Developer</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="px-4 py-3 text-ms font-semibold border">22</td>
                                                    <td className="px-4 py-3 text-xs border">
                                                        <span className="px-2 py-1 font-semibold leading-tight text-green-700 bg-green-100 rounded-sm"> Acceptable </span>
                                                    </td>
                                                    <td className="px-4 py-3 text-sm border">6/4/2000</td>
                                                </tr>
                                                <tr className="text-gray-700">
                                                    <td className="px-4 py-3 border">
                                                        <div className="flex items-center text-sm">
                                                            <div className="relative w-8 h-8 mr-3 rounded-full">
                                                                {/* <Image className="object-cover w-full h-full rounded-full" src="https://images.pexels.com/photos/5212324/pexels-photo-5212324.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260" alt="" loading="lazy" /> */}
                                                                <div className="absolute inset-0 rounded-full shadow-inner" aria-hidden="true"></div>
                                                            </div>
                                                            <div>
                                                                <p className="font-semibold text-black">Stevens</p>
                                                                <p className="text-xs text-gray-600">Programmer</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="px-4 py-3 text-md font-semibold border">27</td>
                                                    <td className="px-4 py-3 text-xs border">
                                                        <span className="px-2 py-1 font-semibold leading-tight text-orange-700 bg-gray-100 rounded-sm"> Pending </span>
                                                    </td>
                                                    <td className="px-4 py-3 text-sm border">6/10/2020</td>
                                                </tr>
                                                <tr className="text-gray-700">
                                                    <td className="px-4 py-3 border">
                                                        <div className="flex items-center text-sm">
                                                            <div className="relative w-8 h-8 mr-3 rounded-full">
                                                                {/* <Image className="object-cover w-full h-full rounded-full" src="https://images.pexels.com/photos/5212324/pexels-photo-5212324.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260" alt="" loading="lazy" /> */}
                                                                <div className="absolute inset-0 rounded-full shadow-inner" aria-hidden="true"></div>
                                                            </div>
                                                            <div>
                                                                <p className="font-semibold">Nora</p>
                                                                <p className="text-xs text-gray-600">Designer</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="px-4 py-3 text-md font-semibold border">17</td>
                                                    <td className="px-4 py-3 text-xs border">
                                                        <span className="px-2 py-1 font-semibold leading-tight text-red-700 bg-red-100 rounded-sm"> Nnacceptable </span>
                                                    </td>
                                                    <td className="px-4 py-3 text-sm border">6/10/2020</td>
                                                </tr>
                                          
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </section>
                        </ContentDiv>
                    </div>

                    {/* 
                    <div className="h-96 bg-green-400 p-10">
                        <h1 className="text-4xl">Middle Content</h1>
                    </div>
                    <div className="h-96 bg-indigo-400 p-10">
                        <h1 className="text-4xl">Last Content</h1>
                    </div> */}
                </main>
            </div>
        </>
    )
}
export default Dashboard