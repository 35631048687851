import axios from "axios";
import {BASE_URL} from "../utiles/constant";
export  const Subject ={
  subject : "/api/Courses", 
}

const user =  localStorage.getItem('user')
const token  = JSON.parse(user);

export const getSubject = Subject.subject;

export  const editSubject = (data) => {
    return axios.put(`${BASE_URL}${Subject.subject}/${data.id}`,data,
    { headers: {"Authorization" : `Bearer ${token.token}`} }
    
    ).then(res=>{
      //  console.log(res,'TEST')
    }).catch(err=>{
     //   console.log(err)
    })
  };


  export  const deleteSubject = (del) => {
    return axios.delete(`${BASE_URL}${Subject.subject}/${del.id}`,
    { headers: {"Authorization" : `Bearer ${token.token}`} }
    
    ).then(res=>{
     //   console.log(res,'TEST')
    }).catch(err=>{
      //  console.log(err)
    })
  };

export  const createSubject = (data) => {
    return axios.post(`${BASE_URL}${Subject.subject}`,data,
    { headers: {"Authorization" : `Bearer ${token.token}`} }
    
    ).then(res=>{
     //   console.log(res,'TEST')
    }).catch(err=>{
     //   console.log(err)
    })
  };


