
//import FeatureCard from '../../components/FeatureCard'
import ContentDiv from "../../../components/ContentDiv";
import React from 'react';
const StudentCourse = () => {
    return (
        <>
            <div className="flex  justify-center bg-background w-full">
              
                <main className="flex-1 ml-10">
                    <div className="p-2 h-min-screen lg:h-36 ">
                  
                        <ContentDiv >
                            <div className="flex flex-col gap-12 p-6">
                                {/* <FeatureCard /> */}
                                For questions, please contact us at the support  helpline: xxx-xxxxxx between 10 AM - 6 PM.
Please note we are closed on Fridays.

<hr/>

                            </div>
                        </ContentDiv>
                    </div>
                    <div className=" h-min-screen w-min-screen md:h-[52rem] lg:h-[44rem] p-4">
                        <ContentDiv >
                            <div className="flex flex-col gap-12">

                            </div>
                            <section className="flex flex-col container mx-4 ">
                                <h1 className="text-center text-xl text-primary mb-4  md:text-left  text-xl lg:text-left  text-2xl xl:text-left   text-3xl">My Courses</h1>
         <div className="grid grid-cols-1 gap-4 md:grid-cols-3 gap-6">
                                 
<div className="p-6 max-w-sm bg-white rounded-lg border border-gray-200 shadow-md bg-white dark:border-shadow-lg">
<span className="px-2 float-right py-1 font-semibold leading-tight text-red-700 bg-red-100 rounded-sm"> Status </span>
   
    <a href="/#">
        <h5 className="mb-2 text-xl font-medium tracking-tight  text-primary">English - 0456</h5>
    </a>
    <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">Here are the biggest enterprise technology acquisitions of 2021 so far, in reverse chronological order.</p>
    <a href="/#" className="inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-primary rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-blue-300 bg-primary dark:hover:bg-red-700 dark:focus:ring-blue-800">
        Open Portal
        <svg aria-hidden="true" className="ml-2 -mr-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
    </a>
</div>

<div className=" p-6 max-w-sm bg-white rounded-lg border border-gray-200 shadow-md bg-white dark:border-shadow-lg">
<span className="px-2 float-right py-1 font-semibold leading-tight text-green-700 bg-green-100 rounded-sm"> Accept </span>
   
    <a href="/#">
        <h5 className="mb-2 text-xl font-medium tracking-tight  text-primary">Business Studies - 0456</h5>
    </a>
   
    <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">Here are the biggest enterprise technology acquisitions of 2021 so far, in reverse chronological order.</p>
    <a href="/#" className="inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-primary rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-blue-300 bg-primary dark:hover:bg-red-700 dark:focus:ring-blue-800">
        Open Portal
        <svg aria-hidden="true" className="ml-2 -mr-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
    </a>
</div>      
<div className="p-6 max-w-sm bg-white rounded-lg border border-gray-200 shadow-md bg-white dark:border-shadow-lg">
    <a href="/#">
        <h5 className="mb-2 text-xl font-medium tracking-tight  text-primary">Business Studies - 0456</h5>
    </a>
    <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">Here are the biggest enterprise technology acquisitions of 2021 so far, in reverse chronological order.</p>
    <a href="/#" className="inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-primary rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-blue-300 bg-primary dark:hover:bg-red-700 dark:focus:ring-blue-800">
        Open Portal
        <svg aria-hidden="true" className="ml-2 -mr-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
    </a>
</div>
<div className="p-6 max-w-sm bg-white rounded-lg border border-gray-200 shadow-md bg-white dark:border-shadow-lg">
    <a href="/#">
        <h5 className="mb-2 text-xl font-medium tracking-tight  text-primary">English - 0456</h5>
    </a>
    <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">Here are the biggest enterprise technology acquisitions of 2021 so far, in reverse chronological order.</p>
    <a href="/#" className="inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-primary rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-blue-300 bg-primary dark:hover:bg-red-700 dark:focus:ring-blue-800">
        Open Portal
        <svg aria-hidden="true" className="ml-2 -mr-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
    </a>
</div>
</div>                        
                            </section>
                        </ContentDiv>
                    </div>

                    {/* 
                    <div className="h-96 bg-green-400 p-10">
                        <h1 className="text-4xl">Middle Content</h1>
                    </div>
                    <div className="h-96 bg-indigo-400 p-10">
                        <h1 className="text-4xl">Last Content</h1>
                    </div> */}
                </main>
            </div>
        </>
    )
}
export default StudentCourse