
import ContentDiv from "../../../components/ContentDiv";
import StudentSidebar from "../../../components/StudentSidebar";
import logo from '../../../assests/images/logos/Cambridge-logo.webp';
import React, { useEffect, useState } from 'react';
import jwtDecode from "jwt-decode";
import { Book } from "../../../api/textBook";
import axios from "axios";
import { BASE_URL, fileTextBookUrl } from "../../../utiles/constant";

import './index.css'
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const TextBook = () => {
    const [textBookData, setTextBookData] = useState([]);
    const [book, setBook] = useState();
    const [showDocument, setShowDocument] = useState(false);
    const [table, setTable] = useState(true);
    const [bookName, setBookName] = useState(false);

    const user = localStorage.getItem('user')
    const decodeToken = jwtDecode(user);
    const id = decodeToken.primarysid;
    const token  = JSON.parse(user);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1); //setting 1 to show fisrt page



    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setPageNumber(1);
    }

    function previousPage() {
        if (pageNumber > 1) {
            setPageNumber(pageNumber - 1)
        };
    }

    function nextPage() {
        if (pageNumber < numPages) {
            setPageNumber(pageNumber + 1)
        };
    }


    useEffect(() => {
        const loadTextBook = async () => {
            return await axios.get(`${BASE_URL}${Book.studentTextBook}/${id}`,
                { headers: { "Authorization": `Bearer ${token.token}` } }
            ).then(res => {
                setTextBookData(res.data);
            }).catch(err => {
                console.log(err)
            })
        }


         loadTextBook()

    }, [])

    return (
        <>
            <div className="flex h-screen justify-center bg-background w-full">
                <aside className="w-14 fixed left-0 top-0  bg-slate-700">
                    <StudentSidebar />
                </aside>
                <main className="flex-1 ml-10 bg-background">
                    <div className="p-10 ">
                        <ContentDiv classes={'justify-center -mt-4'}>
                            <img className='' src={logo} alt="CambridgeOnline" height={250} width={250} />
                        </ContentDiv>
                    </div>
                    <div className="w-full bg-background -mt-10 p-6">
                        <ContentDiv>

                            {/* Table */}
                            <section className="flex flex-col  container ml-4  mx-4">
                                <h1 className="text-center text-xl text-primary mb-4  md:text-left  text-xl lg:text-left  text-2xl xl:text-left   text-2xl">Text Books</h1>
                                {
                                    table &&

                                    <div className="w-full mb-8 overflow-hidden rounded-lg shadow-lg ">
                                        <div className="w-full overflow-x-auto">
                                            <table className="w-full border-spacing">
                                                <thead>
                                                    <tr className="text-md font-semibold tracking-wide text-left text-white bg-primary uppercase border-b border-gray-600">
                                                        <th className="px-4 py-3">Subjects</th>
                                                        <th className="px-4 py-3">Books</th>
                                                        <th className="px-4 py-3">View</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="bg-white">
                                                    {Array.isArray(textBookData) ? textBookData.map((item,index) => {
                                                        return (
                                                            <tr className="text-gray-700" key={item.id}>
                                                                <td className="px-4 py-3 text-ms font-semibold border">{item.course}</td>
                                                                <td className="px-4 py-3 text-sm border">{item.bookName}</td>
                                                                <td className="px-4 py-3 text-sm border">
                                                                    <div className="flex flex-row gap-2" >
                                                                        {/* <a href={fileTextBookUrl + item.fileName} target="_blank" rel="noreferrer">  */}
                                                                        <button type="button" onClick={() => { setBookName(item.bookName); setBook(fileTextBookUrl + item.fileName); setShowDocument(true); setTable(false) }} >
                                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-primary">
                                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                                            </svg>
                                                                        </button>
                                                                        {/* </a> */}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }).reverse() : []}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                }
                                {showDocument &&
                                    <>
                                        <h1 className="text-primary text-xl mb-4 text-center">{bookName}</h1>
                                        <div className=" h-screen overflow-x-scroll overflow-x-hidden scrolling-touch" >
                                            <Document file={book} onLoadSuccess={onDocumentLoadSuccess}
                                                onContextMenu={(e) => e.preventDefault()} className="pdf-container">
                                                <Page pageNumber={pageNumber} scale={1.5} />
                                            </Document>
                                        </div>
                                        <div className="controls" onContextMenu={(e) => e.preventDefault()}>
                                            <button onClick={previousPage} disabled={pageNumber === 1}>
                                                Prev
                                            </button>
                                            <button onClick={nextPage} disabled={pageNumber === numPages}>
                                                Next
                                            </button>
                                            <button onClick={() => { setShowDocument(false); setTable(true) }}>
                                                Close
                                            </button>
                                        </div>
                                    </>
                                }
                            </section>
                        </ContentDiv>
                    </div>
                </main>
            </div>
        </>
    )
}
export default TextBook


