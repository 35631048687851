import axios from "axios";
import {BASE_URL} from "../utiles/constant";
export  const Topic ={
  topic : "/api/topic", 
  topicList:"/api/topicList",
  topicQuestion:"/api/CreateQuestion"
}

const user =  localStorage.getItem('user')
const token  = JSON.parse(user);

export const getTopic = Topic.topic;
export const getTopicQuestion = Topic.topicQuestion;

export  const editTopic = (data) => {
    return axios.put(`${BASE_URL}${Topic.topic}/${data.id}`,data,
    { headers: {"Authorization" : `Bearer ${token.token}`} }
    
    ).then(res=>{
      //  console.log(res,'TEST')
    }).catch(err=>{
     //   console.log(err)
    })
  };


  export  const deleteTopic = (del) => {
    return axios.delete(`${BASE_URL}${Topic.topic}/${del.id}`,
    { headers: {"Authorization" : `Bearer ${token.token}`} }
    
    ).then(res=>{
     //   console.log(res,'TEST')
    }).catch(err=>{
      //  console.log(err)
    })
  };

export  const createTopic = (data) => {
    return axios.post(`${BASE_URL}${Topic.topic}`,data,
    { headers: {"Authorization" : `Bearer ${token.token}`} }
    
    ).then(res=>{
        console.log(res,'TEST')
    }).catch(err=>{
     //   console.log(err)
    })
  };


