
import logo from '../../assests/images/logos/Cambridge-logo.webp';
import ContentDiv from "../../components/ContentDiv";
import StudentSidebar from '../../components/StudentSidebar';
import React, { useEffect, useState } from 'react';
import { BASE_URL, token } from '../../utiles/constant';
import { dashboard } from '../../api/studnetMarks';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
const Dashboard = () => {

    // const data = [
    //     {
    //         id: 1,
    //         subject: "English",
    //         chapter: [
    //             {
    //                 id: 1,
    //                 chapter: "Chapter 1",
    //                 topic: "Topic 1",
    //                 date: "01/Nov/22",
    //                 mark: 10
    //             },
    //             {
    //                 id: 2,
    //                 chapter: "Chapter 3",
    //                 topic: "Topic 2",
    //                 date: "01/Nov/22",
    //                 mark: 11
    //             },
    //             {
    //                 id: 3,
    //                 chapter: "Chapter 3",
    //                 topic: "Topic 3",
    //                 mark: 10,
    //                 date: "01/Nov/22"
    //             }

    //         ]

    //     },
    //     {
    //         id: 1,
    //         subject: "Math",
    //         chapter: [
    //             {
    //                 id: 1,
    //                 chapter: "Chapter 1",
    //                 topic: "Topic Math 1",
    //                 mark: 10,
    //                 date: "02/Nov/22"
    //             },
    //             {
    //                 id: 2,
    //                 chapter: "Chapter 3",
    //                 topic: "Topic Math 2",
    //                 mark: 15,
    //                 date: "02/Nov/22",
    //             },
    //             {
    //                 id: 3,
    //                 chapter: "Chapter 3",
    //                 topic: "Topic Math 3",
    //                 mark: 12,
    //                 date: "02/Nov/22"
    //             }

    //         ]
    //     }

    // ]

    const [studentData, setStudentData] = useState([]);
    const user = localStorage.getItem('user')
    const decodeToken = jwtDecode(user);
    const studentId = decodeToken.primarysid;

    useEffect(() => {
        loadData();
    }, [studentId])

    const loadData = async () => {
        return await axios.get(`${BASE_URL}${dashboard}/${studentId}`,
            { headers: { "Authorization": `Bearer ${token.token}` } }
        ).then(res => {
            setStudentData(res.data.StudentMarksLists);
        }).catch(err => {
            console.log(err)
        })
    };
    return (
        <>
            <div className="flex h-screen justify-center bg-background w-full">
                <aside className="w-14 fixed left-0 top-0  bg-slate-700">
                    <StudentSidebar />
                </aside>
                <main className="flex-1 ml-10">
                    <div className="p-10">
                        <ContentDiv classes={'justify-center'}>
                            <img className='' src={logo} alt="CambridgeOnline" height={250} width={250} />
                        </ContentDiv>
                    </div>
                    <div className=" bg-background -mt-10 p-6">
                        <ContentDiv>
                            <section className="flex flex-col container mx-4 p-2 -mt-8">
                                {Array.isArray(studentData) ? studentData.map((item, i) => {
                                    return (
                                        <div key={i + 1}>
                                            <h1 className="text-center font-medium text-xl text-primary mb-4  md:text-left  text-xl lg:text-left  text-2xl xl:text-left   text-2xl">Subject: {item.course}</h1>

                                            {Array.isArray(item.CourseDetailMarks) ? item.CourseDetailMarks.map((student, index) => {
                                                return (
                                                    <div key={index + 1} className="w-full mb-8 overflow-hidden rounded-lg shadow-lg ">
                                                        <div className="w-full overflow-x-auto">
                                                            <table className="w-full border-spacing ">
                                                                <thead>
                                                                    <tr className="text-md font-semibold tracking-wide text-left text-white bg-[#7378a2] uppercase border-b border-gray-600">
                                                                        <th className="px-4 py-3">Chapter</th>
                                                                        <th className="px-4 py-3">Topic</th>

                                                                        <th className="px-2 py-3"> Obtained / Total - %</th>
                                                                        <th className="px-2 py-3">Date</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className="bg-white">
                                                                    <tr className="text-gray-700" key={index + 1}>
                                                                        <td className="px-2 py-3 border">
                                                                            {student.chapterName}
                                                                        </td>
                                                                        <td className="px-2 py-3 text-ms font-semibold border">{student.topicName}</td>
                                                                        <td className="px-2 py-3 text-sm border">{student.marks +"  /  " + 10 + "  =  " + student.marks/10 *100 + "%" }</td>
                                                                        <td className="px-2 py-3 text-sm border">{student.createdAt}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                )
                                            }) : []}
                                        </div>
                                    )
                                }) : []}
                            </section>
                        </ContentDiv>
                    </div>
                </main>
            </div>
        </>
    )
}
export default Dashboard