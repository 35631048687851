import axios from "axios";
import {BASE_URL} from "../utiles/constant";
export  const Chapter ={
  chapter : "/api/Chapter", 
  courseChapter:"/api/ChapterList",
  chapterTopic:"/api/ChapterTopic"

}

const user =  localStorage.getItem('user')
const token  = JSON.parse(user);

export const getChapter = Chapter.chapter;
export const getChapterTopic = Chapter.chapterTopic;


export  const editChapter = (data) => {
    return axios.put(`${BASE_URL}${Chapter.chapter}/${data.id}`,data,
    { headers: {"Authorization" : `Bearer ${token.token}`} }
    
    ).then(res=>{
       // console.log(res,'TEST')
    }).catch(err=>{
      //  console.log(err)
    })
  };


  export  const deleteChapter = (del) => {
    return axios.delete(`${BASE_URL}${Chapter.chapter}/${del.id}`,
    { headers: {"Authorization" : `Bearer ${token.token}`} }
    
    ).then(res=>{
      //  console.log(res,'TEST')
    }).catch(err=>{
      //  console.log(err)
    })
  };

export  const createChapter = (data) => {
    return axios.post(`${BASE_URL}${Chapter.chapter}`,data,
    { headers: {"Authorization" : `Bearer ${token.token}`} }
    
    ).then(res=>{
    //    console.log(res,'TEST')
    }).catch(err=>{
      //  console.log(err)
    })
  };


