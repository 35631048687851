import React from "react";
import { AppRoute } from "./routes";
import { Route, Routes } from 'react-router-dom';


import OnlineRegistration from "../../pages/onlineRegistration";
/*Owner Routes */
import Dashboard from "../../pages/owner/dashboard";
import Subject from "../../pages/owner/subjects/index"
import Student from "../../pages/owner/students/index"
import Subscriptions from "../../pages/owner/students/subscriptions";

import AddStructureQuestion from "../../pages/owner/assessment/addStructuredQuestions";

import TeacherStructuredQuestions from "../../pages/teacher/structuredQuestion";
import Assessment from "../../pages/owner/assessment";
import Teacher from "../../pages/owner/teachers";

import TextBook from "../../pages/owner/textBooks";
import TeacherTextBook from "../../pages/teacher/textBooks";
import Annoucement from "../../pages/owner/annoucements";

/*Student Routers */
import StudentLogin from "../../pages/login/student";
import StudentDashboard from "../../pages/students/dashboard";
import StudentTextBook from "../../pages/students/textBook/";
import StudentOnlineResources from "../../pages/students/onlineResource/";
import StudentAnnoucement from "../../pages/students/annoucement/";
import StudentReports from "../../pages/students/report/";
import StudentCourse from "../../pages/students/course/";
import StudentAssessment from "../../pages/students/onlineResource/studentAssessments"
import OnlineResourceChapter from "../../pages/students/onlineResource/chapters";
import OnlineResourceTopic from "../../pages/students/onlineResource/topics";
import AttemptQuiz from "../../pages/students/onlineResource/attempQuiz";
import StudentReportDetails from "../../pages/students/report/reportDetails";
import StudentAskQuestion from "../../pages/students/askQuestions";
import AnswerKey from "../../pages/students/report/answerKey";
import CreateChapter from "../../pages/owner/subjects/chapters";
import CreateLesson from "../../pages/owner/subjects/lessons";
import CreateTopic from "../../pages/owner/subjects/topics";
import TeacherDashboard from "../../pages/teacher/dashboard";
import TeacherLogin from "../../pages/login/teacher";
import TeacherResponse from "../../pages/students/teacherResponse";
import TeacherAnsweringQuestion from "../../pages/teacher/answers";
import TeacherTopic from "../../pages/teacher/topics";
import TeacherAddQuestion from "../../pages/teacher/questions";
import OwnerAddTopicQuestion from "../../pages/owner/assessment/addQuestion";
import OwnerSubmitQuestion from "../../pages/owner/assessment/addTopicQuestions";
import FurtherAssessment from "../../pages/students/furtherAssessment";
import ProtectedRoute from "./ProtectedRoute";
import Page404 from "../../pages/login/page404.js";
import ViewRegistration from "../../pages/onlineRegistration/viewRegistration";
import HeadOwner from "../../pages/login/headOwner";
import HeadOwnerDashboard from "../../pages/headonwer";
import StudentHO from "../../pages/headonwer/viewStudentHO";
import TeacherHO from "../../pages/headonwer/viewTeacherHO";
import SubjectHO from "../../pages/headonwer/viewSubjectHO";
import AnnoucementHO from "../../pages/headonwer/announcementHO";
import  FinanceHO  from "../../pages/headonwer/financeHO";
import ForgetPasswordStudent from "../../pages/forgetPassword/forgetPasswordStudent";
import ForgetPasswordTeacher from "../../pages/forgetPassword/forgetPasswordTeacher";
import Admin from "../../pages/login/owner";
import SubTopic from "../../pages/owner/subjects/subTopic";
import CreateSubTopic from "../../pages/owner/subjects/subTopic";
const routing = [
{
    appRoute: AppRoute.TeacherTextBooks,
    component: TeacherTextBook    
}
    ,{
        appRoute: AppRoute.HeadOwnerDashboard,
        component: HeadOwnerDashboard
    },
    {
        appRoute:AppRoute.HeadOwner,
        component:HeadOwner
    }

    ,{
        appRoute: AppRoute.ViewOnlineRegistration,
        component: ViewRegistration
    },
    {
        appRoute: AppRoute.StudentLogin,
        component: StudentLogin
    },
    {
        appRoute: AppRoute.Dashboard,
        component: Dashboard
    },
    {
        appRoute: AppRoute.Subjects,
        component: Subject
    },
    {
        appRoute: AppRoute.Students,
        component: Student
    },
    {
        appRoute: AppRoute.CreateChapter,
        component: CreateChapter
    },
    {
        appRoute: AppRoute.CreateLesson,
        component: CreateLesson
    }
    ,
    {
        appRoute: AppRoute.CreateTopic,
        component: CreateTopic
    },
    {
        appRoute: AppRoute.CreateSubTopic,
        component: CreateSubTopic
    },
    {
        appRoute: AppRoute.Teachers,
        component: Teacher
    },
    {
        appRoute: AppRoute.Subscription,
        component: Subscriptions
    },
    {
        appRoute: AppRoute.Assessments,
        component: Assessment
    }
    ,

    {
        appRoute: AppRoute.AddStructuredQuestion,
        component: AddStructureQuestion
    },
    {
        appRoute: AppRoute.TeacherStructuredQuestions,
        component: TeacherStructuredQuestions
    },
    {
        appRoute: AppRoute.TextBooks,
        component: TextBook
    },
    {
        appRoute: AppRoute.Annoucement,
        component: Annoucement
    },
    {
        appRoute: AppRoute.OwnerAddTopicQuestion,
        component: OwnerAddTopicQuestion
    },
    {
        appRoute: AppRoute.OwenerSubmitQuestion,
        component: OwnerSubmitQuestion
    },
    //Studnet
    {
        appRoute: AppRoute.StudentDashboard,
        component: StudentDashboard
    },
    {
        appRoute: AppRoute.StudentTextBooks,
        component: StudentTextBook
    },
    {
        appRoute: AppRoute.StudentAnnoucement,
        component: StudentAnnoucement
    },
    {
        appRoute: AppRoute.StudentCourse,
        component: StudentCourse
    },
    {
        appRoute: AppRoute.StudentReports,
        component: StudentReports
    },

    {
        appRoute: AppRoute.StudentReportDetails,
        component: StudentReportDetails
    },
    {
        appRoute: AppRoute.StudentAnswerKeys,
        component: AnswerKey
    }

    , {
        appRoute: AppRoute.StudentOnlineResource,
        component: StudentOnlineResources
    },

    {
        appRoute: AppRoute.StudentOnlineResourceChapter,
        component: OnlineResourceChapter
    },

    {
        appRoute: AppRoute.StudentOnlineResourceTopic,
        component: OnlineResourceTopic
    },
    {
        appRoute: AppRoute.StudentAttemptQuiz,
        component: AttemptQuiz
    },
    {
        appRoute: AppRoute.StudentAssessment,
        component: StudentAssessment
    },
    {
        appRoute: AppRoute.StudentAskQuestion,
        component: StudentAskQuestion
    },
    {
        appRoute: AppRoute.TeacherAnswer,
        component: TeacherResponse
    },
    {
        appRoute: AppRoute.StudentFurtherAssessment,
        component: FurtherAssessment
    },
    //Teacher
    {
        appRoute: AppRoute.TeacherDashboard,
        component: TeacherDashboard
    }
    , {
        appRoute: AppRoute.Teacher,
        component: TeacherLogin
    },
    {
        appRoute: AppRoute.AnsweringQuestion,
        component: TeacherAnsweringQuestion
    }, {
        appRoute: AppRoute.TeacherTopic,
        component: TeacherTopic
    }
    , {
        appRoute: AppRoute.TeacherAddQuestion,
        component: TeacherAddQuestion
    },
    {
        appRoute: AppRoute.Page404,
        component: Page404
    }

]

const AppRouter = () => {
    return (
        <Routes>
             <Route path={"/headowner"} element={<HeadOwner />} ></Route>
             {/* To be protected */}
            <Route path={"/headowner/dashboard"} element={<HeadOwnerDashboard />} ></Route>
            <Route path={"/headowner/dashboard/students"} element={<StudentHO />} ></Route>
            <Route path={"/headowner/dashboard/teachers"} element={<TeacherHO />} ></Route>
            <Route path={"/headowner/dashboard/subjects"} element={<SubjectHO />} ></Route>
            <Route path={"/headowner/dashboard/announcements"} element={<AnnoucementHO />} ></Route>
            <Route path={"/headowner/dashboard/finance"} element={<FinanceHO />} ></Route>
 {/* To be protected */}

 <Route path={"/forgetPassword/student"} element={<ForgetPasswordStudent />} ></Route>
            <Route path={"/admin"} element={<Admin />} ></Route>
            <Route path={"/"} element={<StudentLogin />} ></Route>
            <Route path={"/teacher"} element={<TeacherLogin />} ></Route>
            <Route path="*" element={<Page404 />} ></Route>
            <Route path={"/registration"} element={<OnlineRegistration />} ></Route>
            <Route element={<ProtectedRoute />}>
                {routing.map((i, index) => {
                    return (
                        <Route path={i.appRoute} key={index + 1} element={<i.component />} ></Route>
                    )
                })}
            </Route>
        </Routes>
    )
}
export default AppRouter;