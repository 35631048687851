import { useEffect, useState } from "react";
import Sidebar from '../../../components/Sidebar'
import TeacherSidebar from '../../../components/TeacherSidebar'
import ContentDiv from "../../../components/ContentDiv";
import React from 'react';
import { Book, deleteTextBook } from "../../../api/textBook";
import BackButton from "../../../components/BackButton";
import axios from "axios";
import { BASE_URL, fileTextBookUrl } from "../../../utiles/constant";
import LoadingSpinner from "../../../components/LoaderComponent";
import jwtDecode from "jwt-decode";

const TeacherTextBook = () => {
    const [table, setTable] = useState(true)
    const [subject, setSubject] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [delModal, setDelModal] = useState(false);
    const [delTextBook, setDelTextBook] = useState({});
    const imageInputRef = React.useRef()
    const [selectedFile, setSelectedFile] = useState(null);
    const [book, setBook] = useState("")
    const [subjectData, setSubjectData] = useState({});
    const [textBook, setTextBook] = useState([]);

    const user = localStorage.getItem('user')
    const decodeToken = jwtDecode(user);
    const id = decodeToken.primarysid;
    const token  = JSON.parse(user);

    useEffect(() => {
        getTextBook();
        const getSubject = async () => {
            return await axios.get(`${BASE_URL}${Book.teacherSubject}/${id}`,
                { headers: { "Authorization": `Bearer ${token.token}` } }
            ).then(res => {
                setSubjectData(res.data);

            }).catch(err => {
                console.log(err)
            })
        };
        getSubject()
    }, [id])

    const getTextBook = async () => {
        return await axios.get(`${BASE_URL}${Book.textBook}`,
            { headers: { "Authorization": `Bearer ${token.token}` } }
        ).then(res => {
            setTextBook(res.data);

        }).catch(err => {
            console.log(err)
        })
    };

    const addHandler = () => {

        setTable(false)
        setSubject(true)
    }
    const viewHandler = () => {

        setTable(true)
        setSubject(false)
    }


    const handleFile = (e) => {
        console.log('^%^%^%', e.target.files)
        setSelectedFile(e.target.files[0])
    }

    const onSubmit = async () => {
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                "Authorization": `Bearer ${token.token}`
            }
        };
        const formData = new FormData();
        formData.append("bookName", book);
        formData.append("fileName", selectedFile);
        formData.append("subjectId", subjectData.courseId);

        setIsLoading(true);
        await axios
            .post(`${BASE_URL}${Book.textBook}`, formData, config)
            .then((res) => {
                console.log(res);
                console.log(formData, 'formData');
                setBook("");
                imageInputRef.current.value = "";
                setSelectedFile(null)
                setIsLoading(false);
                setSubject(false)
                setTable(true)
                getTextBook();
            })
            .catch((err) => alert("File Upload Error"));
    }

    const onSubmitDel = async (del) => {
        await deleteTextBook(del)
        getTextBook();
    }
    const role = "teacher";
    return (
        <>
            <div className="flex h-screen justify-center bg-background w-full">
                <aside className="w-14 fixed left-0 top-0  bg-slate-700">
                <TeacherSidebar /> 
                </aside>
                <main className="flex-1 ml-10">
                    <div className="p-10">
                        <h1 className=" text-center text-primary mb-4 text-2xl">The Cambridge Online Digital School</h1>
                        <ContentDiv >
                            <div className="flex flex-col mt-6 lg:-mt-12">
                                <BackButton url={'/dashboard'} />
                            </div>
                        </ContentDiv>
                        <ContentDiv >
                            <div className="flex flex-row">
                                <div className="flex flex-col">
                                    <div className="mt-4 grid grid-cols-1 px-8 ml-4 gap-8 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4  ">
                                        <div className="flex items-start rounded-xl bg-white p-4 shadow-lg cursor-pointer" onClick={() => addHandler()}>
                                            <div className="flex h-16 w-16 items-center justify-center rounded-full border border-primary bg-white-50">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-primary" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
                                                </svg>
                                            </div>
                                            <div className="ml-4">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="text-primary float-right w-6 h-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                                </svg>

                                                <p className="mt-2 text-sm text-gray-500">Add Book</p>
                                            </div>
                                        </div>
                                        <div className="flex items-start rounded-xl bg-white p-4 shadow-lg cursor-pointer" onClick={() => viewHandler()}>
                                            <div className="flex h-16 w-16 items-center justify-center rounded-full border border-primary bg-white-50">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-primary" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
                                                </svg>
                                            </div>
                                            <div className="ml-4">
                                                <span className="flex justify-between">
                                                </span>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="text-primary float-right w-6 h-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607zM13.5 10.5h-6" />
                                                </svg>
                                                <p className="mt-2 text-sm text-gray-500">View  Book</p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </ContentDiv>
                    </div>
                    <div className=" bg-background -mt-10 p-6">
                        <ContentDiv>
                            {/* Table */}
                            {table && <section className="flex flex-col  container ml-4  mx-4">
                                <h1 className="text-center text-xl text-primary mb-4  md:text-left  text-xl lg:text-left  text-2xl xl:text-left   text-2xl">Text Book</h1>

                                <div className="w-full mb-8 overflow-hidden rounded-lg shadow-lg ">
                                    <div className="w-full overflow-x-auto">
                                        <table className="w-full border-spacing">
                                            <thead>
                                                <tr className="text-md font-semibold tracking-wide text-left text-white bg-primary uppercase border-b border-gray-600">
                                                    <th className="px-4 py-3">Book Name</th>
                                                    <th className="px-4 py-3">File</th>
                                                    <th className="px-4 py-3">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody className="bg-white">
                                                {Array.isArray(textBook) ? textBook.map((item) => {
                                                    return (
                                                        <tr className="text-gray-700" key={item.id}>
                                                            <td className="px-4 py-3 text-ms font-semibold border">{item.bookName}</td>
                                                            <td className="px-4 py-3 text-sm border">{item.fileName}</td>
                                                            <td className="px-4 py-3 text-sm border">
                                                                <div className="flex flex-row gap-2">
                                                                    <a href={fileTextBookUrl + item.fileName} target="_blank" rel="noreferrer"> <button type="button"  >
                                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-primary">
                                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                                        </svg>
                                                                    </button>
                                                                    </a>
                                                                    <svg onClick={() => { setDelModal(true); setDelTextBook(item) }} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 text-primary cursor-pointer">
                                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                                                    </svg>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                                ).reverse() : []}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </section>
                            }
                            {subject &&
                                <div className="bg-white rounded shadow-lg p-8 mb-6 ml-6 md:ml-14 w-5/6 lg:ml-12 w-5/6   ">
                                    <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
                                        <div className="text-gray-600">
                                            <p className="text-primary text-lg">Text Book</p>
                                            <p>Please fill out all the fields *</p>
                                        </div>
                                        <div className="lg:col-span-2">
                                            <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5">
                                                <div className="md:col-span-5">
                                                    <label htmlFor="full_name">Book Name</label>
                                                    <input type="text" name="bookName" onChange={(e) => setBook(e.target.value)} id="full_name" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" />
                                                </div>
                                                <div className="md:col-span-5">
                                                    <label htmlFor="code">Upload</label>
                                                    <input type="file" name="fileName" id="file" onChange={handleFile} className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" ref={imageInputRef} />
                                                </div>
                                                <div className="md:col-span-5 mt-2 text-right">
                                                    <div className="inline-flex items-end">
                                                        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={onSubmit}>Submit</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {delModal ? (
                                <>
                                    <div
                                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                                    >
                                        <div className="relative w-auto my-6 mx-auto max-w-3xl">
                                            {/*content*/}
                                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                                {/*header*/}
                                                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">

                                                    <label htmlFor="code" className="form-label inline-block mb-2 text-gray-700">Are you want to sure delete this Book: {delTextBook.bookName} ?</label>
                                                </div>
                                                {/*body*/}

                                                <div className="relative p-6 flex-auto">
                                                    <div className="flex justify-center">
                                                        <div className="mb-3 xl:w-96">

                                                            <div className="flex grid-col-2 justify-center gap-4">
                                                                <button
                                                                    className="bg-red-500 text-white active:bg-red-600 text-white-500 background-transparent rounded font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                                                    type="button"
                                                                    onClick={() => { setDelModal(false); onSubmitDel(delTextBook) }}

                                                                >
                                                                    Yes
                                                                </button>
                                                                <button
                                                                    className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                                                    type="button"
                                                                    onClick={() => { setDelModal(false) }}
                                                                >
                                                                    No
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/*footer*/}
                                                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                                </>
                            ) : null}
                        </ContentDiv>
                    </div>
                    {isLoading && <LoadingSpinner />}
                </main>
            </div>
        </>
    )
}
export default TeacherTextBook


