import { useState, useEffect } from "react";
import Sidebar from '../../components/Sidebar'
import ContentDiv from "../../components/ContentDiv";
import React from 'react';
import { getRegistration, deleteRegistration } from '../../api/onlineRegistration'
import { BASE_URL, token } from "../../utiles/constant";
import axios from "axios";
const ViewRegistration = () => {

    const [record, setRecord] = useState([])
    const [delModal, setDelModal] = useState(false);
    const [delStudent, setDelStudent] = useState({});

    const reload = () => {
        loadData()
    }
    const loadData = async () => {
        return await axios.get(`${BASE_URL}${getRegistration}`,
            { headers: { "Authorization": `Bearer ${token.token}` } }
        ).then(res => {
            setRecord(res.data);
        }).catch(err => {
            console.log(err)
        })
    };

    useEffect(() => {
        loadData();

    }, [])

    const onSubmitDel = async (del) => {
        await deleteRegistration(del)
        loadData();
    }

    return (
        <>
            <div className="flex h-screen justify-center bg-background w-full">
                <aside className="w-14 fixed left-0 top-0  bg-slate-700">
                    <Sidebar />
                </aside>
                <main className="flex-1 ml-10">
                    <div className="flex flex-row">
                        <div className="flex flex-col">
                            <div className="mt-4 grid grid-cols-1 px-8 ml-4 gap-8 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4  ">
                                <div className="flex items-start rounded-xl bg-white  text-white p-4 shadow-lg cursor-pointer"
                                    onClick={reload}
                                >
                                    <div className="">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 text-primary">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-10">
                        <h1 className=" text-center text-primary -mt-8 mb-4 text-2xl">The Cambridge Online Digital School</h1>
                    </div>
                    <div className=" bg-background -mt-4 ">
                        <ContentDiv>
                            {/* Table */}

                            <section className="flex flex-col  container ml-12 px-4   lg:ml-0    ">
                           
                                <h1 className="text-center text-xl text-primary mb-4  md:text-left  text-xl lg:text-left  text-2xl xl:text-left   text-2xl">Students List</h1>
                     
                                <div className=" mb-8 overflow-hidden rounded-lg shadow-lg ">
                                    <div className="w-full overflow-x-auto">
                                        <table className="w-full border-spacing">
                                            <thead>
                                                <tr className="text-md font-semibold tracking-wide text-left text-white bg-primary uppercase border-b border-gray-600">
                                                <th className="px-4 py-3">S.No</th>
                                                    <th className="px-4 py-3">Name</th>
                                                    <th className="px-4 py-3">Father</th>
                                                    <th className="px-4 py-3">Phone</th>
                                                    <th className="px-4 py-3">Whatsapp</th>
                                                    <th className="px-4 py-3">Age</th>
                                                    <th className="px-4 py-3">Address</th>
                                                    <th className="px-4 py-3">Status</th>
                                                    <th className="px-4 py-3">Date</th>
                                                    <th className="px-4 py-3">Subject</th>

                                                    <th className="px-4 py-3">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody className="bg-white">
                                                {Array.isArray(record) ? record.map((item,index) => {

                                                    return (

                                                        <tr className="text-gray-700" key={index+1}>
                                                            <td className="px-4 py-3 text-ms font-semibold border">{index+1}</td>
                                                            <td className="px-4 py-3 text-ms font-semibold border">{item.name}</td>
                                                            <td className="px-4 py-3 text-ms font-semibold border">{item.fname}</td>
                                                            <td className="px-4 py-3 text-ms font-semibold border">{item.phNumber}</td>
                                                            <td className="px-4 py-3 text-ms font-semibold border">{item.whatspp}</td>
                                                            <td className="px-4 py-3 text-ms font-semibold border">{item.age}</td>
                                                            <td className="px-4 py-3 text-ms font-semibold border">{item.address}</td>
                                                            <td className="px-4 py-3 text-ms font-semibold border">{item.ppStudent}</td>
                                                            <td className="px-4 py-3 text-ms font-semibold border"> {item.subject}</td>
                                                            <td className="px-4 py-3 text-sm border">{item.createdAt}</td>

                                                            <td className="px-4 py-3 text-sm border">
                                                                <div className="flex flex-row gap-2">

                                                                    <div onClick={() => { setDelModal(true); setDelStudent(item) }}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 text-primary cursor-pointer">
                                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                }).reverse() : []}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </section>




                            {delModal ? (
                                <>
                                    <div
                                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                                    >
                                        <div className="relative w-auto my-6 mx-auto max-w-3xl">
                                            {/*content*/}
                                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                                {/*header*/}
                                                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                                    <label htmlFor="code" className="form-label inline-block mb-2 text-gray-700">Are you want to sure delete this Student:
                                                        {delStudent.name}
                                                        ?</label>
                                                </div>
                                                {/*body*/}
                                                <div className="relative p-6 flex-auto">
                                                    <div className="flex justify-center">
                                                        <div className="mb-3 xl:w-96">

                                                            <div className="flex grid-col-2 justify-center gap-4">
                                                                <button
                                                                    className="bg-red-500 text-white active:bg-red-600 text-white-500 background-transparent rounded font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                                                    type="button"
                                                                    onClick={() => { setDelModal(false); onSubmitDel(delStudent) }}
                                                                >
                                                                    Yes
                                                                </button>
                                                                <button
                                                                    className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                                                    type="button"
                                                                    onClick={() => { setDelModal(false) }}
                                                                >
                                                                    No
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/*footer*/}
                                                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                                </>
                            ) : null}
                        </ContentDiv>
                    </div>
                </main>
            </div>
        </>
    )
}
export default ViewRegistration