
import FeatureCard from '../../components/FeatureCard'
import ContentDiv from "../../components/ContentDiv";
import React,{useState,useEffect} from 'react';
import HeadSidebar from '../../components/headSidebar';
import { Bar,Doughnut,Pie } from "react-chartjs-2";



import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
} from "chart.js";
const HeadOwnerDashboard = () => {

    const [chartData, setChartData] = useState({
        datasets: [],
    });
    const [chartData2, setChartData2] = useState({
        datasets2: [],
    });

    const [chartOptions, setChartOptions] = useState({});
    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend,
        ArcElement
    );
    useEffect(() => {

        const data = [

            {
                id: 1,
                chapter: "Chapter 1",
                topic: "topic 1",
                attempt: [
                    {
                        attempt: 1,
                        marks: 12
                    },
                    {
                        attempt: 2,
                        marks: 15
                    }
                ],
                avg:13
            },
            {
    
                id: 2,
                chapter: "Chapter 2",
                topic: "topic 2",
                attempt: [
                    {
                        attempt: 1,
                        marks: 12
                    }
                ],
                avg:12
            },
    
        ]

        
        
        setChartData({
            labels: data.map(chapter =>   chapter.chapter),
            datasets: [
                {
                    label: "Students",
                    data: data.map(marks => marks.avg),
                    borderColor: "rgb(53, 162, 235)",
                    backgroundColor: "#ff5951",
                },
            ],
        });
        setChartOptions({
            responsive: true,
            plugins: {
                legend: {
                    position: "top",
                  
                },
                title: {
                    display: true,
                    text: "Student Per Month",
                },
            },
        });
    }, []);


const data2 ={
    labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
    datasets: [
      {
        label: '# of Votes',
        data: [12, 19, 3, 5, 2, 3],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
}
    
    return (
        <>
            <div className="flex  justify-center bg-background w-full">
                <aside className="w-14 fixed left-0 top-0  bg-slate-700">
                    <HeadSidebar />
                </aside>
                <main className="flex-1 ml-10">
                    <div className="p-2 h-min-screen lg:h-36 ">
                        <h1 className="text-center text-primary  text-2xl">The Cambridge Online Digital School</h1>
                        <h1 className="text-center text-primary  text-2xl">Head Owner Dashboard</h1>
                        <ContentDiv >
                            <div className="flex flex-col gap-12">
                                <FeatureCard />
                            </div>
                        </ContentDiv>
                    </div>
                    <div className=" h-min-screen w-min-screen  p-10">
                        <ContentDiv >
                     
                            <section className="flex flex-col container mx-4">
                                <h1 className="text-center text-xl text-primary mb-4  md:text-left  text-xl lg:text-left  text-2xl xl:text-left   text-2xl">Recent Students</h1>
                                <div class="grid grid-cols-2 gap-4">
  <div>Pie Chart

  <Pie data={data2} />
  </div>
<div>Doughnut Char
<Doughnut data={data2} />
</div>
<div>Bar Chart
<Bar options={chartOptions} data={chartData} />
</div>
<div>04
<Bar options={chartOptions} data={chartData} />
</div>

</div>


                                <div className="flex-col-2  rounded-lg shadow-lg ">
                              
                               
                                </div>
                            </section>
                        </ContentDiv>
                    </div>

                    {/* 
                    <div className="h-96 bg-green-400 p-10">
                        <h1 className="text-4xl">Middle Content</h1>
                    </div>
                    <div className="h-96 bg-indigo-400 p-10">
                        <h1 className="text-4xl">Last Content</h1>
                    </div> */}
                </main>
            </div>
        </>
    )
}
export default HeadOwnerDashboard