import ContentDiv from "../../../components/ContentDiv";
import React from 'react';
import StudentSidebar from "../../../components/StudentSidebar";

const FurtherAssessment = () => {

    const course = [
        {
            id: 1,
            course: "Physics",
            subject:"GCSE",
            link:"https://gradegorilla.com/gcse-physics-revision-questions.php"
        },
        {
            id: 2,
            course: "Physics",
            subject:"IGCSE",
            link:"https://gradegorilla.com/igcse-physics-revision-questions.php"
        },
        {
            id: 3,
            course: "Physics",
            subject:"KS3",
            link:"https://gradegorilla.com/KS3-physics-revision-questions.php"
        },
        {
            id: 4,
            course: "Physics",
            subject:"IB",
            link:"https://gradegorilla.com/IB-physics-revision-questions.php"
        }, {
            id: 5,
            course: "Chemistry",
            subject:"GCSE",
            link:"https://gradegorilla.com/gcse-chemistry-revision-questions.php"
        },
        {
            id: 6,
            course: "Chemistry",
            subject:"IGCSE",
            link:"https://gradegorilla.com/igcse-chemistry-revision-questions.php"
        },
        {
            id: 7,
            course: "Chemistry",
            subject:"KS3",
            link:"https://gradegorilla.com/KS3-chemistry-revision-questions.php"
        },
        {
            id: 8,
            course: "Chemistry",
            subject:"IB",
            link:"https://gradegorilla.com/IB-chemistry-revision-questions.php"
        },{
            id: 9,
            course: "Biology",
            subject:"GCSE",
            link:"https://gradegorilla.com/gcse-biology-revision-questions.php"
        },
        {
            id: 10,
            course: "Biology",
            subject:"IGCSE",
            link:"https://gradegorilla.com/igcse-biology-revision-questions.php"
        },
        {
            id: 11,
            course: "Biology",
            subject:"KS3",
            link:"https://gradegorilla.com/KS3-biology-revision-questions.php"
        },
        {
            id: 12,
            course: "Biology",
            subject:"IB",
            link:"https://gradegorilla.com/IB-biology-revision-questions.php"
        }

    ]
  
    return (
        <>
            <div className="flex h-screen justify-center bg-background w-full">
                <aside className="w-14 fixed left-0 top-0  bg-slate-700">
                    <StudentSidebar />
                </aside>
                <main className="flex-1 ml-10">
                    <div className="bg-background p-10">
                        <h1 className=" text-center text-primary mb-4 text-2xl">The Cambridge Online Digital School</h1>
                        <ContentDiv >


                            <section className="flex flex-col container mx-4 p-2">
                                <div className='md:grid grid-cols-4 gap-4'>
                                    {
                                        course.map(item => {
                                            return (
                                                <div className="mb-4 p-6 max-w-sm bg-white rounded-lg border border-gray-200 shadow-md bg-white dark:border-shadow-lg" key={item.id}>

                                                    <h5 className="mb-2  font-medium tracking-tight  text-primary">{item.course}</h5>
                                                    <h6 className="mb-2 text-lg font-medium tracking-tight  text-primary">{item.subject}</h6>

                                                    <div className='grid grid-cols-2 gap-4'>
                                                        <a href={item.link} rel='noreferrer' target='_blank'  className="inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-primary rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-blue-300 bg-primary dark:hover:bg-red-700 dark:focus:ring-blue-800">
                                                            Link
                                                            <svg aria-hidden="true" className="ml-2 -mr-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                                        </a>
                                                        
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </section>

                        </ContentDiv>
                    </div>



                </main>
            </div>
        </>
    )
}
export default FurtherAssessment