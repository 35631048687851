import axios from "axios";
import {BASE_URL} from "../utiles/constant";
export  const Lesson ={
  lesson : "/api/Lesson", 
  lessonList:"/api/LessonList",
  lessonIntroVideo:"/api/LessonIntroVideo"
}
const user =  localStorage.getItem('user')
const token  = JSON.parse(user);

export const getLesson = Lesson.lesson;
// export const lessonIntroVideo = Lesson.lessonIntroVideo;

export  const editLesson = (data) => {
    return axios.put(`${BASE_URL}${Lesson.lesson}/${data.id}`,data,
    { headers: {"Authorization" : `Bearer ${token.token}`} }
    
    ).then(res=>{
     //   console.log(res,'TEST')
    }).catch(err=>{
        console.log(err)
    })
  };


  export  const deleteLesson = (del) => {
    return axios.delete(`${BASE_URL}${Lesson.lesson}/${del.id}`,
    { headers: {"Authorization" : `Bearer ${token.token}`} }
    
    ).then(res=>{
     //  console.log(res,'TEST')
    }).catch(err=>{
        console.log(err)
    })
  };

export  const createLesson = (data) => {
    return axios.post(`${BASE_URL}${Lesson.lesson}`,data,
    { headers: {"Authorization" : `Bearer ${token.token}`} }
    
    ).then(res=>{
        //console.log(res,'TEST')
    }).catch(err=>{
        console.log(err)
    })
  };


