import ContentDiv from "../../../components/ContentDiv";
import StudentSidebar from "../../../components/StudentSidebar";
import logo from '../../../assests/images/logos/Cambridge-logo.webp';
import React from 'react';


const StudentAnnoucement = () => {


    
    return (
        <>
            <div className="flex h-screen justify-center bg-background w-full">
                <aside className="w-14 fixed left-0 top-0  bg-slate-700">
                    <StudentSidebar />
                </aside>
                <main className="flex-1 ml-10">
                    <div className="p-10">
                    <ContentDiv classes={'justify-center -mt-4'}>
                            <img className='' src={logo} alt="CambridgeOnline" height={250} width={250} />
                        </ContentDiv>
                        
                    </div>
                    <div className=" bg-background -mt-10 p-6">
                        <ContentDiv>
                        <div className="container">
          <div
            className="flex flex-col md:grid  mx-auto p-2 text-blue-50"
          >
           
      
           
            <div className="flex md:contents">
              <div className="col-start-5 col-end-6 mr-10 md:mx-auto relative">
                <div className="h-full w-6 flex items-center justify-center">
                  <div className="h-full w-1 bg-primary  pointer-events-none"></div>
                </div>
                <div
                  className="w-6 h-6 absolute top-1/2  -mt-3 rounded-full bg-primary shadow"
                ></div>
              </div>
              <div
                className="bg-primary col-start-6 col-end-10 p-4 rounded-xl my-4 mr-auto shadow-md"
              >
                <h3 className="font-semibold text-lg mb-1">Lorem ipsum</h3>
                <p className="leading-tight text-justify">
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Vitae, facilis.
                </p>
              </div>
            </div>
           
           
           
            <div className="flex flex-row-reverse md:contents">
              <div
                className="border-primary text-black border-2 col-start-1 col-end-5 p-4 rounded-xl my-4 ml-auto shadow-md"
              >
             Date:01-Nov-22
              </div>
              <div className="col-start-5 col-end-6 md:mx-auto relative mr-10">
                <div className="h-full w-6 flex items-center justify-center">
                  <div className="h-full w-1 bg-primary     pointer-events-none"></div>
                </div>
                <div
                  className="w-6 h-6 absolute top-1/2 -mt-3 rounded-full bg-primary shadow"
                ></div>
              </div>
            </div>
        
           
          </div>
        </div>
                        </ContentDiv>
                    </div>
                </main>
            </div>
        </>
    )
}
export default StudentAnnoucement


