
import ContentDiv from "../../components/ContentDiv";
import logo from '../../assests/images/logos/Cambridge-logo.webp'
import React, { useState } from 'react';
import { createRegistration } from "../../api/onlineRegistration";
const OnlineRegistration = () => {
  const [ppStudent, setPPStudent] = React.useState('');
  const [msg, setMessage] = useState(false);
  const [form, setForm] = useState(true);
  const [lang, setLang] = useState([]);
  const [error,setError] =useState(false);
  const initialState = {
    name: "",
    fname: "",
    phNumber: "",
    whatspp:  "",
    address: "",
    age: "",
    subject: "",
    ppStudent: ""
  }
  const [data, setData] = useState(initialState)

  const handleRegularChange = () => {
    setPPStudent('regular');
  };

  const handlePrivateChange = () => {
    setPPStudent('private');
  };

  const handleChange = e => {
    const { value, checked } = e.target;
    if (checked) {
      // push selected value in list
      setLang(prev => [...prev, value]);
    } else {
      // remove unchecked value from the list
      setLang(prev => prev.filter(x => x !== value));
    }
  }
  const onInputChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value, })
  }

  const subjectList = [
    { value: "english", label: "ENGLISH" },
    { value: "urdu", label: "URDU" },
    { value: "biology", label: "BIOLOGY" },
    { value: "physics", label: "PHSICS" },
    { value: "islamiyat", label: "ISLAMIAT" },
    { value: "chemistry", label: "CHEMISTRY" },
    { value: "mathematics", label: "MATHEMATICS" },
    { value: "business-studies", label: "BUSINESS STUDIES" },
    { value: "pakistan-studies", label: "PAKISTAN STUDIES" }
  ];


  const Submit = async () => {
    const onlineRegistation = {
      onlineRegistration: data,
      ppStudent: ppStudent,
      subject: lang

    }
    data.ppStudent = onlineRegistation.ppStudent;
    await createRegistration(onlineRegistation).then(res=>{
      setData(initialState)
      setPPStudent("")
      setMessage(true)
      setForm(false)
    }).catch(err=>{
      setError(true)
        setTimeout(()=>{
          setError(false
            
            )
        },3000)

    })
  }

  return (
    <>
      <div className="flex  justify-center bg-background w-full">
        <main className="flex-1 ml-10">
          <div className="p-2 h-min-screen lg:h-36 ">
            <ContentDiv >
              <div className="flex flex-col gap-6 p-6">
                {/* <FeatureCard /> */}
                <img className='mt-2  -mb-4' src={logo} alt="Cambridge-logo" width={200} height={200} />
              </div>
            </ContentDiv>
          </div>
            <ContentDiv >
              <section className="flex flex-col container mx-4 ">
                {form &&
                  <div className="bg-white rounded shadow-lg p-8 mb-6 ml-6 md:ml-14 w-5/6 lg:ml-12 w-5/6   ">
                    <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
                      <div className="text-gray-600">
                        <p className="text-primary text-lg">Student Form</p>
                      </div>
                      <div className="lg:col-span-6">
                        <p className="text-center"></p>
                        <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-6">
                          <div className="md:col-span-3">
                            <label htmlFor="name">Student Name </label>
                            <input type="text" name="name" id="name" required={true} className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                              value={data.name || ''} onChange={(e) => onInputChange(e)}
                            />
                          </div>
                          <div className="md:col-span-3">
                            <label htmlFor="fname">Father Name </label>
                            <input type="text" name="fname" id="fname" required className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                              value={data.fname || ''} onChange={(e) => onInputChange(e)}
                            />
                          </div>
                          <div className="md:col-span-3">
                            <label htmlFor="code">Phone </label>
                            <input type="number" pattern="[0-9]*" name="phNumber" id="phNumber" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                              value={data.phNumber || ''} onChange={(e) => onInputChange(e)}
                            />
                          </div>
                          <div className="md:col-span-3">
                            <label htmlFor="whatspp">whatsapp Number </label>
                            <input type="number" name="whatspp" pattern="[0-9]*" id="whatspp" required className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" 
                              value={data.whatspp || ''} onChange={(e) => onInputChange(e)}
                            />
                          </div>
                          <div className="md:col-span-3">
                            <label htmlFor="password">Address</label>
                            <input
                              name="address" id="address" type={"text"} required className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" 
                              value={data.address || ''} onChange={(e) => onInputChange(e)}
                            />
                          </div>
                          <div className="md:col-span-3">
                            <label htmlFor="age">Age</label>
                            <input type="number" name="age" id="address" required className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" 
                              value={data.age} onChange={(e) => onInputChange(e)}
                            />
                          </div>
                          <div className="md:col-span-5">
                            <label htmlFor="address">Selected Subject</label>
                            <input type="text" readOnly name="subject" id="subject" required className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" placeholder="Street/Address"
                              value={lang.length ? lang.join(', ').toUpperCase() : null || ''} onChange={(e) => onInputChange(e)}
                            />
                          </div>
                          <div className="flex flex-col py-4 gap-4 md:col-span-3">
                            <label htmlFor="city">Select Subjects to Enroll</label>
                            {subjectList.map((x, i) => <label key={i}>
                              <input
                                type="checkbox"
                                name="lang"
                                value={x.value}
                                onChange={handleChange}
                              /> {x.label}
                            </label>)}
                          </div>
                          <div className="flex flex-col py-4 md:col-span-3">
                            <label htmlFor="phone">Regular or Private Student</label>
                            <RadioButton
                              label="Regular"
                              value={ppStudent === 'regular'}
                              onChange={handleRegularChange}
                            />
                            <RadioButton
                              label="Private"
                              value={ppStudent === 'private'}
                              onChange={handlePrivateChange}
                            />
                          </div>
                          {error &&  <span className='text-primary'> Fill the form properly </span>} 
                          <div className="md:col-span-3 mt-2 text-right">
                            <div className="inline-flex items-end">
                              <button onClick={Submit} type="submit" className="bg-primary hover:bg-red-700 text-white font-bold py-2 px-4 rounded">Submit</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>}
                {
                  msg && <div className="bg-white rounded shadow-lg p-8 mb-6 ml-6 md:ml-14 w-5/6 lg:ml-12 w-5/6   ">
                    <h2 className="text-primary text-xl">Thank you for your registration. Someone will contact you soon...</h2>

                    <div className="md:col-span-5 mt-2 text-right">
                      <div className="inline-flex items-end">

                        <a href="https://cambridgeonlineschool.me/">   <button className="bg-primary hover:bg-red-700 text-white font-bold py-2 px-4 rounded">Back</button></a>
                      </div>
                    </div>
                  </div>
                }
              </section>
            </ContentDiv>
        </main>
      </div>
    </>
  )
}
export default OnlineRegistration


const RadioButton = ({ label, value, onChange }) => {
  return (
    <label>
      <div className="flex flex-row p-2  gap-6">
        <input className="" type="radio" checked={value} onChange={onChange} />
        {label}
      </div>
    </label>
  );
};


