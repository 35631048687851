// import Image from 'next/image'
import React from 'react';
const Footer = () => {
    return (
        <>
            <footer className="footer  w-full h-16  border-t-2 border-[bg-primary] 
            fixed left-0 bottom-0
            flex justify-center items-center
            text-white text-2xl
            
            "><a
                    href="https://vercel.com?utm_source=create-next-app&utm_medium=default-template&utm_campaign=create-next-app"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <p className='text-primary font-thin'>Powered by</p>
                    <span className={'logo'}>
                        {/* <Image src="/vercel.svg" alt="Vercel Logo" width={72} height={16} /> */}
                    </span>
                </a>
            </footer>
        </>
    )
}

export default Footer;