import { useState } from "react";
import Sidebar from '../../../components/Sidebar'
import ContentDiv from "../../../components/ContentDiv";
import { Link } from "react-router-dom";
import React from 'react';

const Assessment = () => {


    const [viewQuestion, setViewQuestion] = useState(false)

    const course = [
        {
            id: 1,
            course: "ENGLISH - 0510"
        },
        {
            id: 2,
            course: "MATHEMATICS - 0580"
        },
        {
            id: 3,
            course: "BIOLOGY - 0610"
        },
        {
            id: 4,
            course: "BUSINESS STUDIES - 0450"
        }, {
            id: 5,
            course: "CHEMISTRY - 0620"
        },
        {
            id: 5,
            course: "ISLAMIYAT - 0493"
        },
        {
            id: 5,
            course: "PAKISTAN STUDIES - 0448"
        },
        {
            id: 5,
            course: "PHYSICS - 0625"
        },
        {
            id: 5,
            course: "URDU  - 0539"
        }

    ]
    const questionList = [
        {
            id: 1,
            img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRUWMeI5-o79F2ueD-DFCFar2mC2K2qziWQHisH7JfLy6-xhbJReaswqj5S3j87fb9FnvM&usqp=CAU",
            code: "10",
            answer: "a"

        },
        {
            id: 2,
            img: "https://www.fast-standard.org/wp-content/uploads/2016/03/sample_question_1.png",
            code: "20",
            answer: "b"
        },
        {
            id: 3,
            img: "https://www.fast-standard.org/wp-content/uploads/2016/03/sample_question_5.png",
            code: "30",
            answer: "c"
        },
        {
            id: 4,
            img: "https://images.slideplayer.com/26/8777630/slides/slide_2.jpg",
            code: "40",
            answer: "d"
        }
    ]
    return (
        <>
            <div className="flex h-screen justify-center bg-background w-full">
                <aside className="w-14 fixed left-0 top-0  bg-slate-700">
                    <Sidebar />
                </aside>
                <main className="flex-1 ml-10">
                    <div className="bg-background p-10">
                        <h1 className=" text-center text-primary mb-4 text-2xl">The Cambridge Online Digital School</h1>
                        <ContentDiv >


                            <section className="flex flex-col container mx-4 p-2">
                                <div className='md:grid grid-cols-3 gap-4'>
                                    {
                                        course.map(item => {
                                            return (
                                                <div className="mb-4 p-6 max-w-sm bg-white rounded-lg border border-gray-200 shadow-md bg-white dark:border-shadow-lg">

                                                    <h5 className="mb-2  font-medium tracking-tight  text-primary">Course</h5>
                                                    <h6 className="mb-2 text-lg font-medium tracking-tight  text-primary">{item.course}</h6>

                                                    <div className='grid grid-cols-2 gap-4'>
                                                        <Link exact to={`/add/topic/questions/${item.id}/${item.course}`} onClick={() => { setViewQuestion(false) }} className="inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-primary rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-blue-300 bg-primary dark:hover:bg-red-700 dark:focus:ring-blue-800">
                                                            Chapters
                                                            <svg aria-hidden="true" className="ml-2 -mr-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                                        </Link>
                                                        <Link  onClick={() => { setViewQuestion(true) }} className="inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-primary rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-blue-300 bg-primary dark:hover:bg-red-700 dark:focus:ring-blue-800">
                                                            View Question
                                                            <svg aria-hidden="true" className="ml-2 -mr-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                                        </Link>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </section>

                        </ContentDiv>
                    </div>

                    {viewQuestion &&
                        <div className=" bg-background -mt-10 p-6">
                            <ContentDiv>
                                {/* Table */}
                                <section className="flex flex-col  container ml-4  mx-4">
                                    <h1 className="text-center text-xl text-primary mb-4  md:text-left  text-xl lg:text-left  text-2xl xl:text-left   text-2xl">Total Questions: {questionList.length} </h1>

                                    <div className="w-full mb-8 overflow-hidden rounded-lg shadow-lg ">
                                        <div className="w-full overflow-x-auto">
                                            <table className="w-full border-spacing">
                                                <thead>
                                                    <tr className="text-md font-semibold tracking-wide text-left text-white bg-primary uppercase border-b border-gray-600">
                                                        <th className="px-4 py-3">Question</th>
                                                        <th className="px-4 py-3">Answer</th>
                                                        <th className="px-4 py-3">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="bg-white">

                                                    {questionList.map((item) => {
                                                        return (
                                                            <tr className="text-gray-700" key={item._id}>

                                                                <td className="px-4 py-3 text-ms font-semibold border">
                                                                    <img height={'auto'} width={500} alt='img' src={item.img} />

                                                                </td>

                                                                <td className="px-4 py-3 text-sm border">{item.answer}</td>
                                                                <td className="px-4 py-3 text-sm border">
                                                                    <div className="flex flex-row gap-2">


                                                                        <Link to='/assessments/addMutipleChoice'>

                                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 text-primary cursor-pointer">
                                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                                                            </svg>
                                                                        </Link>
                                                                        <button type="button"  >
                                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 text-primary cursor-pointer">
                                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                                                            </svg>
                                                                        </button>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }

                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </section>

                            </ContentDiv>
                        </div>
                    }


                </main>
            </div>
        </>
    )
}
export default Assessment