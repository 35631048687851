import { useState } from "react";
import ContentDiv from "../../../components/ContentDiv";
import React from 'react';
import { useParams } from "react-router-dom";
import Sidebar from "../../../components/Sidebar";
// import axios from "axios"
const OwnerSubmitQuestion = () => {
 
    const {courseId,course,chapterId,chapter,id,topic} =useParams();
    const [optionList, setoptionList] = useState([{ option: "", imgUrl: "" }]);
    const onSubmit = () => {
        let data = {
            courseId:courseId,
            chapterId:chapterId,
            topicId:id,
            optionList
        }
        console.log(data, 'data')
    }

    // handle input change
    const handleInputChange = (event, index) => {
        const values = [...optionList]
        if (event.target.name === "option") {
            values[index].option = event.target.value
        }
        else {
            values[index].imgUrl = event.target.value
        }
        setoptionList(values)
    };
    // handle click event of the Remove button
    const handleRemoveClick = index => {
        const list = [...optionList];
        list.splice(index, 1);
        setoptionList(list);
    };
    // handle click event of the Add button
    const handleAddClick = () => {
        const values = [...optionList];
        values.push({ option: '', imgUrl: '' });
        setoptionList(values);
    };
    return (
        <>
            <div className="flex h-screen justify-center bg-background w-full">
                <aside className="w-14 fixed left-0 top-0  bg-slate-700">
                    <Sidebar />
                </aside>
                <main className="flex-1 ml-10">
                    <div className="p-10">
                        <h1 className=" text-center text-primary  text-2xl">The Cambridge Online Digital School</h1>
                    </div>
                    <div className=" bg-background -mt-10 p-6">
                        <ContentDiv>
                            <div className="bg-white rounded shadow-lg p-8 mb-6 ml-6 md:ml-14 w-5/6 lg:ml-12 w-5/6   ">
                                <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
                                    <div className="flex flex-col">
                                    <div className="text-gray-600">
                                          <h3 className="text-xl">Course: <span className="text-primary text-lg">{courseId}  {course}</span> </h3>
                                      </div>
                                        <div className="text-gray-600 mt-4">
                                            <h3 className="text-xl">Chapter: <span className="text-primary text-lg">{chapterId}  {chapter}</span> </h3>
                                        </div>
                                        <div className="text-gray-600 mt-4">  
                                            <h3 className="text-xl">Topic: <span className="text-primary text-lg">{id}  {topic}</span> </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ContentDiv>
                    </div>
                    <div className=" bg-background -mt-10 p-6" >
                        <ContentDiv>
                            <div className="bg-white rounded shadow-lg p-8 mb-6 ml-6 md:ml-14 w-5/6 lg:ml-12 w-5/6   ">
                                <div className="grid gap-4 gap-y-2 text-sm grid-cols-1">
                                    <div className="lg:col-span-2">
                                        <div className=" gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5">
                                            <div className="mb-4 md:col-span-5">
                                                <label htmlFor="full_name" className="mb-4">Insert Answers</label>
                                                {optionList.map((x, index) => {
                                                    return (
                                                        <div className="-ml-6 flex flex-col gap-2 mt-4 mb-4 md:ml-0 lg:ml-0" key={index + 1}>
                                                            <span className="text-xl">{index + 1}.</span>
                                                            <div className=" grid grid-cols-1 px-4 gap-8 sm:grid-cols-2 md:grid-cols-3 gap-4 px-2 lg:grid-cols-2 xl:grid-cols-2">
                                                                <div>
                                                                    <input onChange={(event) => handleInputChange(event, index)} className="block w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" aria-describedby="file_input_help" id="file_input" type="file" />
                                                                    <p className="mt-1 text-sm text-gray-500 dark:text-gray-300" id="file_input_help">SVG, PNG, JPG or GIF (MAX. 800x400px).</p>
                                                                </div>
                                                                <div>
                                                                    <select id="option" name="option" value={x.option} onChange={(e) => handleInputChange(e, index)} className="bg-secondary border border-gray-300 text-gray-900 text-sm rounded-lg w-48 focus:ring-blue-500 focus:border-primary block w-full p-2.5 dark:bg-secondary dark:border-secondary dark:placeholder-black-100 dark:text-black dark:focus:ring-primary dark:focus:border-primary md:w-36 lg:w-48">
                                                                        <option value="e" selected>Select Option</option>
                                                                        <option value="a">A</option>
                                                                        <option value="b">B</option>
                                                                        <option value="c">C</option>
                                                                        <option value="d">D</option>
                                                                    </select>
                                                                </div>
                                                                <div>
                                                                    {optionList.length !== 1 &&
                                                                        <button
                                                                            className="bg-primary hover:bg-red-700 text-white font-bold w-6 h-6  px-2 rounded"
                                                                            onClick={() => handleRemoveClick(index)}>x</button>
                                                                    }
                                                                    {optionList.length - 1 === index &&
                                                                        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold w-6 h-6  px-2 rounded"
                                                                         onClick={handleAddClick}>+</button>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ContentDiv>
                        <div className="md:col-span-5 mt-2 text-center">
                            <div className="inline-flex items-end gap-4">
                                <button onClick={onSubmit} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Submit</button>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}
export default OwnerSubmitQuestion