
import logo from '../../../assests/images/logos/Cambridge-logo.webp';
import ContentDiv from "../../../components/ContentDiv";
import StudentSidebar from '../../../components/StudentSidebar';
import { json, Link } from 'react-router-dom';
import { getStudentCourse } from '../../../api/student';
import { BASE_URL } from "../../../utiles/constant";
import axios from "axios";
import React,{useEffect, useState} from 'react';
import jwtDecode from 'jwt-decode';

const OnlineResource = () => {
    const [record, setRecord] = useState([])
    const user =  localStorage.getItem('user')
    const decodeToken = jwtDecode(user);
    const studnetId =  decodeToken.primarysid;
    const token  = JSON.parse(user);

    useEffect(()=>{
        const loadData = async () => {
            return await axios.get(`${BASE_URL}${getStudentCourse}/${studnetId}`,
                { headers: { "Authorization": `Bearer ${token.token}` } }
            ).then(res => {
                setRecord(res.data);
            }).catch(err => {
                console.log(err)
            })
        };
        loadData();
    },[])
    return (
        <>
  <div className="flex h-screen justify-center bg-background w-full">
                <aside className="w-14 fixed left-0 top-0  bg-slate-700">
                    <StudentSidebar />
                </aside>
                <main className="flex-1 ml-10">
                    <div className="p-10">
                        <ContentDiv classes={'justify-center'}>
                            <img className='' src={logo} alt="CambridgeOnline" height={250} width={250} />
                        </ContentDiv>
                        
                    </div>
                    <div className=" bg-background -mt-10 p-6">
                    <ContentDiv >
                            <section className="gap-4 py-6  container mx-4 p-2 mt-4 md:flex col-grid">
                                {/* <BackButton/> */}
                            {Array.isArray(record) ? record.map((item,index) => {
                                                        return (
                                <div className="mb-4 p-6 max-w-sm bg-white rounded-lg border border-gray-200 shadow-md bg-white dark:border-shadow-lg md:mb-0" key={index+1}>
                                    <a href="/#">
                                        <h6 className="mb-2  font-medium tracking-tight  text-primary">Course</h6>
                                        <h5 className="mb-2 text-xl font-medium tracking-tight  text-primary">{item.course}</h5>
                                    </a>
                                    <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">A one year subscription for the course of Cambridge  program designed for absolute beginners. Getting student ready for practicing and learning Cambridge course online.</p>
                                    
                                    <Link to={`/student/onlineResource/chapters/${item.courseId}`} className="inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-primary rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-blue-300 bg-primary dark:hover:bg-red-700 dark:focus:ring-blue-800">
                                        
                                   View Course
                                        <svg aria-hidden="true" className="ml-2 -mr-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                        </Link> 
                                </div>
                                )}):[]}
                                
                            </section>
                        </ContentDiv>
                    </div>
                </main>
            </div>


            
        </>
    )
}
export default OnlineResource