import axios from "axios";
import {BASE_URL} from "../utiles/constant";
export  const Student ={
  marks : "/api/StudentMarks",
  dashboard:"/api/StudentDashboard" 
}

const user =  localStorage.getItem('user')
const token  = JSON.parse(user);

export const dashboard = Student.dashboard;



//   export  const deleteSubject = (del) => {
//     return axios.delete(`${BASE_URL}${Subject.subject}/${del.id}`,
//     { headers: {"Authorization" : `Bearer ${token.token}`} }
    
//     ).then(res=>{
//      //   console.log(res,'TEST')
//     }).catch(err=>{
//       //  console.log(err)
//     })
//   };

export  const attempQuiz = (data) => {
    return axios.post(`${BASE_URL}${Student.marks}`,data,
    { headers: {"Authorization" : `Bearer ${token.token}`} }
    
    ).then(res=>{
     //   console.log(res,'TEST')
    }).catch(err=>{
     //   console.log(err)
    })
  };


