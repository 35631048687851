
import { Link } from "react-router-dom";
import ContentDiv from "../../../components/ContentDiv";
import StudentSidebar from "../../../components/StudentSidebar";
import logo from '../../../assests/images/logos/Cambridge-logo.webp';
import React from 'react';


const StudentReports = () => {




    

    const data=[
        {
            id:1,
            subject:"English",
          
        },
        {
            id:2,
            subject:"Math",
          
        },
        {
            id:3,
            subject:"Physic",
        },
        {
            id:4,
            subject:"Chemistry",
        }
    ]
    return (
        <>
            <div className="flex h-screen justify-center bg-background w-full">
                <aside className="w-14 fixed left-0 top-0  bg-slate-700">
                    <StudentSidebar />
                </aside>
                <main className="flex-1 ml-10">
                    <div className="p-10">
                    <ContentDiv classes={'justify-center -mt-4'}>
                            <img className='' src={logo} alt="CambridgeOnline" height={250} width={250} />
                        </ContentDiv>
                        
                    </div>
                    <div className=" bg-background -mt-12 p-6">
                        <ContentDiv>
                            {/* Table */}
                            <section className="flex flex-col  container ml-4  mx-4">
                                <h1 className="text-center text-xl text-primary mb-4  md:text-left  text-xl lg:text-left  text-2xl xl:text-left   text-2xl">Reports</h1>

                                <div className="w-full mb-8 overflow-hidden rounded-lg shadow-lg ">
                                    <div className="w-full overflow-x-auto">
                                        <table className="w-full border-spacing">
                                            <thead>
                                                <tr className="text-md font-semibold tracking-wide text-left text-white bg-primary uppercase border-b border-gray-600">
                                                    <th className="px-4 py-3">Subjects</th>
                                                    <th className="px-4 py-3">Reports</th>
                                                </tr>
                                            </thead>
                                            <tbody className="bg-white">
                                          
                                               {data.map((item)=>
                                               {
                                                return(
                                                    <tr className="text-gray-700" key={item._id}>
                                                    <td className="px-4 py-3 text-ms font-semibold border">{item.subject}</td>
                                                    <td className="px-4 py-3 text-sm border">
                                                       <div className="flex flex-row gap-2">
                                                        <Link to={`/student/reports/${item.id}/${item.subject}`}>   <button type="button"  >
                                                           <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-primary">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25M9 16.5v.75m3-3v3M15 12v5.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                                                            </svg>
                                                           </button>
                                                           </Link>
                                                       </div>
                                                   </td>
                                                </tr>
                                                )
                                               }
                                            
                                               )}



                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </section>
                            
                        
                        </ContentDiv>
                    </div>
                </main>
            </div>
        </>
    )
}
export default StudentReports


