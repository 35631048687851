
 import logo from '../../assests/images/logos/Cambridge-logo.webp';
 import ContentDiv from "../../components/ContentDiv";
 import TeacherSidebar from '../../components/TeacherSidebar';
 import React from 'react';
 const TeacherDashboard = () => {
 
 
     return (
         <>
             <div className="flex h-screen justify-center bg-background w-full">
                 <aside className="w-14 fixed left-0 top-0  bg-slate-700">
                     <TeacherSidebar />
                 </aside>
                 <main className="flex-1 ml-10">
                     <div className="p-10">
                          <ContentDiv classes={'justify-center'}>
                             <img className='' src={logo} alt="CambridgeOnline" height={250} width={250} />
                         </ContentDiv>
                         
                     </div>
                     <div className=" bg-background -mt-10 p-6">
                     <ContentDiv >
                    
                       </ContentDiv>
                     </div>
                 </main>
             </div>
         </>
     )
 }
 export default TeacherDashboard