import axios from "axios";
import {BASE_URL} from "../utiles/constant";
export  const Registration ={
  registration : "/api/OnlineRegistration", 
}


export const getRegistration = Registration.registration;
const user =  localStorage.getItem('user')
const token  = JSON.parse(user);

  export  const deleteRegistration = (del) => {
    return axios.delete(`${BASE_URL}${Registration.registration}/${del.id}`,
    { headers: {"Authorization" : `Bearer ${token.token}`} }
    
    ).then(res=>{
        console.log(res,'TEST')
    }).catch(err=>{
        console.log(err)
    })
  };


export  const createRegistration = (data) => {
    return axios.post(`${BASE_URL}${Registration.registration}`,data,
    // { headers: {"Authorization" : `Bearer ${token.token}`} }
    )
    // .then(res=>{
    //     console.log(res,'TEST')
    // }).catch(err=>{
    //     console.log(err)
    // })
  };


