
export const AppRoute={

    ForgetPasswordStudent:"/forgetPassword/student/:email",
    ForgetPasswordTeacher:"/forgetPassword/teacher",
    StudentLogin:"/",

    //HeadOwner
    HeadOwner:"/headowner",
    HeadOwnerDashboard:"/headowner/dashboard",
    StudnetHO:"/headowner/dashboard/students",
    TeacherHO:"/headowner/dashboard/teachers",
    SubjectHO:"/headowner/dashboard/subjects",
    AnnouncementHO:"/headowner/dashboard/announcements",
    FinanceHO:"/headowner/dashboard/finance",
    //Owner
    ViewOnlineRegistration:"/onlineRegistration",
    Dashboard:"/dashboard",
    Subjects:"/subjects",
    Students:"/students",
    Teachers:"/teachers",
    Subscription:"/subscription",
    Assessments:"/assessments",

    //
    AddStructuredQuestion:"/assessments/addStructuredQuestion/:courseId/:course/:chapterId/:chapter/:id/:topic",
 
    
//
    Annoucement:"/announcements",
    TextBooks:"/textBooks",
    CreateChapter:"/subject/chapters/:subjectId/:subject",
    CreateLesson:"/subject/chapters/lesson/:id/:chapterName/:subjectId/:subjectName",
    CreateTopic:"/subject/chapters/lesson/topic/:subjectId/:subjectName/:chapterId/:chapterName/:id/:lesson",
    CreateSubTopic:"/subject/chapters/lesson/topic/subTopic/:topic/:topicId",

    OwnerAddTopicQuestion:"/add/topic/questions/:subjectId/:subjectName",
    OwenerSubmitQuestion:"/add/questions/:courseId/:course/:chapterId/:chapter/:id/:topic",
    //Student
    StudentCourse:"/student/course",
    StudentDashboard:"/student/dashboard",
    StudentAnnoucement:"/announcement",
    StudentOnlineResource:"/student/onlineResource/",
    StudentOnlineResourceChapter:"/student/onlineResource/chapters/:courseId",

    StudentOnlineResourceTopic:"/student/onlineResource/chapters/lesson/:chapterId/:id/:courseId",
    
    StudentAttemptAssessmet:"/student/assessment",
    StudentReports:"/student/reports",
    StudentReportDetails:"/student/reports/:id/:subject",
    StudentAnswerKeys:"/student/reports/answerKey/:id",
    StudentTextBooks:"/student/textBooks",
    StudentAttemptQuiz:"/student/onlineResource/attemptQuiz/topic/:courseId/:chapterId/:topicId/:topic/:chapter/:id",
    // StudentEnrolledSubject:"/student/enrolledSubject",
    StudentAskQuestion:"/student/askQuestion/",
    TeacherAnswer:"/student/teacher/answer",
    StudentFurtherAssessment:"/student/furtherAssement",
    //Teacher
    Teacher:"/teacher",
    TeacherDashboard:"/teacher/dashboard",
    StudentQuestions:"/teacher/studentQuestions",
    TeacherTextBooks:"/teacher/textBooks",
    AnsweringQuestion:"/teacher/answer/questions",
    TeacherTopic:"/teacher/questions/topic",
    TeacherAddQuestion:"/teahcer/add/questions/:courseId/:course/:chapterId/:chapter/:id/:topic",
    TeacherStructuredQuestions:"/teacher/assessments/StructuredQuestion/:courseId/:course/:chapterId/:chapter/:id/:topic",

    //Parent
    Parent:"parent/dashboard",
// StudentReports:"/student/reports",


}