import {  useState } from "react";
import {Link} from 'react-router-dom'
import ContentDiv from "../../components/ContentDiv";

import BackButton from "../../components/BackButton";
import HeadSidebar from "../../components/headSidebar";




const FinanceHO = () => {


    const [table, setTable] = useState(true)
    const [subject, setSubject] = useState(false)
    const [timeline, setTimeline] = useState(false)
    // const [message, setMessage] =useState(false)
    const initalState ={
        subject:"",
        code:""
    }
    const [inputField,setInputField] =useState(initalState)

    const addHandler = () => {

        setTable(false)
        setSubject(true)
        setTimeline(false)
    }
    const viewHandler = () => {

        setTable(true)
        setSubject(false)
        setTimeline(false)
    }
    const viewTimeline = () => {

        setTable(false)
        setSubject(false)
        setTimeline(true)
    }

    const onInputChange =(e)=>{
        setInputField({ ...inputField, [e.target.name]: e.target.value })
    }
    const onSubmit = ()=>{
    //     console.log(inputField,'data');
    //      axios.post(`${url}/subjects`,inputField).
         
    //   then((res)=>{
    //         console.log(res)
    //         if(res.status===200){
    //             console.log("Add ",res)
    //             console.log({...initalState},'log')
    //             setInputField({...initalState})
    //             setMessage(res.data.msg)
    //             setTimeout(()=>{
    //                 setMessage(false)
                   
    //             },3000)
               
               
                
    //         }
    //      }).catch((err)=>{
    //         console.log(err)
    //          setMessage(err.data.msg)
    //      });

    }

    const data=[
        {
            id:1,
            announcement:"Maintenance of Sunday due to technical issue",
            date:'12/11/2022'
        },
        {
            id:2,
            announcement:"Videos will be uploaded soon ",
            date:'20/11/2022'
        }
    ]
    return (
        <>
            <div className="flex h-screen justify-center bg-background w-full">
                <aside className="w-14 fixed left-0 top-0  bg-slate-700">
                    <HeadSidebar />
                </aside>
                <main className="flex-1 ml-10">
                    <div className="p-10">
                        <h1 className=" text-center text-primary mb-4 text-2xl">The Cambridge Online Digital School</h1>
                        <h1 className=" text-center text-primary mb-4 text-2xl">Finance Model</h1>
                        <ContentDiv >
                            <div className="flex flex-col mt-6 lg:-mt-12">
                                <BackButton url={'/dashboard'} />
                            </div>
                        </ContentDiv>   

                    
                    </div>
                    
                </main>
            </div>
        </>
    )
}
export default FinanceHO


