
import { NavLink, useNavigate } from 'react-router-dom'
import logo from '../assests/images/logos/Cambridge-logo.webp'
import React from 'react';
const StudentSidebar = () => {
    const navigate = useNavigate()
    const onSubmit = () => {
        localStorage.clear();
        navigate('/')
    }
    return (
        <>
            <div className="min-h-screen bg-gray-100">
                <div className="sidebar min-h-screen w-[3.35rem] overflow-hidden border-r hover:w-56 hover:bg-white hover:shadow-lg">
                    <div className="flex h-screen flex-col justify-between pt-2 pb-6">
                        <div>
                            <div className="w-max p-2.5">
                                <img className='mt-2 -mb-4' src={logo} alt="Cambridge-logo" width={150} height={150} />
                            </div>
                            <ul className="mt-6 space-y-2 tracking-wide">
                                <li className="min-w-max">
                                    <NavLink to='/student/dashboard'>
                                        <div aria-label="dashboard" className="relative flex items-center space-x-4 bg-gradient-to-r from-sky-600 to-primary px-4 py-3 text-white">
                                            <svg className="-ml-1 h-6 w-6" viewBox="0 0 24 24" fill="none">
                                                <path d="M6 8a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2V8ZM6 15a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2v-1Z" className="fill-current text-primary dark:fill-slate-600"></path>
                                                <path d="M13 8a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2V8Z" className="fill-current text-primary group-hover:text-primary"></path>
                                                <path d="M13 15a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2v-1Z" className="fill-current group-hover:text-sky-300"></path>
                                            </svg>
                                            <span className="-mr-1 font-medium">   Dashboard </span>
                                        </div>
                                    </NavLink>
                                </li>
                                <li className="min-w-max">
                                    <NavLink to='/student/onlineResource'>
                                        <div className="bg group flex items-center space-x-4 rounded-full px-4 py-3 text-gray-600">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                <path className=" text-primary group-hover:text-primary" strokeLinecap="round" strokeLinejoin="round" d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25" />
                                            </svg>
                                            <span className="group-hover:text-primary"> Online Resource</span>
                                        </div>
                                    </NavLink>
                                </li>
                                <li className="min-w-max">
                                    <NavLink to='/student/textBooks'>
                                        <div className="bg group flex items-center space-x-4 rounded-full px-4 py-3 text-gray-600">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                <path className=" text-primary group-hover:text-primary" strokeLinecap="round" strokeLinejoin="round" d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5zm6-10.125a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0zm1.294 6.336a6.721 6.721 0 01-3.17.789 6.721 6.721 0 01-3.168-.789 3.376 3.376 0 016.338 0z" />
                                            </svg>
                                            <span className="group-hover:text-primary">Text Books</span>
                                        </div>
                                    </NavLink>
                                </li>
                                <li className="min-w-max">
                                    <NavLink to='/student/reports'>
                                        <div className="bg group flex items-center space-x-4 rounded-full px-4 py-3 text-gray-600">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-primary">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25M9 16.5v.75m3-3v3M15 12v5.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                                            </svg>
                                            <span className="group-hover:text-primary">Reports</span>
                                        </div>
                                    </NavLink>
                                </li>
                                <li className="min-w-max">
                                    <NavLink to='/announcement'>
                                        <div className="group flex items-center space-x-4 rounded-md px-4 py-3 text-gray-600">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                <path className=" text-primary group-hover:text-primary" strokeLinecap="round" strokeLinejoin="round" d="M8.25 7.5V6.108c0-1.135.845-2.098 1.976-2.192.373-.03.748-.057 1.123-.08M15.75 18H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08M15.75 18.75v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5A3.375 3.375 0 006.375 7.5H5.25m11.9-3.664A2.251 2.251 0 0015 2.25h-1.5a2.251 2.251 0 00-2.15 1.586m5.8 0c.065.21.1.433.1.664v.75h-6V4.5c0-.231.035-.454.1-.664M6.75 7.5H4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V16.5a9 9 0 00-9-9z" />
                                            </svg>
                                            <span className="group-hover:text-primary">Annoucement</span>
                                        </div>
                                    </NavLink>
                                </li>
                                <li className="min-w-max">
                                    <NavLink to='/student/askQuestion'>
                                        <div className="group flex items-center space-x-4 rounded-md px-4 py-3 text-gray-600">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-primary">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z" />
                                            </svg>
                                            <span className="group-hover:text-primary">Ask Question</span>
                                        </div>
                                    </NavLink>
                                </li>
                                <li className="min-w-max">
                                    <NavLink to='/student/teacher/answer'>
                                        <div className="group flex items-center space-x-4 rounded-md px-4 py-3 text-gray-600">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-primary">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                                            </svg>
                                            <span className="group-hover:text-primary">Teacher Answers</span>
                                        </div>
                                    </NavLink>
                                </li>
                                <li className="min-w-max">
                                    <NavLink to='/student/furtherAssement'>
                                        <div className="group flex items-center space-x-4 rounded-md px-4 py-3 text-gray-600">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-primary">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M9 9V4.5M9 9H4.5M9 9L3.75 3.75M9 15v4.5M9 15H4.5M9 15l-5.25 5.25M15 9h4.5M15 9V4.5M15 9l5.25-5.25M15 15h4.5M15 15v4.5m0-4.5l5.25 5.25" />
                                            </svg>
                                            <span className="group-hover:text-primary">Further Assessment</span>
                                        </div>
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                        <div className="w-max -mb-3 cursor-pointer">
                            <div className="group flex items-center space-x-4 rounded-md px-4 py-3 text-gray-600">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                    <path className=" text-primary group-hover:text-primary" strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75" />
                                </svg>
                                <span className="group-hover:text-primary" onClick={onSubmit}>Logout</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default StudentSidebar