
import { useState } from 'react';
import logo from '../../assests/images/logos/Cambridge-logo.webp';
import ContentDiv from "../../components/ContentDiv";
import TeacherSidebar from '../../components/TeacherSidebar';
import React from 'react';
const TeacherAnsweringQuestion = () => {
    const [showModal, setShowModal] = React.useState(false);
    const [answerData, setAnswerData] = useState({
        id: "",
        studentId: "",
        question: "",
        answer: "",
        teacherId: "",

    });
    const data = [
        {
            id: 1,
            studentId: 1,
            student: "Ali Haider",
            question: 'What is the S.I Unit Force',
            status: 1
        },
        {
            id: 2,
            studentId: 2,
            student: "Mirza Baig",
            question: 'What is 4+4?',
            status: 0
        }
    ]

    const unreadCount = data.filter(obj=> obj.status < 1).length;
    const onInputChange = (e) => {
        setAnswerData({ ...answerData, [e.target.name]: e.target.value });
    };
    const onSubmit = () => {

        console.log(answerData, 'data');
    }

    return (
        <>
            <div className="flex h-screen justify-center bg-background w-full">
                <aside className="w-14 fixed left-0 top-0  bg-slate-700">
                    <TeacherSidebar />
                </aside>
                <main className="flex-1 ml-10">
                    <div className="p-10">
                        <ContentDiv classes={'justify-center'}>
                            <img className='' src={logo} alt="CambridgeOnline" height={250} width={250} />
                        </ContentDiv>

                    </div>
                    <div className=" bg-background -mt-10 p-6">
                        <ContentDiv >

                            <section className="flex flex-col  container ml-4  mx-4">
                                <h1 className="text-center text-xl text-primary mb-4  md:text-left  text-xl lg:text-left  text-2xl xl:text-left   text-2xl">Student Questions</h1>

                                <h2 className='text-primary text-right'>Unread Question:{unreadCount} </h2>
                                <div className="w-full mb-8 overflow-hidden rounded-lg shadow-lg ">
                                    <div className="w-full overflow-x-auto">
                                        <table className="w-full border-spacing">
                                            <thead>
                                                <tr className="text-md font-semibold tracking-wide text-left text-white bg-primary uppercase border-b border-gray-600">
                                                    <th className="px-4 py-3">Student</th>
                                                    <th className="px-4 py-3">Question</th>
                                                    <th className="px-4 py-3">Status</th>
                                                    <th className="px-4 py-3">Answer</th>
                                                </tr>
                                            </thead>
                                            <tbody className="bg-white">

                                                {data.map((item) => {
                                                    return (
                                                        <tr className="text-gray-700" key={item._id}>
                                                            <td className="px-4 py-3 text-ms font-semibold border">{item.student}</td>
                                                            <td className="px-4 py-3 text-sm border">{item.question}</td>
                                                            <td className="px-4 py-3 text-sm border">
{
    item.status===0 ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-primary">
    <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
  </svg>: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-primary">
  <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 9v.906a2.25 2.25 0 01-1.183 1.981l-6.478 3.488M2.25 9v.906a2.25 2.25 0 001.183 1.981l6.478 3.488m8.839 2.51l-4.66-2.51m0 0l-1.023-.55a2.25 2.25 0 00-2.134 0l-1.022.55m0 0l-4.661 2.51m16.5 1.615a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V8.844a2.25 2.25 0 011.183-1.98l7.5-4.04a2.25 2.25 0 012.134 0l7.5 4.04a2.25 2.25 0 011.183 1.98V19.5z" />
</svg>

  
}

                                                            </td>
                                                            <td className="px-4 py-3 text-sm border">
                                                                <div className="flex flex-row gap-2">
                                                                    <button type="button" onClick={() => { setShowModal(true); setAnswerData(item) }}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-primary">
                                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m.75 12l3 3m0 0l3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                                                                        </svg>
                                                                    </button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                }

                                                )}



                                            </tbody>
                                        </table>
                                        {showModal ? (
                                            <>
                                                <div
                                                    className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                                                >
                                                    <div className="relative w-auto my-6 mx-auto max-w-3xl">
                                                        {/*content*/}
                                                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                                            {/*header*/}
                                                            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                                                <h3 className="text-2xl font-semibold">
                                                                    Send To: <span className='text-primary'>{answerData.student}</span>
                                                                </h3>
                                                                <button
                                                                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                                                    onClick={() => setShowModal(false)}
                                                                >
                                                                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                                                        ×
                                                                    </span>
                                                                </button>
                                                            </div>
                                                            {/*body*/}
                                                            <div className="relative p-6 flex-auto">
                                                                <div className="flex justify-center">
                                                                    <div className="mb-3 xl:w-96">
                                                                        <label htmlFor="exampleFormControlTextarea1" className="form-label inline-block mb-2 text-gray-700">Q: {answerData.question}</label>
                                                                        <label htmlFor="exampleFormControlTextarea1" className="form-label inline-block mb-2 text-gray-700">Answer</label>

                                                                        <textarea
                                                                            className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                                                            id="exampleFormControlTextarea1"
                                                                            rows="3"
                                                                            value={answerData.answer}
                                                                            name="answer"
                                                                            onChange={(e) => onInputChange(e)}
                                                                            placeholder="Your message"
                                                                        ></textarea>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/*footer*/}
                                                            <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                                                <button
                                                                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                                                    type="button"
                                                                    onClick={() => setShowModal(false)}
                                                                >
                                                                    Close
                                                                </button>
                                                                <button
                                                                    className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                                                    type="button"
                                                                    onClick={() => { setShowModal(false); onSubmit() }}
                                                                >
                                                                    Save Changes
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                                            </>
                                        ) : null}
                                    </div>
                                </div>
                            </section>
                        </ContentDiv>
                    </div>
                </main>
            </div>
        </>
    )
}
export default TeacherAnsweringQuestion