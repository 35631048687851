
import axios from 'axios';
import { React, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Chapter } from '../../../api/chapter';
import { Lesson } from '../../../api/lesson';
import logo from '../../../assests/images/logos/Cambridge-logo.webp';
import ContentDiv from "../../../components/ContentDiv";

import StudentSidebar from '../../../components/StudentSidebar';
import { BASE_URL } from '../../../utiles/constant';
import jwtDecode from 'jwt-decode';




const OnlineResourceChapter = () => {
    const { courseId } = useParams();
    const user = localStorage.getItem('user')
    const token = JSON.parse(user);
    const decodeToken = jwtDecode(user);
    const studentId = decodeToken.primarysid;

    const [chapter, setChapter] = useState([]);
    const [chapterLesson, setChapterLesson] = useState({});
    const [lesson, setLesson] = useState([]);

    const loadChapter = async () => {
        return await axios.get(`${BASE_URL}${Chapter.courseChapter}/${courseId}`,
            { headers: { "Authorization": `Bearer ${token.token}` } }
        ).then(res => {
            setChapter(res.data);
        }).catch(err => {
            console.log(err)
        })
    };

    const loadLesson = async (item) => {
        return await axios.get(`${BASE_URL}${Lesson.lesson}?studentId=${studentId}&chapterId=${item.id}`,
            { headers: { "Authorization": `Bearer ${token.token}` } }
        ).then(res => {
            setLesson(res.data.LessonDatas);

        }).catch(err => {
            console.log(err)
        })
    };


    useEffect(() => {

        loadChapter()


    }, [])
    return (

        <div className="flex h-screen justify-center bg-background w-full">
            <aside className="w-14 fixed left-0 top-0  bg-slate-700">
                <StudentSidebar />
            </aside>
            <main className="flex-1 ml-10">
                <div className="p-10">
                    <ContentDiv classes={'justify-center'}>
                        <img className='' src={logo} alt="CambridgeOnline" height={250} width={250} />
                    </ContentDiv>

                </div>
                <div className=" bg-background -mt-10 p-6">
                    <ContentDiv >
                        <section className="flex flex-col container mx-4 p-2 -mt-6">
                            <div className="flex flex-col md:grid grid-cols-2 gap-8">
                                <div className=''>
                                    {Array.isArray(chapter) ? chapter.map((item, index) => {
                                        return (
                                            <div onClick={() => { setChapterLesson(item); loadLesson(item) }} key={index + 1} className='hover:bg-primary cursor-pointer mb-4 text-xs p-6 max-w-md bg-white rounded-lg border border-gray-200 shadow-md bg-white dark:border-shadow-lg md:text-lg'>
                                                <span className='inline-flex gap-4'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                                                        <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clipRule="evenodd" />
                                                    </svg>
                                                    <p className='md:-mt-1'>{item.chapterName}</p>  </span>
                                            </div>
                                        )
                                    }) : []}
                                </div>
                                <div className=' '>
                                    {Array.isArray(lesson) ? lesson.filter(chapId => chapId.chapterId === chapterLesson.id).map((lesson, index) => {
                                        return (
                                            <div key={index + 1} className=" mb-4 flex flex-col items-center bg-white rounded-lg border shadow-md md:flex-row md:max-w-2xl ">
                                                <div className=" w-full h-32  bg:-primary rounded-t-lg bg-primary md:h-auto md:w-40 md:rounded-none md:rounded-l-lg " >
                                                    <div className="flex flex-col justify-between h-72 p-4 leading-normal text-center">
                                                        {/* <h5 className="mb-2 text-2xl font-bold text-primary tracking-tight text-gray-900 dark:text-white"></h5>
                                                    <p className="mb-3 font-normal text-gray-700 dark:text-gray-400"></p> */}
                                                    </div>

                                                </div>
                                                <div className='px-4 lg:-mt-12'>
                                                    {/* <p className='mb-4'>{lesson.status}</p> */}
                                                    <p className='mb-4'>{lesson.lesson}</p>
                                                    <div className='flex items-center gap-4'>

                                                        {lesson.status === 'lock' ?
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
                                                            </svg>
                                                            : <>
                                                                <Link to={`/student/onlineResource/chapters/lesson/${lesson.chapterId}/${lesson.id}/${courseId}`} className="inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-primary rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-blue-300 bg-primary dark:hover:bg-red-700 dark:focus:ring-blue-800">
                                                                    Continue
                                                                    <svg aria-hidden="true" className="ml-2 -mr-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                                                </Link>

                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 10.5V6.75a4.5 4.5 0 119 0v3.75M3.75 21.75h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H3.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
                                                                </svg>
                                                            </>
                                                        }
                                                    </div>
                                                </div>

                                            </div>
                                        )
                                    }) : []}

                                </div>
                            </div>
                        </section>
                    </ContentDiv>
                </div>
            </main>
        </div>
    )
}
export default OnlineResourceChapter
