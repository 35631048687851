
import ContentDiv from "../../../components/ContentDiv";
import StudentSidebar from "../../../components/StudentSidebar";
import logo from '../../../assests/images/logos/Cambridge-logo.webp';
import React from 'react';
import { useParams } from "react-router-dom";

const AnswerKey = () => {

    const { id } = useParams();
    const data={
        id:1,
        topicName:"Topic",
        topicArr:[
            {
            id:1,
            topicId:1,
            img:'https://www.fast-standard.org/wp-content/uploads/2016/03/sample_question_1.png',
            answer:"B",
            studentAnswer:{
                id:1,
                studentId:1,
                yourAnswer:"A"
            }
        },
        {
            id:2,
            topicId:1,
            img:'https://www.fast-standard.org/wp-content/uploads/2016/03/sample_question_5.png',
            answer:"A",
            studentAnswer:{
                id:2,
                studentId:1,
                yourAnswer:"A"
            }
        }
    ]
}
    return (
        <>
            <div className="flex h-screen justify-center bg-background w-full">
                <aside className="w-14 fixed left-0 top-0  bg-slate-700">
                    <StudentSidebar />
                </aside>
                <main className="flex-1 ml-10">
                    <div className="p-10">
                    <ContentDiv classes={'justify-center -mt-4'}>
                            <img className='' src={logo} alt="CambridgeOnline" height={250} width={250} />
                        </ContentDiv>
                    </div>
                    <div className=" bg-background -mt-10 p-6">
                        <ContentDiv>
                            {/* Table */}
                       <section className="flex flex-col  container ml-4  mx-4">
                                <h1 className="text-center text-xl text-primary mb-4  md:text-left  text-xl lg:text-left  text-2xl xl:text-left   text-2xl">Answer Key:  {id}</h1>
{data.topicName}
                                <div className="w-full mb-8 overflow-hidden rounded-lg shadow-lg ">
                                    <div className="w-full overflow-x-auto">
                                        <table className="w-full border-spacing">
                                            <thead>
                                                <tr className="text-md font-semibold tracking-wide text-left text-white bg-primary uppercase border-b border-gray-600">
                                                   
                                                    <th className="px-4 py-3">Question</th>
                                                    <th className="px-4 py-3">Correct Answer</th>
                                                    <th className="px-4 py-3">Your Answer</th>
                                                </tr>
                                            </thead>
                                            <tbody className="bg-white">
                                          
                                               {data.topicArr.map((item)=>
                                               {
                                                return(
                                                    <tr className="text-gray-700" key={item._id}>
                                                 
                                                    <td className="px-4 py-3 text-sm border"><img src={item.img} alt="question"/> </td>
                                                    <td className="px-4 py-3 text-sm border">{item.answer}</td>
                                                    <td className="px-4 py-3 text-sm border">{item.studentAnswer.yourAnswer}</td>
                                                  
                                                </tr>
                                                )
                                               }
                                               
                                               )}



                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </section>
                         
                        </ContentDiv>
                    </div>
                </main>
            </div>
        </>
    )
}
export default AnswerKey


