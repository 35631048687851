
import logo from '../../../assests/images/logos/Cambridge-logo.webp';
import ContentDiv from "../../../components/ContentDiv";
import { useNavigate } from 'react-router-dom'
import React, { useEffect } from 'react';
import StudentSidebar from '../../../components/StudentSidebar';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import CountdownTimer from '../../../components/Timer/CountdownTimer';
import './index.css'
import { QuestionByTopic } from '../../../api/question';
import { attempQuiz } from '../../../api/studnetMarks';
import jwtDecode from 'jwt-decode';
import LoadingSpinner from '../../../components/LoaderComponent';
const url = "https://cos-backend.cambridgeonlineschool.org/Uploads/Questions/";
const THREE_DAYS_IN_MS = 1 * 1 * 1 * 60 * 1000;
const NOW_IN_MS = new Date().getTime();

const dateTimeAfterThreeDays = NOW_IN_MS + THREE_DAYS_IN_MS;

const AttemptQuiz = () => {
    const navigate = useNavigate()
    const { courseId, chapterId, topicId, topic, chapter, id } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [activeQuestion, setActiveQuestion] = useState(0)
    const [selectedAnswer, setSelectedAnswer] = useState('')
    const [showResult, setShowResult] = useState(false)
    const [selectedAnswerIndex, setSelectedAnswerIndex] = useState(null)
    const [finish, setFinish] = useState(false);
    const [scoreCard, setScoreCard] = useState(false);

    const user = localStorage.getItem('user')
    const decodeToken = jwtDecode(user);
    const studentId = decodeToken.primarysid;
    const [ques, setQues] = useState({
        topicId: topicId,
        topic: "",
        totalQuestions: 4,
        perQuestionScore: 1,
        questions: [{
            question: "",
            choices: [],
            correctAnswer: ""
        }]
    })

    const [result, setResult] = useState({
        score: 0,
        correctAnswers: 0,
        wrongAnswers: 0,
    })

    const loadQuestion = async () => {
        const data = await QuestionByTopic(topicId)
        setQues(data.data)
    }
    useEffect(() => {
        loadQuestion();
    }, [])

    const { questions } = ques;

    const { question, choices, correctAnswer } = questions[activeQuestion]

    const addLeadingZero = (number) => (number > 9 ? number : `0${number}`)

    const onSubmit = async () => {

        const data = {
            studentId: studentId,
            topicId: topicId,
            chapterId: chapterId,
            courseId: courseId,
            marks: result.score,
            chapterName: chapter,
            topicName: topic,
            lessonId: id

        }
        await attempQuiz(data).then(res => {
        }).catch(err => {
            console.log(err, 'err')
        });

        setIsLoading(true)
        setTimeout(() => {
            setIsLoading(false);
            setFinish(false);
            setScoreCard(true);

        }, 5000)
        setTimeout(() => {
            setIsLoading(false)
            navigate('/student/dashboard')
        }, 10000)


    }
    const onClickNext = async () => {
        setSelectedAnswerIndex(null)
        setResult((prev) =>
            selectedAnswer
                ? {
                    ...prev,
                    score: prev.score + 1,
                    correctAnswers: prev.correctAnswers + 1,
                }
                : { ...prev, wrongAnswers: prev.wrongAnswers + 1 }
        )

        if (activeQuestion !== questions.length - 1) {
            setActiveQuestion((prev) => prev + 1)

        } else {

            if (activeQuestion === questions.length - 1) {
                setFinish(true)
            }
            setActiveQuestion(0)
            setShowResult(true)
        }
    }

    const onAnswerSelected = (answer, index) => {
        setSelectedAnswerIndex(index)
        if (answer === correctAnswer) {
            setSelectedAnswer(true)
        } else {
            setSelectedAnswer(false)
        }
    }
    return (
        <>
            <div className="flex flex h-screen justify-center bg-background w-full">
                <aside className="w-14 fixed left-0 top-0  bg-slate-700">
                    <StudentSidebar />
                </aside>
                <main className="flex-1 ml-10 ">
                    <div className="p-2  h-min-screen lg:h-36 ">
                        <h1 className="text-center text-primary mt-4 text-2xl">Welcome To Cambridge Online Digital School</h1>
                        <ContentDiv>
                            <img className='ml-4 px-4' src={logo} alt="CambridgeOnline" height={250} width={250} />
                        </ContentDiv>
                    </div>
                    <div className=" bg-background  p-6">
                        <ContentDiv >
                            <section className="flex flex-col container mx-4 p-2 -mt-8">
                                <h2 className='text-xl text-center underline text-primary md: mt-4 '>{chapter}</h2>
                                <h2 className='text-xl text-center underline text-primary md: mt-4 '>{topic}:</h2>
                                {/* {chapterId} {topicId} */}
                                {/* <CountdownTimer targetDate={dateTimeAfterThreeDays}  /> */}
                                <div className="w-full mb-8 overflow-hidden rounded-lg shadow-lg ">
                                    <div className="w-full overflow-x-auto">
                                        <div className="flex grid gap-4 px-4 flex-col">
                                            <div className='justify-center  flex mb-4'>
                                                <div className="quiz-container">
                                                    {!showResult ? (
                                                        <div>
                                                            <div>
                                                                <span className="active-question-no">{addLeadingZero(activeQuestion + 1)}</span>
                                                                <span className="total-question">/{addLeadingZero(questions.length)}</span>
                                                            </div>
                                                            {/* <h2>{question}</h2> */}
                                                            <img src={url + question} alt={question} height={100} width={400} />
                                                            <ul>
                                                                {Array.isArray(choices) ? choices.map((answer, index) => (
                                                                    <li
                                                                        onClick={() => onAnswerSelected(answer, index)}
                                                                        key={index + 1}
                                                                        className={selectedAnswerIndex === index ? 'selected-answer' : null}>
                                                                        {answer}
                                                                    </li>
                                                                )) : []}
                                                            </ul>
                                                            <div className="flex-right">
                                                                <button
                                                                    onClick={onClickNext} disabled={selectedAnswerIndex === null}>


                                                                    {activeQuestion === questions.length - 1 ? 'Finish' + activeQuestion : 'Next' + activeQuestion}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <>
                                                            {
                                                                finish &&
                                                                <button onClick={() => onSubmit(result.score)} className="bg-primary hover:bg-red-700 text-white font-bold py-2 px-4 rounded">Submit
                                                                </button>
                                                            }

                                                            <div className="justify-center">

                                                                {scoreCard &&
                                                                    <div className="result">
                                                                        <h3>Result</h3>
                                                                        <p>
                                                                            Total Question: <span>{questions.length}</span>
                                                                        </p>
                                                                        <p>
                                                                            Total Score:<span > {result.score}</span>
                                                                        </p>
                                                                        <p>
                                                                            Correct Answers:<span> {result.correctAnswers}</span>
                                                                        </p>
                                                                        <p>
                                                                            Wrong Answers:<span> {result.wrongAnswers}</span>
                                                                        </p>
                                                                    </div>
                                                                }

                                                                {isLoading && <LoadingSpinner />}
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </section>
                        </ContentDiv>
                    </div>
                </main>
            </div>
        </>
    )
}
export default AttemptQuiz