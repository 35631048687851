import { useState, useEffect } from "react";
import Sidebar from '../../../components/Sidebar'
import ContentDiv from "../../../components/ContentDiv";
import { getUnEnrollStudent, createEnrollStudent, deleteEnrollStudent, editStudentEnroll, getEnrollStudent } from '../../../api/student'
import { getSubject } from '../../../api/subject'
import axios from "axios";
import { BASE_URL } from "../../../utiles/constant";

import React from 'react';
const Subscriptions = () => {
    const initailEnrollSubject = {
        studentId: "",
        courseId: "",
        code: "",
        name: ""
    }

    const [table, setTable] = useState(true)
    const [record, setRecord] = useState([]);
    const [subjectList, setSubjectList] = useState([]);

    const [multipleEnrollData, setMultipleEnrollData] = useState({})
    const [courseEnrollData, setCourseEnrollData] = useState({});

    const [multipleEnroll, setmultipleEnrollFrom] = useState(false)
    const [multipleEnrollRecord, setMultipleEnrollRecord] = useState([])
    const [edit, setEdit] = useState({});
    const [delStudent, setDelStudent] = useState({});
    const [delModal, setDelModal] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [enrollTable, setEnrollTable] = useState(false);
    const [showCourse, setShowCourse] = useState(false);
    const [subjectEnroll, setSubjectEnroll] = useState([initailEnrollSubject]);

    const user =  localStorage.getItem('user')
    const token  = JSON.parse(user);

    const viewHandler = () => {
        setTable(true)
        setmultipleEnrollFrom(false)
        setEnrollTable(false)
        loadData()

    }
    const onInputHandleChange = (event, index) => {
        event.preventDefault();
        const values = [...subjectEnroll]
        if (event.target.name === "courseId") {
            values[index].courseId = event.target.value
        }
        else {
            values[index].studentId = event.target.value
        }
        setSubjectEnroll(values)

    }
    // handle click event of the Remove button
    const handleRemoveClick = index => {
        const list = [...subjectEnroll];
        list.splice(index, 1);
        setSubjectEnroll(list);
    };
    // handle click event of the Add button
    const handleAddClick = () => {
        const values = [...subjectEnroll];
        values.push({ studentId: '', courseId: '', code: '' });
        setSubjectEnroll(values);

    };

    const handleMultipleEnroll = (data) => {
        setMultipleEnrollData(data)
        setmultipleEnrollFrom(true)
    }

    const handleCourseEnroll = (data) => {
        setCourseEnrollData(data)
        setmultipleEnrollFrom(false)
        setShowCourse(true)
    }


    const onSubmitSubjectEnroll = async () => {
        await createEnrollStudent(subjectEnroll);
        setSubjectEnroll([initailEnrollSubject])
        setTable(true)

        setmultipleEnrollFrom(false)
        setShowCourse(false)
        setTable(false)
        setEnrollTable(true)
        loadEnrollStudent();
    }

    const loadData = async () => {
        return await axios.get(`${BASE_URL}${getUnEnrollStudent}`,
            { headers: { "Authorization": `Bearer ${token.token}` } }
        ).then(res => {
            //  console.log(res, 'res')
            setRecord(res.data);
        }).catch(err => {
            // console.log(err)
        })
    };


    const loadSubject = async () => {
        return await axios.get(`${BASE_URL}${getSubject}`,
            { headers: { "Authorization": `Bearer ${token.token}` } }
        ).then(res => {
            setSubjectList(res.data);
            console.log(res.data, 'subjectList');
        }).catch(err => {
            console.log(err)
        })
    };
    const loadEnrollStudent = async () => {
        return await axios.get(`${BASE_URL}${getEnrollStudent}`,
            { headers: { "Authorization": `Bearer ${token.token}` } }
        ).then(res => {
            setMultipleEnrollRecord(res.data);
        }).catch(err => {
            console.log(err)
        })
    };


    useEffect(() => {
        loadData();
        loadSubject();
        loadEnrollStudent();
    }, [])

    const viewHandlerMultipleEnroll = () => {
        setTable(false);
        setmultipleEnrollFrom(false);
        setEnrollTable(true);
        loadEnrollStudent();
        setShowCourse(false)
    }
    const onChangeEdit = (e) => {
        setEdit({ ...edit, [e.target.name]: e.target.value });
    }
    const onSubmitEdit = async (edit) => {
        await editStudentEnroll(edit)
        loadEnrollStudent();
        console.log(edit);
    }

    const onSubmitDel = async (del) => {
        await deleteEnrollStudent(del)
        loadEnrollStudent();
    }

    return (
        <>
            <div className="flex h-screen justify-center bg-background w-full">
                <aside className="w-14 fixed left-0 top-0  bg-slate-700">
                    <Sidebar />
                </aside>
                <main className="flex-1 ml-2">
                    <div className="p-10">
                        <h1 className=" text-center text-primary mb-4 text-2xl">The Cambridge Online Digital School</h1>

                        <ContentDiv >
                            <div className="flex flex-row">
                                <div className="flex flex-col">
                                    <div className="mt-4 grid grid-cols-1 px-8 ml-4 gap-8 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4  ">
                                        <div className="flex items-start rounded-xl bg-white p-4 shadow-lg cursor-pointer" onClick={() => viewHandler()}>
                                            <div className="flex h-16 w-16 items-center justify-center rounded-full border border-primary bg-white-50">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-primary" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
                                                </svg>
                                            </div>
                                            <div className="ml-4">
                                                <span className="flex justify-between">
                                                </span>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="text-primary float-right w-6 h-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607zM13.5 10.5h-6" />
                                                </svg>
                                                <p className="mt-2 text-sm text-gray-500">View  Unenrollment Student Subjects</p>
                                            </div>
                                        </div>
                                        <div className="flex items-start rounded-xl bg-white p-4 shadow-lg cursor-pointer" onClick={() => viewHandlerMultipleEnroll()}>
                                            <div className="flex h-16 w-16 items-center justify-center rounded-full border border-primary bg-white-50">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-primary" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
                                                </svg>
                                            </div>
                                            <div className="ml-4">
                                                <span className="flex justify-between">
                                                </span>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="text-primary float-right w-6 h-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607zM13.5 10.5h-6" />
                                                </svg>
                                                <p className="mt-2 text-sm text-gray-500">View Enrolled Student Subjects</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ContentDiv>
                    </div>
                    <div className=" bg-background -mt-10 p-6">
                        <ContentDiv>
                            {/* Table */}
                            {
                                multipleEnroll &&
                                <div className="bg-white rounded shadow-lg p-8 mb-6 ml-6 md:ml-14 w-5/6 lg:ml-12 w-5/6   ">
                                    <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
                                        <div className="text-gray-600">
                                            <p className="text-primary text-lg">Student Form</p>
                                            <p>Please fill out all the fields *</p>
                                        </div>
                                        <div className="lg:col-span-2">
                                            {
                                                subjectEnroll.map((x, index) => {
                                                    return (
                                                        <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5" key={index + 1}>
                                                            <div className="md:col-span-2" >
                                                                <label htmlFor="full_name">Student Id</label>
                                                                <input type="text"
                                                                    // value={x.studentId }
                                                                    value={x.studentId = multipleEnrollData.id}

                                                                    onChange={(e) => onInputHandleChange(e, index)}
                                                                    name="studentId" id="studentId" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" />
                                                            </div>
                                                            <div className="md:col-span-3">
                                                                <label htmlFor="full_name">Code</label>
                                                                <input type="text"
                                                                    value={x.code = multipleEnrollData.code}

                                                                    onChange={(e) => onInputHandleChange(e, index)} name="code" id="code" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" />
                                                            </div>
                                                            <div className="md:col-span-5">
                                                                <label htmlFor="courseId">Subjects</label>
                                                                <select id="courseId" name="courseId" onChange={(e) => onInputHandleChange(e, index)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                                                                    <option defaultValue>Choose a subject</option>
                                                                    {
                                                                        subjectList.map((item, index) => {
                                                                            return (

                                                                                <option value={item.id} key={index + 1}> {item.course} {item.code} {item.id}</option>
                                                                            )
                                                                        })}

                                                                </select>
                                                            </div>
                                                            <div className="md:col-span- mt-2 text-right">
                                                                <div className="text-left">
                                                                    {subjectEnroll.length !== 1 &&
                                                                        <button
                                                                            className="bg-primary hover:bg-red-700 text-white font-bold w-6 h-6  px-2 rounded"
                                                                            onClick={() => handleRemoveClick(index)}

                                                                        >x</button>
                                                                    }
                                                                    {
                                                                        subjectEnroll.length - 1 === index &&
                                                                        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold w-6 h-6  px-2 rounded"
                                                                            onClick={handleAddClick}
                                                                        >+</button>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                            <div className="md:col-span-5 mt-2 text-right">
                                                <div className="inline-flex items-end">
                                                    <button type="submit" onClick={onSubmitSubjectEnroll} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                table &&
                                <section className="flex flex-col  container ml-8  mx-4 p-2">
                                    <h1 className="text-center text-xl text-primary mb-4  md:text-left  text-xl lg:text-left  text-2xl xl:text-left   text-2xl">Students Subscription</h1>
                                    <div className="flex flex-row ">
                                        <div className="md:col-span-2 mb-4 justify-center">
                                            <input type="text" name="full_name" placeholder="StudentId" id="full_name" className=" px-4 h-10 border mt-1 w-5/6 rounded   bg-gray-50" />
                                        </div>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-10 h-10 text-primary " >
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 15.75l-2.489-2.489m0 0a3.375 3.375 0 10-4.773-4.773 3.375 3.375 0 004.774 4.774zM21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                    </div>
                                    <div className="w-full mb-8 overflow-hidden rounded-lg shadow-lg ">
                                        <div className="w-full overflow-x-auto">
                                            <table className="w-full border-spacing">
                                                <thead>
                                                    <tr className="text-md font-semibold tracking-wide text-left text-white bg-primary uppercase border-b border-gray-600">
                                                        <th className="px-4 py-3">S.No</th>
                                                        <th className="px-4 py-3">Name</th>
                                                        <th className="px-4 py-3">Code</th>
                                                        <th className="px-4 py-3">Email</th>
                                                        <th className="px-4 py-3">Status</th>
                                                        <th className="px-4 py-3">Date</th>
                                                        <th className="px-4 py-3">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="bg-white">
                                                    {Array.isArray(record) ? record.map((item, index) => {
                                                        return (
                                                            <tr className="text-gray-700" key={index + 1}>
                                                                <td className="px-4 py-3 text-ms font-semibold border">{index + 1}</td>
                                                                <td className="px-4 py-3 text-ms font-semibold border">{item.name}</td>
                                                                <td className="px-4 py-3 text-ms font-semibold border">{item.code}</td>
                                                                <td className="px-4 py-3 text-ms font-semibold border">{item.email}</td>
                                                                <td className="px-4 py-3 text-xs border">
                                                                    {item.courseStatus == true ? <span className="px-2 py-1 font-semibold leading-tight text-green-700 bg-green-100 rounded-sm">Active</span> : <span className="px-2 py-1 font-semibold leading-tight text-red-700 bg-red-100 rounded-sm">Non-Active</span>}
                                                                </td>
                                                                <td className="px-4 py-3 text-sm border">{item.date}</td>
                                                                <td className="px-4 py-3 text-sm border">
                                                                    <div className="flex flex-row gap-2">
                                                                        <div onClick={() => { handleMultipleEnroll(item); setTable(false) }}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-primary">
                                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                                            </svg>
                                                                        </div>
                                                                        <div onClick={() => { setEnrollTable(true); setTable(false); setmultipleEnrollFrom(false) }}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-primary cursor-pointer">
                                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                                            </svg>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }) : []}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </section>
                            }
                            {
                                enrollTable &&
                                <section className="flex flex-col  container ml-12 px-4   lg:ml-0    ">
                                    <h1 className="text-center text-xl text-primary mb-4  md:text-left  text-xl lg:text-left  text-2xl xl:text-left   text-2xl">Students List</h1>
                                    <div className=" mb-8 overflow-hidden rounded-lg shadow-lg ">
                                        <div className="w-full overflow-x-auto">
                                            <table className="w-full border-spacing">
                                                <thead>

                                                    <tr className="text-md font-semibold tracking-wide text-left text-white bg-primary uppercase border-b border-gray-600">
                                                        <th className="px-4 py-3">S.No</th>

                                                        <th className="px-4 py-3">Name</th>
                                                        <th className="px-4 py-3">Code</th>
                                                        <th className="px-4 py-3">Subjects</th>
                                                        <th className="px-4 py-3">Expiray Date</th>
                                                        <th className="px-4 py-3">Status</th>
                                                        <th className="px-4 py-3">Date</th>
                                                        <th className="px-4 py-3">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="bg-white">
                                                    {Array.isArray(multipleEnrollRecord) ? multipleEnrollRecord.map((item, index) => {
                                                        return (
                                                            <tr className="text-gray-700" key={index + 1}>
                                                                <td className="px-4 py-3 text-ms font-semibold border">{index + 1}</td>
                                                                <td className="px-4 py-3 text-ms font-semibold border">{item.name}</td>
                                                                <td className="px-4 py-3 text-ms font-semibold border">{item.code}</td>
                                                                <td className="px-4 py-3 text-ms font-semibold border">{item.course}</td>
                                                                <td className="px-4 py-3 text-ms font-semibold border">{item.expiraySubscription}</td>
                                                                <td className="px-4 py-3 text-xs border">
                                                                    {item.status === true ? <span className="px-2 py-1 font-semibold leading-tight text-green-700 bg-green-100 rounded-sm">Active</span> : <span className="px-2 py-1 font-semibold leading-tight text-red-700 bg-red-100 rounded-sm">Non-Active</span>}
                                                                </td>
                                                                <td className="px-4 py-3 text-sm border">{item.date}</td>
                                                                <td className="px-4 py-3 text-sm border">
                                                                    <div className="flex flex-row gap-2">
                                                                        <div onClick={() => { handleCourseEnroll(item); setTable(false); setEnrollTable(false); }}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-primary">
                                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                                            </svg>
                                                                        </div>
                                                                        <div onClick={() => { setShowModal(true); setEdit(item) }}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 text-primary cursor-pointer">
                                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                                                            </svg>
                                                                        </div>
                                                                        <div onClick={() => { setDelModal(true); setDelStudent(item) }}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 text-primary cursor-pointer">
                                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                                                            </svg>
                                                                        </div>


                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }) : []}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </section>
                            }
                            {
                                showCourse &&
                                <div className="bg-white rounded shadow-lg p-8 mb-6 ml-6 md:ml-14 w-5/6 lg:ml-12 w-5/6   ">
                                    <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
                                        <div className="text-gray-600">
                                            <p className="text-primary text-lg">Student Form: {courseEnrollData.name}</p>
                                            <p>Please fill out all the fields *</p>
                                        </div>
                                        <div className="lg:col-span-2">
                                            {
                                                subjectEnroll.map((x, index) => {
                                                    return (
                                                        <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5" key={index + 1}>
                                                            <div className="md:col-span-2" >
                                                                <label htmlFor="full_name">Student Id</label>
                                                                <input type="text"
                                                                    // value={x.studentId }
                                                                    value={x.studentId = courseEnrollData.studentId}

                                                                    onChange={(e) => onInputHandleChange(e, index)}
                                                                    name="studentId" id="studentId" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" />
                                                            </div>
                                                            <div className="md:col-span-3">
                                                                <label htmlFor="full_name">Code</label>
                                                                <input type="text"
                                                                    value={x.code = courseEnrollData.code}

                                                                    onChange={(e) => onInputHandleChange(e, index)} name="code" id="code" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" />
                                                            </div>
                                                            <div className="md:col-span-5">
                                                                <label htmlFor="courseId">Subjects</label>
                                                                <select id="courseId" name="courseId" onChange={(e) => onInputHandleChange(e, index)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                                                                    <option defaultValue>Choose a subject</option>
                                                                    {
                                                                        subjectList.map((item, index) => {
                                                                            return (

                                                                                <option value={item.id} key={index + 1}> {item.course} {item.code} {item.id}</option>
                                                                            )
                                                                        })}

                                                                </select>
                                                            </div>
                                                            <div className="md:col-span- mt-2 text-right">
                                                                <div className="text-left">
                                                                    {subjectEnroll.length !== 1 &&
                                                                        <button
                                                                            className="bg-primary hover:bg-red-700 text-white font-bold w-6 h-6  px-2 rounded"
                                                                            onClick={() => handleRemoveClick(index)}

                                                                        >x</button>
                                                                    }
                                                                    {
                                                                        subjectEnroll.length - 1 === index &&
                                                                        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold w-6 h-6  px-2 rounded"
                                                                            onClick={handleAddClick}
                                                                        >+</button>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                            <div className="md:col-span-5 mt-2 text-right">
                                                <div className="inline-flex items-end">
                                                    <button type="submit" onClick={onSubmitSubjectEnroll} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {showModal ? (
                                <>
                                    <div
                                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                                    >
                                        <div className="relative w-auto my-6 mx-auto max-w-3xl">
                                            {/*content*/}
                                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                                {/*header*/}
                                                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                                    <h3 className="text-2xl font-semibold">
                                                        Edit Record of :  <span className='text-primary'>{edit.name} {edit.id}</span>
                                                    </h3>
                                                    <button
                                                        className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                                        onClick={() => setShowModal(false)}
                                                    >
                                                        <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                                            ×
                                                        </span>
                                                    </button>
                                                </div>
                                                {/*body*/}
                                                <div className="relative p-6 flex-auto">
                                                    <div className="flex justify-center">
                                                        <div className="mb-3 xl:w-96">
                                                            <label htmlFor="course" className="form-label inline-block mb-2 text-gray-700">Student Name</label>
                                                            <input
                                                                type={"text"}
                                                                className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                                                value={edit.name}
                                                                name="name"
                                                                onChange={(e) => onChangeEdit(e)}
                                                                placeholder="Your message"
                                                            />
                                                            <label htmlFor="course" className="form-label inline-block mb-2 text-gray-700">Student Name</label>
                                                            <input
                                                                type={"text"}
                                                                className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                                                value={edit.expiraySubscription}
                                                                name="expiraySubscription"
                                                                onChange={(e) => onChangeEdit(e)}
                                                                placeholder="Your message"
                                                            />
                                                            <label htmlFor="subject" className="form-label inline-block mb-2 text-gray-700">Subjects</label>
                                                            <select id="subject" name="courseId"
                                                                onChange={(e) => onChangeEdit(e)}
                                                                className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none">
                                                                <option selected value={edit.courseId}>{edit.course}-{edit.courseId}</option>
                                                                {
                                                                    subjectList.map((item) => {

                                                                        return (
                                                                            <option value={item.id}  > {item.course} {item.code} {item.id} </option>
                                                                           
                                                                        )
                                                                    })}

                                                            </select>

                                                            <label htmlFor="status" className="form-label inline-block mb-2 text-gray-700">Course Status</label>
                                                            <select id="status" name="status" required
                                                                onChange={(e) => onChangeEdit(e)}
                                                                className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none">
                                                                <option value={true}  > {"Active"} </option>
                                                                <option value={false}  > {"Non-Active"} </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/*footer*/}
                                                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                                    <button
                                                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                                        type="button"
                                                        onClick={() => setShowModal(false)}
                                                    >
                                                        Close
                                                    </button>
                                                    <button
                                                        className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                                        type="button"
                                                        onClick={() => { setShowModal(false); onSubmitEdit(edit) }}
                                                    >
                                                        Save Changes
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                                </>
                            ) : null}

                            {delModal ? (
                                <>
                                    <div
                                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                                    >
                                        <div className="relative w-auto my-6 mx-auto max-w-3xl">
                                            {/*content*/}
                                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                                {/*header*/}
                                                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">

                                                    <label htmlFor="code" className="form-label inline-block mb-2 text-gray-700">Are you want to sure delete this Student: {delStudent.name} ?</label>
                                                </div>
                                                {/*body*/}

                                                <div className="relative p-6 flex-auto">
                                                    <div className="flex justify-center">
                                                        <div className="mb-3 xl:w-96">

                                                            <div className="flex grid-col-2 justify-center gap-4">
                                                                <button
                                                                    className="bg-red-500 text-white active:bg-red-600 text-white-500 background-transparent rounded font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                                                    type="button"
                                                                    onClick={() => { setDelModal(false); onSubmitDel(delStudent) }}

                                                                >
                                                                    Yes
                                                                </button>
                                                                <button
                                                                    className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                                                    type="button"
                                                                    onClick={() => { setDelModal(false) }}
                                                                >
                                                                    No
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/*footer*/}
                                                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                                </>
                            ) : null}
                        </ContentDiv>
                    </div>
                </main>
            </div>
        </>
    )
}
export default Subscriptions