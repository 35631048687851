import axios from "axios";
import {BASE_URL} from "../utiles/constant";
export  const Student ={
  student : "/api/StudentRegistration", 
  enroll:"/api/StudentEnrollment",
  unEnroll : "/api/student/listofUnenrollStudent",
  studentCourse:"/api/StudentCourse",
  forgetPassword:"/api/StudentEnrollment/ForgetPassword"  
}

export const getStudent = Student.student;
export const getUnEnrollStudent = Student.unEnroll;
export const getEnrollStudent = Student.enroll;
export const getStudentCourse = Student.studentCourse;
const user =  localStorage.getItem('user')
const token  = JSON.parse(user);

export  const forgetStudentPassword = (data) => {
  return axios.patch(`${BASE_URL}${Student.forgetPassword}/${data.email}`,
 // { headers: {"Authorization" : `Bearer ${token.token}`} }
  
  ).then(res=>{
    //  console.log(res,'TEST')
  }).catch(err=>{
   //   console.log(err)
  })
};

export  const editStudent = (data) => {
    return axios.put(`${BASE_URL}${Student.student}/${data.id}`,data,
    { headers: {"Authorization" : `Bearer ${token.token}`} }
    
    ).then(res=>{
      //  console.log(res,'TEST')
    }).catch(err=>{
     //   console.log(err)
    })
  };

  export  const editStudentEnroll = (data) => {
    return axios.patch(`${BASE_URL}${Student.enroll}/${data.id}`,data,
    { headers: {"Authorization" : `Bearer ${token.token}`} }
    
    ).then(res=>{
       // console.log(res,'TEST')
    }).catch(err=>{
        console.log(err)
    })
  };

  export  const deleteStudent = (del) => {
    return axios.delete(`${BASE_URL}${Student.student}/${del.id}`,
    { headers: {"Authorization" : `Bearer ${token.token}`} }
    
    ).then(res=>{
     //   console.log(res,'TEST')
    }).catch(err=>{
      //  console.log(err)
    })
  };

export  const createStudent = (data) => {
    return axios.post(`${BASE_URL}${Student.student}`,data,
    { headers: {"Authorization" : `Bearer ${token.token}`} }
    )};


  export  const createEnrollStudent = (data) => {
    return axios.post(`${BASE_URL}${Student.enroll}`,data,
    { headers: {"Authorization" : `Bearer ${token.token}`} }
    
    )
  };

  export  const deleteEnrollStudent = (del) => {
    return axios.delete(`${BASE_URL}${Student.enroll}/${del.id}`,
    { headers: {"Authorization" : `Bearer ${token.token}`} }
    
    )
  };


